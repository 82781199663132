import { Link } from "react-router-dom";
import AuthLayout from "../components/auth/AuthLayout";
import VerifyEmailForm from "../components/auth/VerifyEmailForm";

const VerifyEmail = () => {

  return (
    <AuthLayout>
      <div className="grid grid-cols-1 md:grid-cols-2 place-content-center justify-center h-full gap-5 pb-5 w-full px-6 md:px-12 lg:px-20">
        {/* Video Card */}
        <div className="bg-white rounded-md w-full md:w-auto px-3">
          <div className="relative pb-[40.25%] h-[350px] overflow-hidden w-full">
            <iframe
              title="Introduction to TempleHS"
              src="https://www.youtube.com/embed/YzqPzMhe6A8?autoplay=1"
              className="absolute top-0 left-0 w-full h-full border-none rounded-md"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              data-testid="video-iframe"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-auto w-full md:w-auto rounded-md bg-white p-5 border-b-8 gap-4 text-[20px] text-[#103C1B]">
          <p>
            Would you like to learn more? {" "}
          </p>
          <Link
            to="https://calendly.com/okechukwu-aneke/meeting"
            target="_blank"
            rel="noreferrer"
            className=" no-underline text-[18px] font-normal py-2 px-4 mt-2 flex items-center text-sm justify-center rounded-[80px] font-large bg-[#34623F] text-[#fff] !w-[260px] h-[40px]"
          >
            Book a demo
          </Link>{" "}
        </div>
        <div className="min-w-full md:w-auto pt-10 flex items-center justify-items-center justify-center">
          <VerifyEmailForm />
        </div>
      </div>
    </AuthLayout>
  )
};

export default VerifyEmail;
