import { AccountIcon, AppointmentsIcon, DashboardIcon, LogoutIcon, MessageIcon, PillsIcon } from "./../svgs";


type MenuItem = {
  path: string;
  label: string;
  icon: JSX.Element;
  activeIcon: JSX.Element;

}
export const menuItems: MenuItem[] = [
  {
    path: "/patient/dashboard",
    label: "Overview",
    icon: <DashboardIcon stroke="#fff" />,
    activeIcon: <DashboardIcon stroke="#ABE9B9" />
  },
  {
    path: "/patient/appointments",
    label: "Appointments",
    icon: <AppointmentsIcon stroke="#fff" />,
    activeIcon: <AppointmentsIcon stroke="#ABE9B9" />
  },
  {
    path: "/patient/messages",
    label: "Messages",
    icon: <MessageIcon stroke="#fff" />,
    activeIcon: <MessageIcon stroke="#ABE9B9" />
  },
  {
    path: "/patient/prescriptions",
    label: "Prescriptions",
    icon: <PillsIcon stroke="#fff" />,
    activeIcon: <PillsIcon stroke="#ABE9B9" />
  },
  {
    path: "/patient/my-account",
    label: "My Account",
    icon: <AccountIcon stroke="#fff" />,
    activeIcon: <AccountIcon stroke="#ABE9B9" />
  }
];

export const bottomMenus = [
  // {
  //   path: "/settings",
  //   label: "Settings",
  //   icon: <SettingsIcon stroke="#fff" />,
  //   activeIcon: <SettingsIcon stroke="#ABE9B9" />
  // },
  {
    path: "",
    label: "Log out",
    icon: <LogoutIcon stroke="#fff" />,
    activeIcon: <LogoutIcon stroke="#fff" />
  }
];