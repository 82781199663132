import { Divider, styled } from "@mui/material";
import axios, { isAxiosError } from "axios";
import { Form, Formik, FormikErrors } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useGetPatientProfileDetails } from "../api/hooks/accounts/getPatientProfileDetails";
import { useGetMedicalRecord } from "../api/hooks/accounts/useGetMedicalRecord";
import { getS3SignedUrl } from "../api/hooks/common/fetchers.common";
import { useCreateMedicalRecord } from "../api/hooks/patient/createMedicalRecord";
import { useUpdatePatientDetails } from "../api/hooks/patient/updatePatientData";
import AddEmergencyContactDrawer from "../components/MyAccount/Patient/AddEmergencyContactDrawer";
import AddNewDependantDrawer from "../components/MyAccount/Patient/AddNewDependantDrawer";
import DependantsList from "../components/MyAccount/Patient/DependantsList";
import EmergencyContactsList from "../components/MyAccount/Patient/EmergencyContactsList";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import RadioField from "../components/form/RadioField";
import TextField from "../components/form/TextField";
import { getInsurancePayers } from '../components/lib/utils/utils';
import Button from "../components/shared/Button";
import CustomDropdown from "../components/shared/CustomDropdown";
import InfoToolTip from "../components/shared/InfoToolTip";
import ListTabsWidget from "../components/shared/ListTabsWidget";
import { Loading } from "../components/shared/Loading";
import { DeleteIcon } from "../svgs/DeleteIcon";
import { PlusIcon } from "../svgs/PlusIcon";
import { phoneRegExp, statesInTheUS } from "../utils/consts";
import { generateUUID, removeEmptyObjectValues } from "../utils/helpers";
import { initialSettingsValues } from "./schemas/data.schema";
import { InitialSettingsValues } from "./types/data.types";

type govDocType = {
  file?: any;
};

export interface AllergiesFormInputType {
  allergy: string;
  severity: string;
  reaction: string;
}

export const AllergiesFormInputInitialState: AllergiesFormInputType[] = [
  { allergy: "", severity: "", reaction: "" }
];

type BodyMeasurement = {
  feet: number;
  inches: number;
}

type TemporaryMedRecord = {
  index: number;
  name: string;
}

type TabType = "My Details" | "Health Record" | "Dependents" | "Emergency Contact";

type HandleShowPageProps = {

  tab: TabType
  values: ValueUser;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<ValueUser>>;

}

type ValueUser = {
  firstName: string;
  lastName: string;
  gender: string;
  pronoun: string;
  email: string;
  phoneNumber: string;
  zipCode: string;
  stateOfIdIssue: string;
  idNumber: string;
  avatarUrl: string;
  maritalStatus: string;
  race: string;
  ssn: string;
  address: string;
  state: string;
  city: string;
  governmentIdUrl: string;
  insuranceName: string;
  insuranceNumber: string;
};

const MyAccount = () => {
  const [activeTabLabel, setActiveTabLabel] = useState<TabType>("My Details");
  const [allergiesList, setAllergiesList] = useState<AllergiesFormInputType[]>(AllergiesFormInputInitialState);
  const [isNewDependantFormVisible, setIsNewDependantFormVisible] = useState<boolean>(false);
  const [isNewDContactFormVisible, setIsNewContactFormVisible] = useState<boolean>(false);
  const [bodyMeasurement, setBodyMeasurement] = useState<BodyMeasurement>({
    feet: 0,
    inches: 0
  });
  const formResetRef = useRef<HTMLInputElement>(null);
  const formUpdateRef = useRef<HTMLInputElement>(null);
  const [weight, setWeight] = useState<number>(0);
  const [medicationRecordIsEditable, setMedicationsRecordIsEditable] = useState<boolean>(false);
  const [currentMedications, setCurrentMedications] = useState<string[]>([]);
  const [isFormEditable, setIsFormEditable] = useState<boolean>(false);
  const [temporaryMedRecord, setTemporaryMedRecord] = useState<TemporaryMedRecord[]>([]);
  const [isSelected, setIsSelected] = useState(false);
  const [govDoc, setGovDoc] = useState({} as govDocType);
  const [showImageFileUpload, setShowImageFileUpload] =
    useState<boolean>(false);
  const [isOtherRacesFieldVisible, setIsOtherRacesFieldVisible] =
    useState(false);
  const [imageUploadType, setImageUploadType] = useState("");
  const [initialSettingsFormValues, setInitialSettingsFormValues] = useState<InitialSettingsValues>(initialSettingsValues);
  const [isImageUploadInProgress, setIsImageUploadInProgress] = useState(false);

  const usaStates = statesInTheUS;
  const [phoneNumberError, setphoneNumberError] = useState(false);
  const savedPayers = localStorage.getItem("insurancePayers");
  const insuranceCompaniesFromLocalStorage = getInsurancePayers()

  const [insuranceName, setInsuranceName] = useState<{
    payer_id: string;
    payer_name: string;
  }>({ payer_id: "", payer_name: "" });
  const [insuranceData, setInsuranceData] = useState<Array<any>>([]);

  const handleSearchInsuranceName = useCallback((val: string) => {
    const filteredData = insuranceCompaniesFromLocalStorage?.filter(
      (e: any) => e.payer_name.toLowerCase().includes(val.toLowerCase())
    );
    setInsuranceData(filteredData);
  }, [insuranceCompaniesFromLocalStorage]);


  const { patientProfileDetails, isLoading: isPatientProfileDetailsLoading, isError, error } = useGetPatientProfileDetails()

  const { updatePatientData, isPending: isPatientDetailsLoading } = useUpdatePatientDetails();

  const { createMedicalRecord, isPending: isMedicalRecordLoading } = useCreateMedicalRecord()


  useEffect(() => {
    if (insuranceName.payer_name.length > 2) {
      handleSearchInsuranceName(insuranceName.payer_name);
    }
  }, [insuranceName.payer_name, handleSearchInsuranceName]);

  const { user } = patientProfileDetails ?? {}

  useEffect(() => {
    setInitialSettingsFormValues({
      address: user?.address ?? '',
      avatarUrl: user?.avatarUrl ?? '',
      cardToCharge: user?.cardToCharge ?? '',
      dateOfBirth: user?.dateOfBirth ?? '',
      dxScriptId: user?.dxScriptId ?? '',
      email: user?.email ?? '',
      emancipationDecisionKey: user?.emancipationDecisionKey ?? '',
      firstName: user?.firstName ?? '',
      gender: user?.gender ?? '',
      governmentIdUrl: user?.governmentIdUrl ?? null,
      guardianId: user?.guardianId ?? null,
      hasFilledPersonalDetails: user?.hasFilledPersonalDetails ?? true,
      idNumber: user?.idNumber ?? '',
      insuranceName: user?.insuranceName ?? '',
      insuranceNumber: user?.insuranceNumber ?? '',
      isActivated: user?.isActivated ?? false,
      isEmailVerified: user?.isEmailVerified ?? false,
      isEmancipatedMinor: user?.isEmancipatedMinor ?? false,
      lastName: user?.lastName ?? '',
      maritalStatus: user?.maritalStatus ?? null,
      phoneNumber: user?.phoneNumber ?? '',
      pronoun: user?.pronoun ?? null,
      race: user?.race ?? '',
      ssn: user?.ssn ?? '',
      stateOfIdIssue: user?.stateOfIdIssue ?? '',
      userId: user?.userId ?? '',
      userType: user?.userType ?? '',
      zipCode: user?.zipCode ?? '',
      state: user?.state ?? '',
      city: user?.city ?? ''
    });
  }, [patientProfileDetails?.user, user?.address, user?.avatarUrl, user?.cardToCharge, user?.city, user?.dateOfBirth, user?.dxScriptId, user?.email, user?.emancipationDecisionKey, user?.firstName, user?.gender, user?.governmentIdUrl, user?.guardianId, user?.hasFilledPersonalDetails, user?.idNumber, user?.insuranceName, user?.insuranceNumber, user?.isActivated, user?.isEmailVerified, user?.isEmancipatedMinor, user?.lastName, user?.maritalStatus, user?.phoneNumber, user?.pronoun, user?.race, user?.ssn, user?.state, user?.stateOfIdIssue, user?.userId, user?.userType, user?.zipCode])


  const { medicalRecords, isLoading } = useGetMedicalRecord()

  useEffect(() => {
    if (!isLoading && medicalRecords?.medications) {
      setCurrentMedications(medicalRecords?.medications);
    }
    if (!isLoading && medicalRecords?.hasAllergies) {
      setAllergiesList(medicalRecords?.allergies);
    }
    if (!isLoading && medicalRecords?.height) {
      const heightInInches = medicalRecords?.height / 2.54;
      const feet = Math.floor(heightInInches / 12);
      const inches = Math.round(heightInInches - feet * 12);
      setBodyMeasurement({
        feet,
        inches
      });
    }
    if (!isLoading && medicalRecords?.weight) {
      setWeight(medicalRecords.weight);
    }



  }, [isLoading, medicalRecords?.medications, medicalRecords?.hasAllergies, medicalRecords?.height, medicalRecords?.weight, medicalRecords?.allergies]);


  const handleAllergyChange = (e: any, index: number) => {
    const newInputs = [...allergiesList];
    newInputs[index].allergy = e.target.value;
    return setAllergiesList(newInputs);
  };



  const handleReactionChange = (e: any, index: number) => {
    const newInputs = [...allergiesList];
    newInputs[index].reaction = e.target.value;
    return setAllergiesList(newInputs);
  };

  const handleResetUserDetails = () => {
    formResetRef?.current?.click();
  };

  const handleTabClick = (tabName: TabType) => {
    setActiveTabLabel(tabName);
  };

  const handleImgFileUpload = (e: any) => {
    try {
      const file = e.target.files[0];
      const maxAllowedSize = 2 * 1024 * 1024;
      if (file.size > maxAllowedSize) {
        toast.error("File size should be less than 2MB");
        return;
      } else {
        const key =
          generateUUID() + file.name?.slice(file.name?.lastIndexOf("."));
        (async () => {
          setIsImageUploadInProgress(true);
          const response = await getS3SignedUrl({
            operation: "putObject",
            key,
            object: "account"
          });
          if (response?.signedUrlParams) {
            const { signedUrl, host } = response.signedUrlParams;
            const uploadedFileUrl = `${host}/${key}`;
            if (imageUploadType === "avatar") {
              const [res] = await Promise.all([
                axios.put(signedUrl, file),
                updatePatientData({ avatarUrl: uploadedFileUrl })
              ]);
              if (res.status === 200) {
                setInitialSettingsFormValues({
                  ...initialSettingsFormValues,
                  avatarUrl: uploadedFileUrl
                });
                setShowImageFileUpload(false);
                setIsImageUploadInProgress(false);
                e.target.value = "";
              }
            } else {
              const [res] = await Promise.all([
                axios.put(signedUrl, file),
                updatePatientData({ governmentIdUrl: uploadedFileUrl })
              ]);
              if (res.status === 200) {
                setInitialSettingsFormValues({
                  ...initialSettingsFormValues,
                  governmentIdUrl: uploadedFileUrl
                });
                setShowImageFileUpload(false);
                setIsImageUploadInProgress(false);
                e.target.value = "";
              }
            }
          }
        })();
      }
    } catch (error) {
      toast.error(
        "An error occured. Please try your profile picture upload again."
      );
    }
  };

  const handleGovDocDelete = useCallback(() => {
    setGovDoc({});
  }, []);

  const handleGetSideTitle = () => {
    switch (activeTabLabel) {
      case "My Details":
        return {
          header: "Personal Info",
          subBody: "Update your personal information here."
        };
      case "Health Record":
        return {
          header: "Health Record"
        };
      case "Dependents":
        return {
          header: "Dependents"
        };
      case "Emergency Contact":
        return {
          header: "Emergency Contact"
        };
      default:
        return {
          header: "Personal Info",
          subBody: "Update your personal information here."
        };
    }
  };

  if (isPatientProfileDetailsLoading) {
    return (
      <div className="w-1/2 h-1/2 flex items-center justify-center">
        <Loading />
      </div>
    )
  }

  if (isError) {
    if (isAxiosError(error)) {
      const errorMessage = error?.response?.data?.error
      toast.error(errorMessage,
        { toastId: "customId" })
    } else {
      toast.error(error?.error || "Error: Couldn't process your request", { toastId: "customId" })
    }
  }

  const HandleShowTabPage = ({ tab, values, handleChange, setFieldValue }: HandleShowPageProps) => {

    switch (tab) {
      case "My Details":
        return (
          <MyDetailsContainer>
            <Form>
              <div className="flex w-full items-center mt-6 mb-8 ml-4">
                <div className="relative mr-8 h-[80px] w-[80px] rounded-full">
                  <img
                    className="w-full h-full rounded-full"
                    src={values.avatarUrl || "/assets/images/avatar.png"}
                    width={96}
                    height={96}
                    alt="Profile img"
                  />
                  {isImageUploadInProgress && (
                    <div className="absolute bg-[#000000] opacity-50 h-full w-[80px] rounded-full top-0 flex items-center justify-center">
                      Loading...
                    </div>
                  )}
                </div>
                <input
                  onChange={handleImgFileUpload}
                  type="file"
                  accept="image/*"
                  className={showImageFileUpload ? "" : "hidden"}
                />
                <p
                  onClick={() => {
                    if (isFormEditable) {
                      setImageUploadType("avatar");
                      setShowImageFileUpload(!showImageFileUpload);
                    }
                  }}
                  className="cursor-pointer text-[14px] font-[500] text-[#3D874E]"
                >
                  {showImageFileUpload ? "Cancel" : "Change profile photo"}
                </p>
              </div>
              <div className="form-item-grid">
                <div className="form-item-text">Name</div>
                <div className="flex flex-row flex-wrap lg:flex-nowrap items-center justify-end">
                  <div className="form-item-data">
                    <TextField
                      name="firstName"
                      type="text"
                      placeholder="First name"
                      value={values.firstName}
                      onChange={handleChange}
                      disabled={!isFormEditable}
                    />
                  </div>
                  <div className="form-item-data mt-2 lg:mt-0">
                    <TextField
                      name="lastName"
                      type="text"
                      placeholder="Last name"
                      value={values.lastName}
                      onChange={handleChange}
                      disabled={!isFormEditable}
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-text">Gender</div>
                <div className="flex flex-row items-center justify-start">
                  <div className="form-item-data">
                    <RadioField
                      name="gender"
                      placeholder="Type Gender"
                      display="block"
                      options={[
                        { item: "Male", value: "male" },
                        { item: "Female", value: "female" }
                      ]}
                      disabled={!isFormEditable}
                      hasOther={true}
                      isRequired={true}
                      value={values.gender}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-cont">
                  <div className="form-item-text">Pronoun</div>
                </div>
                <div className="flex flex-row items-center justify-end">
                  <div className="form-item-data">
                    <TextField
                      name="pronoun"
                      type="text"
                      disabled={!isFormEditable}
                      placeholder="Enter Pronoun"
                      value={values.pronoun}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-text flex items-center">
                  Email <div className="w-2" />
                  <InfoToolTip
                    width={70}
                    message="You cannot change your email directly here"
                  />
                </div>
                <div className="flex flex-row items-center justify-end">
                  <div className="form-item-data">
                    <TextField
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      value={values.email}
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-text">Phone Number</div>
                <div className="flex flex-row items-center justify-end">
                  <div className="form-item-data">
                    <TextField
                      name="phoneNumber"
                      type="tel"
                      disabled={!isFormEditable}
                      placeholder="Enter phone"
                      value={values.phoneNumber}
                      onChange={e => {
                        handleChange(e);
                        setphoneNumberError(
                          phoneRegExp.test(values.phoneNumber)
                        );
                      }}
                      error={
                        !phoneRegExp.test(values.phoneNumber)
                          ? "invalid Us phone number"
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-text">State</div>
                <select
                  className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded h-[47px]"
                  name="state"
                  value={values?.state}
                  disabled={!isFormEditable}
                  onChange={e => {
                    setFieldValue("state", e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {usaStates.map(state => (
                    <option value={state.toLowerCase()} key={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-text">City</div>
                <div className="flex flex-row items-center justify-end">
                  <div className="form-item-data">
                    <TextField
                      name="city"
                      type="tel"
                      disabled={!isFormEditable}
                      placeholder="Enter phone"
                      value={values.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <Divider />
              <div className="form-item-grid">
                <div className="form-item-cont">
                  <div className="form-item-text">Zipcode</div>
                </div>
                <div className="flex flex-row items-center justify-end">
                  <div className="form-item-data">
                    <TextField
                      name="zipCode"
                      type="text"
                      disabled={!isFormEditable}
                      placeholder="Enter Zipcode"
                      value={values.zipCode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-text">Address</div>
                <div className="flex flex-row items-center justify-end">
                  <div className="form-item-data">
                    <TextField
                      name="address"
                      type="text"
                      disabled={!isFormEditable}
                      placeholder="Enter Address"
                      value={values.address}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-text">Insurance Name</div>
                <div className="flex flex-row items-center justify-end">
                  <div className="form-item-data">
                    <TextField
                      name="insuranceName"
                      type="text"
                      placeholder="Enter isurance name  here "
                      value={values.insuranceName || ""}
                      disabled={!isFormEditable}
                      onChange={e => {
                        setIsSelected(false);
                        setInitialSettingsFormValues({
                          ...initialSettingsFormValues,
                          insuranceName: e.target.value
                        });
                        setInsuranceData([]);
                        setInsuranceName({
                          ...insuranceName,
                          payer_name: e.target.value
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2">
                {!isSelected &&
                  insuranceData.map((item, index) => (
                    <div
                      key={index}
                      className="cursor-pointer"
                      onClick={() => {
                        setFieldValue("insuranceName", item.payer_name);
                        setInitialSettingsFormValues({
                          ...initialSettingsFormValues,
                          insuranceName: item.payer_name
                        });
                        setInsuranceName({
                          payer_name: item.payer_name,
                          payer_id: item.payerid
                        });
                        setIsSelected(true);
                      }}
                    >
                      {item.payer_name}
                    </div>
                  ))}
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-text">Insurance Number</div>
                <div className="flex flex-row items-center justify-end">
                  <div className="form-item-data">
                    <TextField
                      name="insuranceNumber"
                      type="text"
                      disabled={!isFormEditable}
                      placeholder="Enter Insurance ID"
                      value={values.insuranceNumber || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <Divider />
              <div className="form-item-grid">
                <div className="form-item-text">Social security number</div>
                <div className="flex flex-row items-center justify-end">
                  <div className="form-item-data">
                    <TextField
                      name="ssn"
                      type="text"
                      disabled={!isFormEditable}
                      placeholder="Enter Social security number"
                      value={values.ssn}
                      onChange={handleChange}
                      error={
                        /^[A-Z]{1}[0-9]{7}$/.test(values.ssn)
                          ? "Invalid social security number"
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-text">Marital Status</div>
                <div className="flex flex-row items-center justify-end">
                  <div className="form-item-data">
                    <CustomDropdown
                      name="maritalStatus"
                      value={values?.maritalStatus}
                      optionsList={[
                        { name: "Single", value: "Single" },
                        { name: "Married", value: "Married" },
                        { name: "Seperated", value: "Seperated" },
                        { name: "Divorced", value: "Divorced" },
                        { name: "Unmarried", value: "Unmarried" },
                        { name: "Common law", value: "Common law" },
                        {
                          name: "Legally Separated",
                          value: "Legally Separated"
                        },
                        { name: "Living together", value: "Living together" },
                        { name: "Interlocutory", value: "Interlocutory" },
                        { name: "Annulled", value: "Annulled" },
                        { name: "Domestic partner", value: "Domestic partner" },
                        {
                          name: "Registered domestic partner",
                          value: "Registered domestic partner"
                        },
                        { name: "Unreported", value: "Unreported" },
                        { name: "Widowed", value: "Widowed" },
                        { name: "Unknown", value: "Unknown" }
                      ]}
                      onChange={event =>
                        setFieldValue("maritalStatus", event.target.value)
                      }
                      placeholder="Marital Status"
                      defaultValue=""
                      disabled={!isFormEditable}
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-text">Race</div>
                <div className="flex flex-row items-center justify-end">
                  <div className="form-item-data">
                    <CustomDropdown
                      name="race"
                      value={values?.race}
                      optionsList={[
                        {
                          name: "Black or African American",
                          value: "Black or African American"
                        },
                        { name: "White", value: "White" },
                        {
                          name: "American Indian or Alaska Native",
                          value: "American Indian or Alaska Native"
                        },
                        { name: "Asian", value: "Asian" },
                        {
                          name: "Native Hawaiian or Other Pacific Islander",
                          value: "Native Hawaiian or Other Pacific Islander"
                        },
                        { name: "Other races", value: "others" }
                      ]}
                      onChange={event => {
                        if (event.target.value === "others") {
                          setFieldValue("race", event.target.value);
                          setIsOtherRacesFieldVisible(true);
                        } else {
                          setIsOtherRacesFieldVisible(false);
                          setFieldValue("race", event.target.value);
                        }
                      }}
                      placeholder="Virtual visit"
                      defaultValue=""
                      disabled={!isFormEditable || isOtherRacesFieldVisible}
                    />
                    {isOtherRacesFieldVisible && (
                      <div className="w-full pt-2">
                        <TextField
                          name="race"
                          type="text"
                          placeholder="Enter other race"
                          value={values?.race !== "others" ? values?.race : ""}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Divider />
              <div className="form-item-grid">
                <div className="form-item-cont">
                  <div className="form-item-text">Government issued ID</div>
                </div>
                <div className="flex items-center justify-start">
                  <div className="w-full">
                    <GovmentIdUploadContainer>
                      <div className="">
                        <img
                          className="w-[140ppx] h-[80px]"
                          src={
                            values.governmentIdUrl ||
                            "/assets/images/avatar.png"
                          }
                          width={140}
                          height={80}
                          alt="Profile img"
                        />
                      </div>
                      <div className="w-[50px]" />

                      <input
                        id="govDoc_file_upload"
                        className="invisible_file_upload"
                        onChange={handleImgFileUpload}
                        type="file"
                        accept=".pdf, image/*"
                      />

                      <div
                        className="file_upload_cont"
                        onClick={() => {
                          if (isFormEditable) {
                            setImageUploadType("governmentId");
                            document
                              .getElementById("govDoc_file_upload")
                              ?.click();
                          }
                        }}
                      >
                        <div className="trigger-text-cont">
                          <span className="text-[#103C1B] text-[16px] font-[400]">
                            Click to upload file
                          </span>
                        </div>
                      </div>
                    </GovmentIdUploadContainer>
                  </div>
                </div>
              </div>
            </Form>
          </MyDetailsContainer>
        );
      case "Health Record":
        return (
          <MyDetailsContainer>
            <div className="form-item-grid">
              <div className="form-item-text">Current Medications</div>
              <div>
                {currentMedications.map((medication, index) => (
                  <div
                    className="w-1/2 flex flex-row items-center justify-between mb-4"
                    key={index}
                  >
                    <input
                      value={medication}
                      className="med-inputs"
                      disabled={!isFormEditable}
                      placeholder="Pain killer"
                      onChange={(e) => {
                        const newMedications = [...currentMedications];
                        newMedications[index] = e.target.value;
                        setCurrentMedications(newMedications);
                      }}
                    />
                    {isFormEditable && (
                      <button
                        className="ml-2"
                        onClick={() => {
                          setCurrentMedications(currentMedications.filter((_, ind) => ind !== index));
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    )}
                  </div>
                ))}
                {isFormEditable && medicationRecordIsEditable && (
                  <Formik
                    initialValues={{
                      newMedication: ""
                    }}
                    onSubmit={(values) => {
                      setCurrentMedications([...currentMedications, values.newMedication]);
                      setMedicationsRecordIsEditable(false);
                    }}
                  >
                    {({ values, handleChange }) => (
                      <Form>
                        <div className="w-1/2  flex flex-row items-center justify-start mb-4">
                          <input
                            name="newMedication"
                            className="med-inputs"
                            value={values.newMedication}
                            onChange={handleChange}
                            onBlur={() => {
                              if (values.newMedication) {
                                setCurrentMedications([...currentMedications, values.newMedication]);
                                setMedicationsRecordIsEditable(false);
                              }
                            }}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
                {isFormEditable && (
                  <div
                    className="cursor-pointer flex flex-row"
                    onClick={() => {
                      setMedicationsRecordIsEditable(true);
                    }}
                  >
                    <PlusIcon />
                    <span className="text-[#1A9D39] text-[16px] ml-3">
                      Add another medication
                    </span>
                  </div>
                )}
              </div>
            </div>
            <Divider />
            <div className="form-item-grid">
              <div className="form-item-text">Current Allergies</div>
              <div className="w-full">
                {allergiesList &&
                  allergiesList?.map((item, index) => (
                    <div
                      className="w-full p-2 flex flex-wrap lg:flex-nowrap justify-start items-center mb-4"
                      key={index}
                    >
                      <div className="mr-2">
                        <TextField
                          name="allergy"
                          type="text"
                          placeholder="Peanuts"
                          value={item?.allergy}
                          onChange={e => handleAllergyChange(e, index)}
                          disabled={!isFormEditable}
                        />
                      </div>
                      <div className="lg:w-[240px] mr-2">
                        <select
                          className="w-full py-3.5 px-4 text-[#6E8877] border border-grey focus:outline-none text-s rounded h-[47px]"
                          name="severity"
                          value={item?.severity}
                          onChange={e => {
                            const newInputs = [...allergiesList];
                            newInputs[index].severity = e.target.value;
                            setAllergiesList(newInputs);
                          }}
                          disabled={!isFormEditable}
                        >
                          <option value="" disabled>
                            {item?.severity || "Severe"}
                          </option>
                          {["Severe", "Mild", "Moderate"].map(value => (
                            <option value={value.toLowerCase()} key={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="">
                        <TextField
                          name="reaction"
                          type="text"
                          placeholder="Swollen tongue"
                          value={item?.reaction}
                          onChange={e => handleReactionChange(e, index)}
                          disabled={!isFormEditable}
                        />
                      </div>

                      {isFormEditable && (
                        <button
                          className="ml-2"
                          onClick={() => {
                            const newFields = [...allergiesList];
                            newFields.splice(index, 1);
                            setAllergiesList(newFields);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                    </div>
                  ))}
                {isFormEditable && (
                  <div
                    className="cursor-pointer flex flex-row"
                    onClick={() => {
                      setAllergiesList([
                        ...allergiesList,
                        {
                          allergy: "",
                          severity: "",
                          reaction: ""
                        }
                      ]);
                    }}
                  >
                    <PlusIcon />
                    <span className="text-[#1A9D39] text-[16px] ml-3">
                      Add another allergy
                    </span>
                  </div>
                )}
              </div>
            </div>
            <Divider />
            <div className="hidden lg:block">
              <div className="form-item-grid">
                <div className="form-item-text">Body Measurement</div>
                <div className="flex flex-col mt-2">
                  <div className="flex flex-row items-center">
                    <span className="text-[#103C1B]">Height:</span>
                    <div className="flex ">
                      <input
                        placeholder="Feet"
                        type="number"
                        name="feet"
                        maxLength={2}
                        max={10}
                        value={Number(bodyMeasurement.feet)}
                        className="p-2 w-16 border border-solid rounded outline-none ml-3"
                        onChange={e => {
                          const newFeet = Number(e.target.value);
                          if (newFeet >= 0 && newFeet <= 10) {
                            setBodyMeasurement(prev => ({ ...prev, feet: newFeet }));
                          }
                        }}
                        disabled={!isFormEditable}
                      />
                      <input
                        placeholder="Inch"
                        type="number"
                        name="inches"
                        maxLength={2}
                        max={12}
                        value={Number(bodyMeasurement.inches)}
                        className="p-2 w-16 border border-solid rounded outline-none ml-3"
                        onChange={e => {
                          const newInches = Number(e.target.value);
                          if (newInches <= 12) {
                            setBodyMeasurement(prev => ({ ...prev, inches: newInches }));
                          }
                        }}
                        disabled={!isFormEditable}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row items-center mt-2">
                    <span className="text-[#103C1B]">Weight:</span>
                    <div className="ml-3 flex flex-row items-center">
                      <input
                        placeholder="Lbs"
                        type="number"
                        name="weight"
                        max={600}
                        value={Number(weight)}
                        className="p-2 w-16 border border-solid rounded outline-none mr-2"
                        onChange={e => {
                          const newWeight = Number(e.target.value);
                          if (newWeight <= 600) {
                            setWeight(newWeight);
                          }
                        }}
                        disabled={!isFormEditable}
                      />
                    </div>
                  </div>

                  {/* </Formik> */}
                </div>
              </div>
            </div>
            {/* <div className="block lg:hidden">
              <div className="w-full flex flex-col mt-2">
                <div className="form-item-text">Body Measurement</div>
                <div className="flex flex-col mt-2">
                  <div className="flex flex-row items-center">
                    <span className="text-[#103C1B]">Height:</span>
                    <Formik
                      initialValues={{
                        feet: bodyMeasurement.feet
                      }}
                      onSubmit={values => {
                        setBodyMeasurement({
                          ...bodyMeasurement,
                          feet: values.feet
                        });
                      }}
                    >
                      {({ values, handleChange }) => (
                        <Form className="-mr-20">
                          <input
                            placeholder="Feet"
                            type="number"
                            name="feet"
                            maxLength={2}
                            max={10}
                            value={Number(values.feet)}
                            className="p-2 w-2/4 border border-solid rounded outline-none ml-3 mr-4"
                            onChange={v => {
                              if (Number(v.target.value) > 10) return;
                              handleChange(v);
                            }}
                            disabled={!isFormEditable}
                          />
                        </Form>
                      )}
                    </Formik>
                    <Formik
                      initialValues={{
                        inches: bodyMeasurement.inches
                      }}
                      onSubmit={values => {
                        setBodyMeasurement({
                          ...bodyMeasurement,
                          inches: values.inches
                        });
                      }}
                    >
                      {({ values, handleChange }) => (
                        <Form>
                          <input
                            placeholder="Inch"
                            type="number"
                            name="inches"
                            maxLength={2}
                            max={12}
                            value={Number(values.inches)}
                            className="ml-6 p-2 w-2/4 border border-solid rounded outline-none"
                            onChange={v => {
                              if (Number(v.target.value) > 12) return;
                              handleChange(v);
                            }}
                            disabled={!isFormEditable}
                          />
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <Formik
                    initialValues={{
                      weight
                    }}
                    onSubmit={values => {
                      setWeight(values.weight);
                    }}
                  >
                    {({ values, handleChange }) => (
                      <Form>
                        <div className="flex flex-row items-center mt-2">
                          <span className="text-[#103C1B]">Weight:</span>
                          <div className="ml-3 flex flex-row items-center">
                            <input
                              placeholder="Lbs"
                              type="number"
                              name="weight"
                              max={600}
                              value={Number(values.weight)}
                              className="p-2 w-2/4 border border-solid rounded outline-none mr-2"
                              onChange={v => {
                                if (Number(v.target.value) > 600) return;
                                handleChange(v);
                              }}
                              disabled={!isFormEditable}
                            />
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>*/}
          </MyDetailsContainer>
        );
      case "Dependents":
        return (
          <>
            {
              <DependantsList
              />
            }
          </>
        );
      case "Emergency Contact":
        return (
          <>
            <EmergencyContactsList
            />
          </>
        );
      default:
        return null
    }
  };

  const HandleBuildSideButtons = ({
    tab,
    isEditing
  }: {
    tab: string;
    isEditing?: boolean;
  }) => {
    switch (tab) {
      case "My Details": {
        return isEditing ? (
          <div className="flex flex-row items-center justify-space">
            <Button
              width={100}
              height={60}
              loading={isPatientDetailsLoading}
              onClick={() => {
                setIsFormEditable(false);
                handleResetUserDetails();
                if (temporaryMedRecord.length) {
                  const newMeds = [...currentMedications];
                  temporaryMedRecord.forEach(record =>
                    newMeds.splice(record.index, 0, record.name)
                  );
                  setCurrentMedications(newMeds);
                  setTemporaryMedRecord([]);
                }
              }}
              variant="secondary"
              label="Cancel"
              size="medium"
              additionalClassname="mr-4"
            />
            <Button
              width={100}
              height={60}
              loading={isPatientDetailsLoading}
              onClick={e => {
                if (!phoneNumberError) {
                  formUpdateRef?.current?.click();
                }
              }}
              type="submit"
              variant="primary"
              label="Update"
              size="medium"
            />
          </div>
        ) : (
          <Button
            width={100}
            height={60}
              loading={isPatientDetailsLoading}
            onClick={() => {
              setIsFormEditable(true);
            }}
            type="submit"
            variant="primary"
            label="Edit"
            size="medium"
          />
        );
      }
      case "Health Record": {
        return isEditing ? (
          <div className="flex flex-row items-center justify-space">
            <Button
              width={100}
              height={60}
              loading={isPatientDetailsLoading}
              onClick={() => {
                setIsFormEditable(false);
                if (temporaryMedRecord.length) {
                  const newMeds = [...currentMedications];
                  temporaryMedRecord.forEach(record =>
                    newMeds.splice(record.index, 0, record.name)
                  );
                  setCurrentMedications(newMeds);
                  setTemporaryMedRecord([]);
                }
                // if (temporaryAllergiesRecord.length) {
                //   const newAllergies = [...currentAllergies];
                //   temporaryAllergiesRecord.forEach(record =>
                //     newAllergies.splice(record.index, 0, record.name)
                //   );
                //   setCurrentAllergies(newAllergies);
                //   setTemporaryAllergiesRecord([]);
                // }
              }}
              variant="secondary"
              label="Cancel"
              size="medium"
              additionalClassname="mr-4"
            />
            <Button
              width={100}
              height={60}
              loading={isMedicalRecordLoading}
              onClick={() => {
                const { feet, inches } = bodyMeasurement;

                const formattedData = removeEmptyObjectValues({
                  isTakingMedication: currentMedications.length > 0,
                  hasAllergies: allergiesList.length > 0,
                  medications: currentMedications,
                  allergies: allergiesList,
                  weight: weight.toString(),
                  height: (((feet ? feet * 12 : 0) + (inches ?? 0)) * 2.54).toString()
                });

                createMedicalRecord(formattedData);

                setBodyMeasurement({
                  feet: 0,
                  inches: 0
                })
                setWeight(0)
                setIsFormEditable(false);
              }}
              type="submit"
              variant="primary"
              label="Update"
              size="medium"
            />
          </div>
        ) : (
          <Button
            width={100}
            height={60}
              loading={isPatientDetailsLoading}
            onClick={() => {
              setIsFormEditable(true);
            }}
            type="submit"
            variant="primary"
            label="Edit"
            size="medium"
          />
        );
      }
      case "Dependents":
        return (
          <div className="flex flex-row items-center justify-space mt-2 lg:mt-0">
            <Button
              height={60}
              onClick={() => {
                setIsNewDependantFormVisible(true);
              }}
              type="submit"
              variant="primary"
              label="Add Dependent"
            />
          </div>
        );
      case "Emergency Contact":
        return (
          <div className="flex flex-row items-center justify-space">
            <Button
              height={60}
              // loading={isUpdatingUserData}
              onClick={() => {
                setIsNewContactFormVisible(true);
              }}
              type="submit"
              variant="primary"
              label="Add Emergency Contact"
            />
          </div>
        );
      default:
        return null
    }
  };

  return (
    <DashboardLayout headerTitle="My Account" overflowStyle="hidden">
      <div className="flex justify-between items-center pr-0 pl-0 mx-[10px] mt-[20px]">
        <PageContainer>
          <AddNewDependantDrawer
            open={isNewDependantFormVisible}
            onClose={() => setIsNewDependantFormVisible(false)}
            drawerTitle="Add Dependent"
          />
          <AddEmergencyContactDrawer
            open={isNewDContactFormVisible}
            onClose={() => setIsNewContactFormVisible(false)}
            drawerTitle="Add Emergency Contact"
          />
          <div className="overflow-y-hidden my-6 w-full overflow-x-scroll lg:overflow-x-hidden">
            <ListTabsWidget
              tabs={[
                { label: "My Details" },
                { label: "Health Record" },
                { label: "Dependents" },
                { label: "Emergency Contact" }
              ]}
              handleTabClick={(label: any) => {
                handleTabClick(label);
              }}
              activeTabLabel={activeTabLabel}
            />
          </div>
          <div className="tab-page">
            <div className="tab-page-container">
              <div className="tpc-header">
                <div className="w-[90%] mb-4">
                  <p className="text-[1.5rem] font-normal text-[#103C1B]">
                    {handleGetSideTitle().header}
                  </p>
                  <p className="mt-2 text-[.8rem] font-xs text-[#6E8877]">
                    {handleGetSideTitle().subBody}
                  </p>
                </div>
                <HandleBuildSideButtons
                  tab={activeTabLabel}
                  isEditing={isFormEditable}
                />
              </div>
              <div className="page-body-cont">
                <Formik
                  initialValues={{
                    firstName: initialSettingsFormValues?.firstName ?? "",
                    lastName: initialSettingsFormValues?.lastName ?? "",
                    gender: initialSettingsFormValues?.gender ?? "",
                    pronoun: initialSettingsFormValues?.pronoun ?? "",
                    email: initialSettingsFormValues?.email ?? "",
                    phoneNumber: initialSettingsFormValues?.phoneNumber ?? "",
                    zipCode: initialSettingsFormValues?.zipCode ?? "",
                    stateOfIdIssue:
                      initialSettingsFormValues?.stateOfIdIssue ?? "",
                    idNumber: initialSettingsFormValues?.idNumber ?? "",
                    avatarUrl: initialSettingsFormValues?.avatarUrl ?? "",
                    maritalStatus:
                      initialSettingsFormValues?.maritalStatus ?? "",
                    race: initialSettingsFormValues?.race ?? "",
                    ssn: initialSettingsFormValues?.ssn ?? "",
                    address: initialSettingsFormValues?.address ?? "",
                    state: initialSettingsFormValues?.state ?? "",
                    city: initialSettingsFormValues?.city ?? "",
                    governmentIdUrl:
                      initialSettingsFormValues?.governmentIdUrl ?? "",
                    insuranceName: initialSettingsFormValues?.insuranceName ?? "",
                    insuranceNumber: initialSettingsFormValues?.insuranceNumber ?? "",
                  }}
                  enableReinitialize
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string().required("First name is required"),
                    lastName: Yup.string().required("Last name is required")
                  })}
                  onSubmit={async (values: any) => {
                    for (const k in values) {
                      if (!values[k]) {
                        delete values[k];
                      }
                    }
                    updatePatientData({
                      firstName: values.firstName,
                      lastName: values.lastName,
                      phoneNumber: values.phoneNuber,
                      pronoun: values.pronoun,
                      zipCode: values.zipCode,
                      gender: values.gender,
                      stateOfIdIssue: values.stateOfIdIssue,
                      idNumber: values.idNumber,
                      avatarUrl: values.avatarUrl,
                      governmentIdUrl: values.governmentIdUrl,
                      ssn: values.ssn,
                      maritalStatus: values.maritalStatus,
                      race: values.race,
                      address: values.address,
                      state: values.state,
                      city: values.city,
                      insuranceName: values.insuranceName,
                      insuranceNumber: values.insuranceNumber,
                    });
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleReset,
                    handleSubmit,
                    setFieldValue
                  }) => (
                    <>
                      {HandleShowTabPage({
                        tab: activeTabLabel,
                        values: values,
                        handleChange: handleChange,
                        setFieldValue: setFieldValue
                      })}
                      <input
                        onClick={handleReset}
                        ref={formResetRef}
                        type="button"
                        className="elem-no-see"
                      />
                      <input
                        onClick={() => handleSubmit()}
                        ref={formUpdateRef}
                        type="button"
                        className="elem-no-see"
                      />
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </PageContainer>
      </div>
    </DashboardLayout>
  );
};
const MyDetailsContainer = styled("div")(() => ({
  "& .form-item-grid": {
    display: "grid",
    gridTemplateColumns: "40% 60%",
    gridTemplateRows: "auto",
    alignItems: "start",
    position: "relative",
    marginTop: "10px",
    marginBottom: "15px",

    "& .form-item-cont": {
      marginRight: "20px"
    },

    "& .form-item-data": {
      marginLeft: "10px",
      width: "100%"
    },
    "& .med-inputs": {
      border: "1px solid #1E2F231A",
      borderRadius: "3px",
      width: "100%",
      outline: "none",
      backgroundColor: "#CDD0CD2E",
      padding: "2px"
    }
  }
}));

const PageContainer = styled("div")(() => ({
  width: "100%",
  marginLeft: "13px",
  "& .tab-page": {
    position: "relative",
    width: "100%",
    marginTop: "2rem",
    height: "80vh",
    overflowY: "auto"
  },
  "& .elem-no-see": {
    border: "1px solid red",
    position: "absolute",
    right: "100000%"
  },
  "& .css-1aquho2-MuiTabs-indicator": {
    backgroundColor: "#324705"
  },
  "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root": {
    textTransform: "capitalize",
    color: "#34623F99",
    fontWeight: 500
  },
  "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected": {
    color: "#324705"
  },
  "& .tab-page-container": {
    position: "relative",
    width: "100%",
    height: "auto"
  },
  "& .tpc-header": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .page-body-cont": {
    marginTop: "20px",
    marginBottom: "120px",
    marginLeft: "7px",
    marginRight: "7px",
    height: "auto",
    padding: "20px",
    boxShadow: "0 10px 10px rgba(0,0,0,.1)",
    borderRadius: "10px"
  }
}));

const GovmentIdUploadContainer = styled("div")(() => ({
  marginTop: ".9rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "end",
  "& .invisible_file_upload": {
    opacity: 0,
    position: "absolute",
    left: "-100%"
  },
  "& .file_upload_cont": {
    border: " 1px dashed #34623F",
    borderColor: "#103C1B",
    cursor: "pointer",
    background: "rgba(52, 98, 63, 0.07)",
    borderRadius: "4px",
    padding: "1rem 5px",
    maxWidth: "205px",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
    justifyContent: "end",
    marginTop: ".4rem",

    "& .trigger-text-cont": {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
    }
  },

  "& .report-name-cont": {
    marginTop: ".4rem",
    fontSize: ".76rem",
    position: "relative",
    width: "70%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",

    "& .remove-report-doc": {
      paddingLeft: "10px",
      cursor: "pointer",
      color: "#F83535"
    }
  }
}));

export default MyAccount;
