import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { useSpeechRecognition } from 'react-speech-recognition';
import { VoiceNote } from '../../provider/pages/PatientDetail/VoiceNote';
import { NoteValue } from "../../provider/pages/PatientDetail/interfaces";
import { FormTypes } from "../../provider/pages/Settings/Templates";
import Spacer from "../Spacer";
import Button from "../shared/Button";
import TextAreaField from "./TextAreaField";
import TextField from "./TextField";

export type FormFields = {
  type:
  | "text"
  | "section"
  | "textarea"
  | "radio"
  | "dropdown"
  | "checkbox"
  | "date"
  | "heading"
  | "break";
  title: string;
  required: boolean;
  options?: string[];
};

type FormBuilderProps = {
  formParams: FormFields[];
  formType: FormTypes;
  submitAction: () => any;
  isPreview: boolean;
  setFormAnswers?: React.Dispatch<React.SetStateAction<NoteValue>>
  formAnswers?: any;
  enableVoice?: boolean;
};

const FormBuilder = ({
  formParams,
  formType,
  submitAction,
  isPreview,
  setFormAnswers,
  formAnswers,
  enableVoice,

}: FormBuilderProps) => {
  const [blurError, setBlurError] = useState<{ [key: string]: boolean }>({});
  const [activeVoiceField, setActiveVoiceField] = useState<string | null>(null);

  const initialFormValues: {
    [key: string]: string | [];
  } = useMemo(() => {
    return formParams
      ?.filter(item => item.type !== "section")
      ?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.title]: curr.type === "checkbox" ? [] : ""
        }),
        {}
      );
  }, [formParams]);

  useEffect(() => {
    if (setFormAnswers && (formAnswers && !Object.keys(formAnswers).length)) {
      setFormAnswers(initialFormValues);
    }
  }, [initialFormValues, setFormAnswers, formAnswers]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any
  ) => {
    if (setFormAnswers) {
      setFormAnswers({
        ...formAnswers,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = [
      { ...formAnswers[e.target?.name][0], [e.target?.value]: e.target?.checked }
    ];
    if (setFormAnswers) {
      setFormAnswers({
        ...formAnswers,
        [e.target?.name]: newOptions
      });
    }
  };

  const handleBlur = (
    name: string,
    value: string | undefined,
    required?: boolean
  ) => {
    if (required) {
      setBlurError({ ...blurError, [name]: !value });
    }
  };

  const handleVoiceStart = (field: string) => {
    setActiveVoiceField(field);
  };

  const handleVoiceStop = () => {
    setActiveVoiceField(null);
  };

  const handleTranscriptChange = (field: string, transcript: string) => {
    if (setFormAnswers) {
      setFormAnswers({
        ...formAnswers,
        [field]: transcript
      });
    }
  };

  const submitButtonText: { [key in FormTypes]: string } = {
    intakeForm: "Continue",
    initialVisitNote: "Save",
    progressNote: "Save",
    consentForm: "Submit"
  };


  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  return (
    <form className="flex flex-col gap-8 mr-4" data-testid="form-builder-container">
      {formParams?.map((item, index: number) => {
        if (item.type === "text") {
          return (
            <section key={`item${index / 20}`} data-testid='text-field-container'>
              <TextField
                name={item.title}
                value={formAnswers && formAnswers[item.title] !== undefined ? formAnswers[item.title] : ""}
                onChange={handleChange}
                label={item.title}
                placeholder=""
                isRequired={item.required}
                showAsterisk={item.required}
                disabled={isPreview}
                onBlur={e =>
                  handleBlur(e.target.name, e.target.value, item.required)
                }
              />
              {enableVoice && (<VoiceNote
                transcript={transcript}
                listening={listening}
                resetTranscript={resetTranscript}
                browserSupportsSpeechRecognition={browserSupportsSpeechRecognition}

                field={item.title}
                activeVoiceField={activeVoiceField}
                onVoiceStart={handleVoiceStart}
                onVoiceStop={handleVoiceStop}
                onTranscriptChange={handleTranscriptChange}
              />)}
              {blurError[item.title] && (
                <p className="text-[#c20707] pt-2">{item.title} is required</p>
              )}
            </section>
          );
        } else if (item.type === "textarea") {
          return (
            <section key={index} data-testid='text-area-container'>
              <TextAreaField
                name={item.title}
                value={formAnswers && formAnswers[item.title] !== undefined ? formAnswers[item.title] : ""}
                onChange={handleChange}
                label={item.title}
                placeholder=""
                isRequired={item.required}
                rows={5}
                disabled={isPreview}
                onBlur={e =>
                  handleBlur(e.target.name, e.target.value, item.required)
                }
              />
              {enableVoice && (<VoiceNote
                transcript={transcript}
                listening={listening}
                resetTranscript={resetTranscript}
                browserSupportsSpeechRecognition={browserSupportsSpeechRecognition}

                field={item.title}
                activeVoiceField={activeVoiceField}
                onVoiceStart={handleVoiceStart}
                onVoiceStop={handleVoiceStop}
                onTranscriptChange={handleTranscriptChange}
              />)}
              {blurError[item.title] && (
                <p className="text-[#c20707] pt-2">{item.title} is required</p>
              )}
            </section>
          );
        } else if (item.type === "radio") {
          return (
            <div key={index} data-testid='radio-field-container'>
              <label className="text-[#000] font-medium text-[16px]">
                {item.title}
              </label>
              {item.options?.map((opt, ind) => {
                return (
                  <div key={ind} className="mr-4 flex mt-2 items-center">
                    <input
                      className="mr-2 w-[18px] h-[18px] border border-slate-800"
                      type="radio"
                      name={item.title}
                      value={opt}
                      checked={
                        formAnswers && formAnswers[item.title] !== undefined ? formAnswers[item.title] === opt : false
                      }
                      disabled={isPreview}
                      onChange={handleChange}
                      required={item.required}
                      onBlur={e =>
                        handleBlur(e.target.name, opt, item.required)
                      }
                    />
                    <span className="text-[#103C1B] font-medium text-[16px]">
                      {opt}
                    </span>
                  </div>
                );
              })}
            </div>
          );
        } else if (item.type === "dropdown") {
          return (
            <div key={index} className="dropdown" >
              <label className="text-[#000] font-medium text-[16px]">
                {item.title}
              </label>
              <div >
                <select
                  className="w-full py-3 px-4 border mt-2 border-grey focus:outline-none rounded"
                  name={item.title}
                  value={formAnswers && formAnswers[item.title] !== undefined ? formAnswers[item.title] : ""}
                  required={item.required}
                  onChange={handleChange}
                  data-testid='dropdown-builder'
                >
                  <option value=""></option>
                  {item.options?.map((opt, ind) => {
                    return <option key={ind}>{opt}</option>;
                  })}
                </select>

              </div>

            </div>
          );
        } else if (item.type === "checkbox") {
          const optionValues: any[] =
            formAnswers && formAnswers[item.title] !== undefined
              ? Object.values(formAnswers[item.title])
              : [];
          return (
            <div key={index}>
              <label className="text-[#000] font-medium text-[16px]">
                {item.title}
              </label>
              <div>
                {item.options?.map((opt, ind) => (
                  <div key={ind} className="flex mt-2 items-center">
                    <input
                      className="mr-2 w-[18px] h-[18px] border border-slate-800"
                      type="checkbox"
                      name={item.title}
                      checked={optionValues.length > 0 && optionValues.some(value => value[opt])}
                      value={opt}
                      onChange={handleCheckBoxChange}
                      disabled={isPreview}
                      required={item.required}
                      onBlur={e =>
                        handleBlur(e.target.name, opt, item.required)
                      }
                      data-testid='checkbox-builder'
                    />{" "}

                    <span className="text-[#103C1B] font-medium text-[16px]">
                      {opt}
                    </span>{" "}
                  </div>
                ))}
              </div>
            </div>
          );
        } else if (item.type === "date") {
          return (
            <div key={index}>
              <label className="text-[#000] font-medium mb-2 text-[16px]">
                {item.title}
                <div>
                  <input
                    type="date"
                    name={item.title}
                    value={formAnswers && formAnswers[item.title] !== undefined ? formAnswers[item.title] : ""}
                    onChange={handleChange}
                    className="border-2 mt-2 w-full h-[50px]"
                    disabled={isPreview}
                    required={item.required}
                    data-testid='date-builder'
                  />
                </div>
              </label>
            </div>
          );
        } else if (item.type === "section") {
          return (
            <section className="mt-5" key={index}>
              <div className="text-[30px] mb-2">{item.title}</div>
              <hr />
            </section>
          );
        } else if (item.type === "heading") {
          return <div key={index} className="text-[20px] mb-2">{item.title}</div>;
        } else if (item.type === "break") {
          return <hr key={index} />;
        }
        return <Fragment />
      })}
      <div className="my-4 w-full flex flex-row justify-end item-center" data-testid='formbuilder-button-container'>
        <Spacer width={10} />
        <Button
          label={submitButtonText[formType]}
          variant="primary"
          width="100px"
          height="40px"
          type="button"
          onClick={() => {
            submitAction();
            resetTranscript();

          }}
          disabled={
            isPreview ||
            (!isPreview && Object.values(blurError).some(item => item))
          }
        />
      </div>
    </form>
  );
};

export default memo(FormBuilder);
