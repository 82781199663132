import { useNavigate } from "react-router-dom";
import BtnButton from "../../../components/shared/BtnButton";
import { ReactComponent as PatientsCountIcon } from "../../../svgs/patients-count-card-icon.svg";

interface PatientsCountCardProps {
  patientsCount?: React.ReactNode;
}

const PatientsCountCard: React.FC<PatientsCountCardProps> = ({
  patientsCount
}) => {
  const navigate = useNavigate();

  return (
    <div data-testid="patients-card"
      className="border-t-2 border-[#D74E09] rounded-[8px] shadow-md shadow-[-2px 4px 25px #EEEEE9] bg-[#fff] w-full h-[300px] py-8 px-4 box-border flex flex-col gap-y-4">
      <div>
        <PatientsCountIcon />
      </div>
      <p className="w-full text-left text-[#324705] text-base lg:text-[19px] py-4">
        <span className="w-full text-left pr-4 font-light text-base lg:text-[19px] xl:text-[24px]" data-testid='patients-count' >
          {patientsCount ?? 0}
        </span>
        Total Patients
      </p>
      <div className="mt-4 w-full flex justify-center" data-testid='patients-button-container'>
        <BtnButton
          data-testid='patients-view-all-button'
          type="button"
          variant="secondary"
          label="View all"
          onClick={() => navigate("/provider/patients")}
        />
      </div>
    </div>
  );
};

export default PatientsCountCard;
