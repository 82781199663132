import { useEffect, useRef, useState } from "react";
import Header from '../../Layout/Header';
import Sidebar from "../../Layout/Sidebar";
import MobileHeader from "./MobileHeader";
import RetractibleSidebar from "./RetractibleSidebar";

interface DashboardLayoutProps {
  children: React.ReactNode;
  headerTitle?: string;
  noGrid?: boolean;
  overflowStyle?: string;
  reff?: any;
  defaultLeftMargin?: boolean;
}

const DashboardLayout = ({
  children,
  headerTitle,
  overflowStyle = "auto",
  reff,
  noGrid
}: DashboardLayoutProps) => {
  const [isRetractableMenuOpen, setIsRetractableMenuOpen] = useState(false);

  const pageTop = useRef<null | HTMLDivElement>(null);

  const scrollToTop = () => {
    pageTop.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="flex flex-col items-center h-screen bg-[#fff] pb-[91px] min-w-screen overflow-hidden">
      {/* <DashboardMainLayout className="block lg:grid"> */}
      <div className="dashh">
        <div className="w-full flex">
          <Sidebar />
          <RetractibleSidebar
            isMenuOpen={isRetractableMenuOpen}
            dismiss={() => {
              setIsRetractableMenuOpen(false);
            }}
          />
        </div>
        <div
          className={`w-full h-screen !relative ${!noGrid ? "dash-body" : "block"
            }`}
        >
          <div className="w-full flex z-9999">
            <Header
              headerTitle={headerTitle}
            />
            <MobileHeader
              openRetractableMenu={() => {
                setIsRetractableMenuOpen(!isRetractableMenuOpen);
              }}
            />
            <div ref={pageTop} />
            <div ref={reff} />
          </div>

          <div
            className="w-full relative pb-[30px] h-screen"
            style={{ overflow: overflowStyle }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
