import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useFormik } from "formik";
import { Fragment, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useUpdateProviderDetails } from "../../../api/hooks/provider/updateProviderDetails";
import { useGetProviderProfileDetails } from "../../../api/hooks/provider/useGetProviderProfileDetails";
import TextField from "../../../components/form/TextField";
import { getInsurancePayers } from "../../../components/lib/utils/utils";
import Button from "../../../components/shared/Button";
import CheckWithLabelToggle from "../../../components/shared/CheckWithLabelToggle";
import { Loading } from "../../../components/shared/Loading";
import { Insurance, insuranceCompanies } from "../../../utils/consts";



const Payment = () => {
  const [allInsuranceProviders, setAllInsuranceProviders] = useState<Insurance[]>([]);

  const { providerData, isLoading, isError, error } = useGetProviderProfileDetails();

  const { updateProviderProfileDetails, isPending } = useUpdateProviderDetails();



  useEffect(() => {
    setAllInsuranceProviders(insuranceCompanies);
  }, []);

  const paymentFormik = useFormik({
    initialValues: {
      paymentType: "directDeposit",
      accountHoldersName: "",
      initialAppointmentFee: providerData?.user?.initialAppointmentFee,
      followUpAppointmentFee: providerData?.user?.followUpAppointmentFee,
      accountNumber: "",
      routingNumber: "",
      emailAddress: "",
      acceptsCash: providerData?.user?.acceptsCash,
      acceptsInsurance: providerData?.user?.acceptsInsurance,
      insuranceProviders: providerData?.user?.insuranceProviders
    },
    validationSchema: Yup.object({
      paymentType: Yup.string().required("Payment Type is required"),
      accountHoldersName: Yup.string().required("Address is required"),
      initialAppointmentFee: Yup.string(),
      accountNumber: Yup.string().required("Account Number is required"),
      routingNumber: Yup.string().required("Routing Number is required"),
      CPBlicenseExpiryDate: Yup.string()
        .email()
        .required("Email Address is required")
    }),
    enableReinitialize: true,

    onSubmit: () => { }
  });


  const insuranceCompaniesFromLocalStorage = getInsurancePayers()
  const [insuranceName, setInsuranceName] = useState("");
  const [insuranceData, setInsuranceData] = useState<Array<any>>([]);

  const handleSearchInsuranceName = useCallback((val: string) => {
    const filteredData = insuranceCompaniesFromLocalStorage?.filter(
      (e: any, i: number) =>
        e.payer_name.toLowerCase().includes(val.toLowerCase())
    );
    setInsuranceData(filteredData);
  }, [insuranceCompaniesFromLocalStorage]);

  useEffect(() => {
    if (insuranceName.length > 2) {
      handleSearchInsuranceName(insuranceName);
    }
    return () => { };
  }, [insuranceName, handleSearchInsuranceName]);

  const addOrRemoveInsuranceProvidersFromSelectedList = (item: string) => {
    const foundItem = paymentFormik.values?.insuranceProviders?.find(
      (value: string) => value === item
    );
    if (foundItem) {
      const updatedList = paymentFormik.values?.insuranceProviders?.filter(
        (value: any) => value !== item
      );
      paymentFormik.setFieldValue("insuranceProviders", updatedList);
    } else {
      const newItemInList: any[] = insuranceData?.filter(
        (value: any) => value.payer_name === item
      );
      const values = newItemInList.map(value => value.payer_name);
      const updatedList = [
        ...(paymentFormik.values?.insuranceProviders || []),
        ...values
      ];
      paymentFormik.setFieldValue("insuranceProviders", updatedList);
    }
  };

  if (isLoading) {
    return <div className="h-1/2 w-1/2 flex justify-center items-center">
      <Loading />
    </div>
  }

  if (isError && axios.isAxiosError(error)) {
    toast.error(error?.response?.data?.error)
  }


  return (
    <>
      <div className="h-full w-full mb-10 overflow-y-auto px-2">
        <div className="text-center w-full  lg:w-[500px]">
          <div className="text-left text-[20px] font-[600] text-[#103C1B] mb-6" data-testid='payment-settings-title'>
            Payment Settings
          </div>
          <form>
            <div className="my-4 ">
              <div className="my-6 text-left">
                <p className="w-full text-left" data-testid='payment-type-title'>How do you want to get paid?</p>
                <div className="h-6" />
                <div data-testid='payment-type-checkbox-container'>
                  <CheckWithLabelToggle
                    labelTestId="insurance-label"
                    label="Insurance"
                    isSelected={paymentFormik.values?.acceptsInsurance || false}
                    toggleSelection={() => {
                      paymentFormik.setFieldValue(
                        "acceptsInsurance",
                        !paymentFormik.values?.acceptsInsurance
                      );
                    }}
                  />
                  <CheckWithLabelToggle
                    labelTestId='cash-label'
                    label="Cash"
                    isSelected={paymentFormik.values?.acceptsCash || false}
                    toggleSelection={() => {
                      paymentFormik.setFieldValue(
                        "acceptsCash",
                        !paymentFormik.values?.acceptsCash
                      );
                    }}
                  />
                </div>
                {paymentFormik.values?.acceptsCash && (
                  <>
                    <div className="w-full lg:w-1/2 my-2 flex items-center">
                      <TextField
                        name="initialAppointmentFee"
                        type="text"
                        placeholder="200"
                        label="Initial Appointment Fee"
                        value={String(paymentFormik.values?.initialAppointmentFee)}
                        onChange={paymentFormik.handleChange}
                      />
                    </div>
                    <div className="w-full lg:w-1/2 my-2 flex items-center">
                      <TextField
                        name="followUpAppointmentFee"
                        type="text"
                        placeholder="150"
                        label="Follow-Up Appointment Fee"
                        value={String(paymentFormik.values?.followUpAppointmentFee)}
                        onChange={paymentFormik.handleChange}
                      />
                    </div>
                  </>
                )}
              </div>

              {paymentFormik.values?.acceptsInsurance ? (
                <div className="w-full">
                  <p className="w-full text-left" data-testid='insurance-providers-title'>
                    What insurance companies do you accept payments from?
                  </p>
                  <div className=" h-6" />
                  <div className="">
                    <TextField
                      name="insuranceProviders"
                      type="text"
                      placeholder="Enter the insurance name here "
                      value={insuranceName}
                      onChange={e => {
                        setInsuranceData([]);
                        setInsuranceName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="flex flex-col items-start gap-1 mt-3">
                    {paymentFormik.values?.insuranceProviders &&
                      paymentFormik.values?.insuranceProviders.map(
                        (item: string, idx: number) => (
                          <div
                            key={idx}
                            className="flex flex-row items-center gap-2 text-[16px]"
                          >
                            <span>{item}</span>
                            <DeleteIcon
                              fontSize="small"
                              className="hover:text-[red] cursor-pointer"
                              onClick={() => {
                                const newList =
                                  paymentFormik.values?.insuranceProviders.filter(
                                    (_: any, index: number) => idx !== index
                                  );
                                paymentFormik.setFieldValue(
                                  "insuranceProviders",
                                  newList
                                );
                              }}
                            />
                          </div>
                        )
                      )}
                  </div>
                  <div className="w-2/3 my-6 ml-4 box-border">
                    <div className="max-w-full flex items-center flex-wrap">
                      {insuranceData?.map((provider: any, idx: number) => (
                        <div className="flex flex-wrap " key={idx}>
                          <Fragment>
                            <CheckWithLabelToggle
                              label={provider?.payer_name}
                              isSelected={Boolean(
                                paymentFormik.values?.insuranceProviders?.find(
                                  (el: any) => el === provider?.payer_name
                                )
                              )}
                              toggleSelection={async () => {
                                await addOrRemoveInsuranceProvidersFromSelectedList(
                                  provider?.payer_name
                                );
                              }}
                            />
                          </Fragment>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* 
              <div className="my-6">
                <div className="text-[18px] font-[500] text-left">
                  Direct Deposit Information
                </div>
              </div>

              <div className="my-6">
                <TextField
                  name="accountHoldersName"
                  type="text"
                  placeholder="Account Holder's Name"
                  value={paymentFormik.values.accountHoldersName}
                  onChange={paymentFormik.handleChange}
                  label="Account holder's name"
                  error={
                    paymentFormik.touched.accountHoldersName
                      ? paymentFormik.errors.accountHoldersName
                      : ""
                  }
                />
              </div>

              <div className="my-6">
                <TextField
                  name="accountNumber"
                  type="text"
                  placeholder="Account number"
                  value={paymentFormik.values.accountNumber}
                  onChange={paymentFormik.handleChange}
                  label="Account Number"
                  error={
                    paymentFormik.touched.accountNumber
                      ? paymentFormik.errors.accountNumber
                      : ""
                  }
                />
              </div>

              <div className="my-6">
                <TextField
                  name="routingNumber"
                  type="text"
                  placeholder="Routing number"
                  value={paymentFormik.values.routingNumber}
                  onChange={paymentFormik.handleChange}
                  label="Routing Number"
                  error={
                    paymentFormik.touched.routingNumber
                      ? paymentFormik.errors.routingNumber
                      : ""
                  }
                />
              </div> */}
            </div>
          </form>
        </div>
      </div>
      <div className="h-[96px] w-full bg-white border-t border-[#ddd] bottom-0 sticky">
        <div className="h-full flex justify-end items-center">
          <div className="w-[122px] lg:mr-5" data-testid='save-changes-button-container'>
            <Button
              type="button"
              variant="primary"
              label="Save changes"
              size="medium"
              loading={isPending}
              onClick={() => {
                updateProviderProfileDetails({
                  ...(paymentFormik.values?.acceptsCash && {
                    acceptsCash: paymentFormik.values?.acceptsCash
                  }),
                  ...(paymentFormik.values?.acceptsCash && {
                    initialAppointmentFee: paymentFormik.values?.initialAppointmentFee
                  }),
                  ...(paymentFormik.values?.acceptsCash && {
                    followUpAppointmentFee: paymentFormik.values?.followUpAppointmentFee
                  }),
                  ...(paymentFormik.values?.acceptsInsurance && {
                    acceptsInsurance: paymentFormik.values?.acceptsInsurance
                  }),
                  insuranceProviders: paymentFormik.values?.insuranceProviders || []
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
