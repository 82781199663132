import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { SOCKET_API_URL } from "../constants/base_url";

const useWebRTC = () => {
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const peerConnectionRef = useRef<RTCPeerConnection | null>(null);
  const socketRef = useRef<any>(null);

  // Initialize WebRTC
  useEffect(() => {
    const initializeWebRTC = async () => {
      // Connect to signaling server
      const socket = io(SOCKET_API_URL);
      socketRef.current = socket;

      // Get local media stream
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });
      setLocalStream(stream);

      // Create peer connection
      const peerConnection = new RTCPeerConnection({
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }] // Use public STUN servers
      });
      peerConnectionRef.current = peerConnection;

      // Add local stream to peer connection
      stream
        .getTracks()
        .forEach(track => peerConnection.addTrack(track, stream));

      // Handle remote stream
      peerConnection.ontrack = event => {
        setRemoteStream(event.streams[0]);
      };
      console.log({ peerConnection });

      // Handle ICE candidates
      peerConnection.onicecandidate = event => {
        console.log({ event });
        if (event.candidate) {
          socket.emit("signal", {
            target: "other-peer-id", // Replace with the target peer's ID
            payload: { type: "ice-candidate", candidate: event.candidate }
          });
        }
      };

      // Handle signaling data
      socket.on("signal", data => {
        console.log("Signal received: ", data);
        if (data.payload.type === "offer") {
          handleOffer(data.payload);
        } else if (data.payload.type === "answer") {
          handleAnswer(data.payload);
        } else if (data.payload.type === "ice-candidate") {
          handleICECandidate(data.payload.candidate);
        }
      });
    };

    initializeWebRTC();

    // Cleanup
    return () => {
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
      }
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  // Handle incoming offer
  const handleOffer = async (offer: RTCSessionDescriptionInit) => {
    if (!peerConnectionRef.current) return;
    await peerConnectionRef.current.setRemoteDescription(
      new RTCSessionDescription(offer)
    );
    const answer = await peerConnectionRef.current.createAnswer();
    await peerConnectionRef.current.setLocalDescription(answer);
    socketRef.current.emit("signal", {
      target: "other-peer-id", // Replace with the target peer's ID
      payload: { type: "answer", answer }
    });
  };

  // Handle incoming answer
  const handleAnswer = async (answer: RTCSessionDescriptionInit) => {
    if (!peerConnectionRef.current) return;
    await peerConnectionRef.current.setRemoteDescription(
      new RTCSessionDescription(answer)
    );
  };

  // Handle incoming ICE candidate
  const handleICECandidate = async (candidate: RTCIceCandidateInit) => {
    if (!peerConnectionRef.current) return;
    await peerConnectionRef.current.addIceCandidate(
      new RTCIceCandidate(candidate)
    );
  };

  // Create an offer (initiate a call)
  const createOffer = async () => {
    if (!peerConnectionRef.current) return;
    const offer = await peerConnectionRef.current.createOffer();
    await peerConnectionRef.current.setLocalDescription(offer);
    socketRef.current.emit("signal", {
      target: "other-peer-id", // Replace with the target peer's ID
      payload: { type: "offer", offer }
    });
  };

  return { localStream, remoteStream, createOffer };
};

export default useWebRTC;
