import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import http from "../../../utils/http";


type Payload = {
  cancel_url: string;
  plan: string;
  customerId: string;
}


export const useSubscriptionPayment = () => {

  const updateToast = (toastId: number, message: string, type: "success" | "error") => {
    toast.update(toastId, {
      render: message,
      type,
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });
  };

  const { mutate, ...rest } = useMutation({
    mutationKey: ['pay subscription'],
    mutationFn: async (data: Payload) => {
      const response = await http.post("/stripe/create-provider-subscription", data);
      return response.data;
    },
    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },
    onSuccess: (data, _variables, context) => {
      const toastId = context?.toastId as number;
      updateToast(toastId, "Payment initiated successfully", "success");

      if (data?.url) {
        window.location.href = data.url;
      } else {
        updateToast(toastId, "Invalid redirect URL. Please try again.", "error");
      }
    },
    onError: (error: unknown, _variables, context) => {
      const toastId = context?.toastId as number;
      const errorMessage = axios.isAxiosError(error)
        ? error?.response?.data?.error || "Failed to process payment. Please try again."
        : error instanceof Error
          ? error.message
          : "Failed to process payment. Please try again.";

      updateToast(toastId, errorMessage, "error");
    },
  });

  return {
    initiateSubsPayment: mutate,
    ...rest,
  };
};
