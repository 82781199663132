import { JaaSMeeting } from "@jitsi/react-sdk";
import axios from "axios";
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { Socket } from "socket.io-client";
import { useProviderAuthState } from '../../api/hooks/provider/auth/useProviderAuthState';
import { useCancelAppointment } from '../../api/hooks/provider/useCancelAppointment';
import { useGetPatientFormResponse } from '../../api/hooks/provider/useGetPatientResponse';
import { useGetProviderAppointmentCount } from '../../api/hooks/provider/useGetProviderApointmentCount';
import { useGetProviderForm } from '../../api/hooks/provider/useGetProviderForm';
import { useGetProviderProfileDetails } from '../../api/hooks/provider/useGetProviderProfileDetails';
import { useGetPatientUpcomingAppointments } from '../../api/hooks/provider/useGetProviderUpcomingAppointsments';
import { useGetSinglePatientRecord } from "../../api/hooks/provider/useGetSinglePatientRecord";
import { useGetProviderJitsiToken } from "../../api/hooks/provider/useProviderJitsiToken";
import { useGetProviderPatientsCount } from "../../api/hooks/provider/useProviderPatientsCount";
import { useSaveChargeSheetDraft } from "../../api/hooks/provider/useSaveChargeSheet";
import { useSaveFormResponse } from "../../api/hooks/provider/useSaveFormResponse";
import { useSendChargeSheetToBilling } from "../../api/hooks/provider/useSendChargeSheetToBilling";
import { UpcomingAppointmentType } from "../../api/types";
import ReusableTable from "../../components/ReusableTable";
import FormBuilder from "../../components/form/FormBuilder";
import SearchField from "../../components/form/SearchField";
import { VideoModal } from "../../components/introVideoModal";
import Button from "../../components/shared/Button";
import ListTabsWidget from "../../components/shared/ListTabsWidget";
import { Loading } from "../../components/shared/Loading";
import useWebRTC from "../../hooks/useWebRTC";
import AIGenerate from "../../svgs/AIGenerate";
import WritingNotes from "../../svgs/WritingNotes";
import { ReactComponent as EmptyMsgIcon } from "../../svgs/empty-smg.svg";
import { Appointment } from "../../types/appointment";
import { NoteValue } from "../pages/PatientDetail/interfaces";
import { SimpleChargeSheetModal } from "./SimpleModal";
import MobileAppointmentCard from "./appointments/MobileAppointmentCard";
import ProviderAppointmentsDrawer from "./appointments/ProviderAppointmentDrawer";
import RescheduleAppointmentModal from "./appointments/RescheduleAppointment";
import AppointmentsCountCard from "./dashboard/AppointmentsCountCard";
import CallRecord from "./dashboard/CallRecord";
import EventCard from "./dashboard/EventCard";
import PatientsCountCard from "./dashboard/PatientsCountCard";
import ProviderDashboardLayout from "./dashboard/ProviderDashboardLayout";
import { useOverViewTableCols } from "./table/hooks/use_overview_cols";
import { getStartTimeOneHourAhead } from "./utils/getStartTimeOneHourAhead";

const ProviderDashboardOverview = ({ socket }: { socket: Socket }) => {
  const location = useLocation();
  // Check whether the user is coming from the login or professional-details page so they can be shown the intro video modal
  const fromLoginOrProfessionalDetails = [
    "/professional-details",
    "/login"
  ].includes(location.state?.from);
  const { user } = useProviderAuthState();
  const [upcomingAppointmentsNow, setUpcomingAppointmentNow] = useState<
    UpcomingAppointmentType[]
  >([]);
  const [appointmentInfo, setAppointmentInfo] = useState<Appointment | null>(
    null
  );
  const [openAppointmentDetails, setOpenAppointmentDetails] =
    useState<boolean>(false);
  const [joinCall, setJoinCall] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeVideoTabLabel, setActiveVideoTabLabel] = useState("Intake Form");
  const [showChargeSheetModal, setShowChargeSheetModal] =
    useState<boolean>(false);
  const [jitsiToken, setJitsiToken] = useState<string>("");
  const [isRescheduleViewVisible, setIsRescheduleViewVisible] =
    useState<boolean>(false);
  const [oldAppointmentForReschedule, setOldAppointmentForReschedule] =
    useState("");
  const [appointmentId, setAppointmentId] = useState<string>("");
  const [isThirtyMinutesBefore, setIsThirtyMinutesBefore] =
    useState<boolean>(false);
  const [patientId, setPatientId] = useState<string>("");
  const [noteFields, setNoteFields] = useState<NoteValue>({});
  const [isIntroVideo, setIsIntroVideo] = useState<boolean>(
    fromLoginOrProfessionalDetails
  );
  const [noteGenerationStatus, setNoteGenerationStatus] = useState<
    "neutral" | "ai-generation" | "manual"
  >("neutral");
  const apiRef = useRef<any>(null);
  const { remoteStream } = useWebRTC();

  const jaasAppId = process.env.REACT_APP_JAAS_APP_ID || "";

  const handleVideoTabClick = (tabName: string) => {
    setActiveVideoTabLabel(tabName);
  };

  const updateSearch = (event: any) => {
    setSearchTerm(event.target.value.trim().substr(0, 30));
  };

  const {
    getProviderAppointmentCount,
    isError: isProvApmntError,
    isLoading: isLoadProvApmnt,
    error: provApmntError
  } = useGetProviderAppointmentCount();

  const { providerAppointments, isLoading, isError, error } =
    useGetPatientUpcomingAppointments();
  const { data: patientData } = useGetSinglePatientRecord(patientId);

  const filteredData =
    providerAppointments?.providersUpcomingAppointments &&
    providerAppointments?.providersUpcomingAppointments?.filter(
      ({ patientName }) => {
        return (
          patientName?.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        );
      }
    );

  if (isError) {
    const message = axios.isAxiosError(error)
      ? error?.response?.data?.error
      : "Error";
    toast.error(message, { toastId: "customId" });
  }

  if (isProvApmntError) {
    const message = axios.isAxiosError(provApmntError)
      ? provApmntError?.response?.data?.error
      : "Error";
    toast.error(message, { toastId: "customId" });
  }

  const {
    getProviderPatientCount,
    isError: isProvPatientError,
    isLoading: isLoadProvPatient,
    error: provPatientError
  } = useGetProviderPatientsCount();

  if (isProvPatientError) {
    const message = axios.isAxiosError(provPatientError)
      ? provPatientError?.response?.data?.error
      : "Error";
    toast.error(message, { toastId: "customId" });
  }

  useEffect(() => {
    if (providerAppointments?.providersUpcomingAppointments) {
      const appointmentHoldingNow = (
        providerAppointments?.providersUpcomingAppointments || []
      )?.filter(
        (item: any) =>
          new Date().getTime() >= new Date(item?.appointmentDate).getTime()
      );
      setUpcomingAppointmentNow(appointmentHoldingNow);
    }
  }, [providerAppointments?.providersUpcomingAppointments]);


  const { getJitsiToken, refetchGetJitsiToken } =
    useGetProviderJitsiToken(joinCall);

  useEffect(() => {
    if (joinCall) {
      refetchGetJitsiToken();
    }
  }, [joinCall, refetchGetJitsiToken]);

  const { saveFormResponse } = useSaveFormResponse();

  const { providerFormData } = useGetProviderForm({
    formType: appointmentInfo?.isInitialAppointment
      ? ["initialVisitNote"]
      : ["progressNote"],
    providerId: user?.userId ? user?.userId : ""
  });

  const {
    data,
    isError: isErr,
    error: err,
    isLoading: isLoad
  } = providerFormData[0];

  if (isErr && axios.isAxiosError(err)) {
    toast.error(data?.error?.message, { toastId: "customId" });
  } else if (data?.error !== null) {
    toast.error(data?.error, { toastId: "customId" });
  } else {
    toast.error(
      data?.error?.message || "Error fetching provider's Intake Form",
      { toastId: "customId" }
    );
  }

  const { saveChargeSheet, isPending: isSavingDraft } =
    useSaveChargeSheetDraft();
  const { postChargeSheetToBilling, isPending: isSendingToBilling } =
    useSendChargeSheetToBilling();
  const {
    patientFormResponseData,
    isLoading: loading,
    isError: isPError,
    error: pError
  } = useGetPatientFormResponse({ appointmentId });

  if (isPError && axios.isAxiosError(pError)) {
    toast.error(pError?.response?.data?.error, { toastId: "customId" });
  }

  const { cancelAppointment } = useCancelAppointment();

  useEffect(() => {
    if (getJitsiToken) {
      setJitsiToken(getJitsiToken);
    }
  }, [getJitsiToken]);

  useEffect(() => {
    if (upcomingAppointmentsNow.length === 0) {
      setIsThirtyMinutesBefore(false);
    } else {
      const eventStartTime =
        getStartTimeOneHourAhead(upcomingAppointmentsNow) || "00:00:00";
      const currentTime = new Date().toLocaleTimeString("en-US", {
        hour12: false
      });

      const [eventHour, eventMinute] = eventStartTime.split(":")?.map(Number);
      const [currentHour, currentMinute] = currentTime.split(":")?.map(Number);

      const eventTimeInMinutes = eventHour * 60 + eventMinute;
      const currentTimeInMinutes = currentHour * 60 + currentMinute;

      const thirtyMinutesBeforeEvent = eventTimeInMinutes - 30;

      setIsThirtyMinutesBefore(
        currentTimeInMinutes >= thirtyMinutesBeforeEvent &&
        currentTimeInMinutes < eventTimeInMinutes
      );
    }
  }, [upcomingAppointmentsNow]);


  // const checkThirtyMinutesBefore = useCallback(() => {
  //   if (upcomingAppointmentsNow.length === 0) {
  //     setIsThirtyMinutesBefore(false);
  //     return;
  //   }
  //   const eventStartTime =
  //     getStartTimeOneHourAhead(upcomingAppointmentsNow) || "00:00:00";
  //   const currentTime = new Date().toLocaleTimeString("en-US", {
  //     hour12: false
  //   });

  //   const [eventHour, eventMinute] = eventStartTime.split(":").map(Number);
  //   const [currentHour, currentMinute] = currentTime.split(":").map(Number);

  //   const eventTimeInMinutes = eventHour * 60 + eventMinute;
  //   const currentTimeInMinutes = currentHour * 60 + currentMinute;

  //   const thirtyMinutesBeforeEvent = eventTimeInMinutes - 30;

  //   setIsThirtyMinutesBefore(
  //     currentTimeInMinutes >= thirtyMinutesBeforeEvent &&
  //     currentTimeInMinutes < eventTimeInMinutes
  //   );
  // }, [upcomingAppointmentsNow]);


  // useEffect(() => {
  //   const interval = setInterval(checkThirtyMinutesBefore, 1000); // Check every second
  //   return () => clearInterval(interval);
  // }, [checkThirtyMinutesBefore]);

  if (providerFormData[0].isLoading || isLoad || isLoading) {
    <div className="fixed inset-0 z-[10000000] backdrop-blur-sm flex justify-center w-full h-screen items-center">
      <Loading />
    </div>;
  }
  const { TABLE_COLUMNS } = useOverViewTableCols();

  const fullPatientData = useMemo(() => {
    return {
      appointmentId: appointmentInfo?.appointmentId,
      patientName: `${patientData?.patientDetails.patient.firstName}${" "}${patientData?.patientDetails.patient.lastName}`,
      providerId: patientData?.patientDetails.patient?.userId,
      dateOfBirth: patientData?.patientDetails.patient.dateOfBirth,
      gender: patientData?.patientDetails.patient.gender,
      patientId: patientData?.patientDetails.patient.userId,
      insurance: patientData?.patientDetails.patient.insuranceName,
      modeOfEncounter: appointmentInfo?.appointmentType as
        | "virtual"
        | "physical",
      dateOfEncounter: appointmentInfo?.appointmentDate as string
    };
  }, [patientData, appointmentInfo]);

  const adjustedAppointments =
  providerAppointments?.providersUpcomingAppointments.map(
    ({ appointmentDate, appointmentStartTime, appointmentEndTime, ...rest }) => {
      const startTimeParts = appointmentStartTime.split(":");
      const endTimeParts = appointmentEndTime.split(":");

      // Convert appointmentDate to user's timezone
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's timezone
      const composeStartDate = toZonedTime(new Date(appointmentDate), timeZone);
      const composeEndDate = toZonedTime(new Date(appointmentDate), timeZone);

      // Adjust start and end times
      composeStartDate.setHours(composeStartDate.getHours() + Number(startTimeParts[0]), Number(startTimeParts[1]));
      composeEndDate.setHours(composeEndDate.getHours() + Number(endTimeParts[0]), Number(endTimeParts[1]));

      // Format dates
      const formatStartDate = formatInTimeZone(composeStartDate, timeZone, "yyyy/MM/dd HH:mm");
      const formatEndDate = formatInTimeZone(composeEndDate, timeZone, "yyyy-MM-dd HH:mm");

      return {
        ...rest,
        appointmentDate: formatStartDate.split(" ")[0],
        appointmentNewDate: formatStartDate.split(" ")[0],
        appointmentStartTime: formatStartDate.split(" ")[1],
        appointmentEndTime: formatEndDate.split(" ")[1],
      };
    }
  );

        const { providerData, isLoading: isLoadingProvider } = useGetProviderProfileDetails()

        useEffect(() => {
          if (!isLoadingProvider && providerData?.user?.hideIntroModal) {
            setIsIntroVideo(false)
          }
        }, [providerData, isLoadingProvider]);

  const closeVideoModal = () => {
    setIsIntroVideo(false);
  };

  return (
    <>
      {joinCall ? (
        <div className="h-full flex items-center " data-testid='join-call-container'>
          <JaaSMeeting

            appId={jaasAppId}
            roomName={`${appointmentInfo?.patientId as string}${user?.userId as string}`}
            jwt={jitsiToken}
            useStaging={process.env.NODE_ENV === "development"} // dev should remove this prop when the app goes live
            configOverwrite={{
              disableThirdPartyRequests: true,
              disableLocalVideoFlip: true,
              backgroundAlpha: 0.5,
              disableModeratorIndicator: true,
              // startWithAudioMuted: true,
              // startScreenSharing: false,
              // enableEmailInStats: false
              // toolbarButtons: ["recording"]
            }}
            interfaceConfigOverwrite={{
              VIDEO_LAYOUT_FIT: "nocrop",
              MOBILE_APP_PROMO: false,
              TILE_VIEW_MAX_COLUMNS: 4
              // DISABLE_JOIN_LEAVE_NOTIFICATIONS: false
              // SHOW_PROMOTIONAL_CLOSE_PAGE: true
            }}
            userInfo={{
              displayName: `${user?.firstName} ${user?.lastName}`,
              email: user?.email || ""
            }}
            onReadyToClose={() => {
              setJoinCall(false);
              setShowChargeSheetModal(true);
            }}
            getIFrameRef={(iframeRef: any) => {
              iframeRef.style.height = "100%";
              iframeRef.style.width = "60%";
            }}
            data-testid="join-call-iframe"
            onApiReady={(externalApi) => {
              // Store the API reference when it's ready
              apiRef.current = externalApi;
            }}
          />
          <div className="w-[40%] h-full px-4 py-5" data-testid="list-tabs-form-container">
            <div className="overflow-y-hidden mb-6 px-4 w-full overflow-x-scroll lg:overflow-x-hidden py-4" data-testid="tabs-container">
              <ListTabsWidget
                tabs={[{ label: "Intake Form" }, { label: "Notes" }]}
                handleTabClick={label => {
                  handleVideoTabClick(label);
                }}
                activeTabLabel={activeVideoTabLabel}
              />
            </div>
            {loading ? (
              <div className="flex justify-center items-center h-1/2">
                <Loading />
              </div>
            ) : (
              <>
                {activeVideoTabLabel === "Intake Form" && (
                  <div className="w-full h-full">
                    <div className="w-full flex justify-between items-center mb-6">
                      <p>
                        Intake form answers: {`${appointmentInfo?.patientName}`}
                      </p>
                    </div>
                    {patientFormResponseData?.formResponse?.length ? (
                      <div className="w-full h-full">
                        {patientFormResponseData?.formResponse?.map(
                          (item: any, idx: number) => (
                            <section key={idx} className="text-[18px]">
                              {Object.entries(item)?.map(([key, value]: any) => (
                                <div className="w-full pb-5" key={key}>
                                  <p className="font-semibold">{key}</p>
                                  {Array.isArray(value) ? (
                                    Object.keys(value[0])?.map(
                                      (item: any, index) => (
                                        <div key={index}>
                                          <input
                                            className="accent-[#3D874E]"
                                            type="checkbox"
                                            checked={value[0][item]}
                                            readOnly={true}
                                          />{" "}
                                          <span>{item}</span>
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <p>{value}</p>
                                  )}
                                </div>
                              ))}
                            </section>
                          )
                        )}
                      </div>
                    ) : (
                      <div className="w-full h-1/2">
                        {patientFormResponseData?.message}
                      </div>
                    )}
                  </div>
                  )}
                {activeVideoTabLabel === "Notes" && (
                  <>
                    {data?.initialVisitNote?.noteFields?.length ||
                    data?.progressNote?.noteFields?.length > 0 ? (
                      <div className="w-full h-full overflow-y-auto">
                        <div className="w-full flex justify-between items-center mb-12">
                          <p>
                            Progress Note: {`${appointmentInfo?.patientName}`}
                          </p>
                          <p>X</p>
                        </div>
                        {noteGenerationStatus === "neutral" && (
                          <div className="flex justify-between">
                            <div className="flex flex-col gap-5 shadow-xl px-2 py-4 text-primary-main">
                              <AIGenerate />
                              <p>Use AI to generate notes</p>
                              <p>
                                Super easy and fast. Takes the stress of
                                manually generating clinical notes away.{" "}
                                <strong>Charges apply</strong>
                              </p>
                              <Button
                                variant="secondary"
                                textColor="main"
                                label="Start Generating"
                                onClick={() =>
                                  setNoteGenerationStatus("ai-generation")
                                }
                              />
                            </div>
                            <div className="flex flex-col gap-5 shadow-xl px-2 py-4 text-primary-main">
                              <WritingNotes />
                              <p>Manually generate notes</p>
                              <p>
                                Perfect if you prefer generating your notes
                                yourself. Use your created template for this.
                              </p>
                              <Button
                                variant="secondary"
                                textColor="main"
                                label="Start Generating"
                                onClick={() =>
                                  setNoteGenerationStatus("manual")
                                }
                              />
                            </div>
                          </div>
                        )}
                        {noteGenerationStatus === "ai-generation" && (
                          <CallRecord remoteStream={remoteStream} jitsiApi={apiRef.current} setNoteGenerationStatus={setNoteGenerationStatus} socket={socket} />
                        )}
                        {noteGenerationStatus === "manual" && (
                          <div className="w-full h-full">
                            <FormBuilder
                              formParams={
                                appointmentInfo?.isInitialAppointment
                                  ? data?.initialVisitNote?.noteFields ?? []
                                  : data?.progressNote?.noteFields ?? []
                              }
                              formType={
                                appointmentInfo?.isInitialAppointment
                                  ? "initialVisitNote"
                                  : "progressNote"
                              }
                              submitAction={() => {
                                saveFormResponse({
                                  noteType:
                                    appointmentInfo?.isInitialAppointment
                                      ? "initialVisitNote"
                                      : "progressNote",
                                  noteFields: [noteFields],
                                  appointmentId:
                                    appointmentInfo?.appointmentId ?? "",
                                  patientId: appointmentInfo?.patientId ?? ""
                                });
                              }}
                              isPreview={false}
                              formAnswers={noteFields}
                              setFormAnswers={setNoteFields}
                            />
                            <p
                              className="py-5 text-primary-900 text-lg cursor-pointer text-center underline"
                              onClick={() =>
                                setNoteGenerationStatus("ai-generation")
                              }
                            >
                              Switch to AI Generation?
                            </p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>No notes!</div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <ProviderDashboardLayout>
          <div className="w-full h-full px-4 mt-4 lg:mt-16">
              <div className="flex flex-wrap lg:flex-nowrap items-center justify-start my-4 gap-4">
              <div className="w-full lg:w-[402px]">
                {isLoadProvApmnt ? (
                  <div className="flex justify-center items-center h-1/2">
                    <div className="flex justify-center items-center h-1/2">
                      <Loading />
                    </div>
                  </div>
                ) : (
                  <AppointmentsCountCard
                    appointmentCount={
                      getProviderAppointmentCount?.numberOfAppointmentsToday
                    }
                  />
                )}
              </div>
              <div className="w-4 hidden lg:block" />

              <div className="w-full mt-4 lg:mt-0 lg:w-[402px]">
                {isLoadProvPatient ? (
                  <div className="flex justify-center items-center h-1/2">
                    <div className="flex justify-center items-center h-1/2">
                      <Loading />
                    </div>
                  </div>
                ) : (
                  <PatientsCountCard
                    patientsCount={getProviderPatientCount?.numberOfPatients}
                  />
                )}
              </div>
                <div className="w-full lg:w-[402px]" data-testid="upcoming-appointments-card">
                  {isThirtyMinutesBefore && upcomingAppointmentsNow.length > 0 ? (
                    upcomingAppointmentsNow?.map((eventItem) => (
                      <EventCard
                        key={eventItem?.id}
                        name={eventItem?.patientName}
                        diagnosis={eventItem?.diagnosis ?? "No Diagnosis"}
                        startTime={eventItem?.appointmentStartTime}
                        endTime={eventItem?.appointmentEndTime}
                        onClick={() => {
                          if (eventItem?.appointmentId) {
                            setAppointmentId(eventItem?.appointmentId);
                            setPatientId(eventItem?.patientId);
                          }
                          setAppointmentInfo(eventItem);
                          setOpenAppointmentDetails(true);
                          providerFormData[0].refetch();
                        }}
                      />
                    ))
                  ) : (
                      <p className="w-full text-center text-[20px] text-[#324705] font-[500] lg:block" data-testid="empty-appointments-message">
                      No Events
                    </p>
                  )}
                </div>

            </div>
              <div className="w-full" data-testid="upcoming-appointments-section-container">
                <p className="text-sm lg:text-[28px] text-[#324705] font-[600] py-4 ml-4" data-testid="upcoming-appointments-section">
                Upcoming Appointments
              </p>
              {providerAppointments?.providersUpcomingAppointments?.length ? (
                  <Fragment>
                    <div className="hidden lg:block w-full my-4" data-testid='upcoming-appointments-table-section'>
                    {isLoadProvApmnt ? (<div className="flex justify-center items-center h-1/2">
                      <Loading />
                    </div>) : (
                          <ReusableTable
                        tableColumns={TABLE_COLUMNS}
                        tableData={
                          adjustedAppointments as UpcomingAppointmentType[]
                        }
                        onClick={data => {
                          if (data?.appointmentId) {
                            setAppointmentId(data?.appointmentId);
                            setPatientId(data?.patientId);
                          }
                          setAppointmentInfo(data);
                          setOpenAppointmentDetails(true);
                          providerFormData[0].refetch();
                        }}
                        searchFieldPlaceholder="Search Patient"
                      />
                    )}
                  </div>
                    <div className="w-fullblock lg:hidden  pb-2" data-testid="search-field-section">
                    <SearchField
                      placeholder="Search appointment"
                      value={searchTerm}
                      onChange={updateSearch}
                      name="searchTerm"
                    />
                  </div>
                    <div className="w-full block lg:hidden lg:mt-8 shadow-[-1.74129px_3.48259px_21.7662px_#EEEEE9]" data-testid="mobile-appointment-list">
                    <p className="w-full h-[53px] bg-[#EEEEE9] text-[#34623F] text-[500] flex items-center my-2 px-4">
                      Patients
                    </p>
                    {filteredData &&
                      filteredData?.map((item: any, index: number) => (
                        <MobileAppointmentCard
                          key={index}
                          appointmentDetails={item && item}
                        />
                      ))}
                  </div>
                  </Fragment>
              ) : (
                    <Fragment>
                      <div className="h-full w-full m-auto mt-[40px] flex flex-col justify-center items-center" data-testid="empty-appointments-container">
                        <EmptyMsgIcon data-testid="empty-msg-icon" />
                        <div className="font-semibold text-[#34623f] mt-4 text-center" data-testid="empty-appointments-message">
                      Nothing here
                    </div>
                        <div className="text-[#103C1B] pt-2 lg:w-[322px] text-center" data-testid="empty-appointments-submessage">
                      You don’t have any upcoming appointments
                    </div>
                  </div>
                    </Fragment>
              )}
            </div>
          </div>
          <ProviderAppointmentsDrawer
            setIsRescheduleViewVisible={setIsRescheduleViewVisible}
            setOldAppointmentForReschedule={setOldAppointmentForReschedule}
            open={openAppointmentDetails}
            onClose={() => {
              setOpenAppointmentDetails(false);
            }}
            drawerTitle="Appointment Details"
              appointmentDetails={appointmentInfo as Appointment}
              clickJoinCall={() => {
                setJoinCall(true)
                setOpenAppointmentDetails(false);
            }}
            patientIntakeFormResponse={patientFormResponseData?.formResponse}
            handleCancelAppointment={() => {
              cancelAppointment({
                appointmentId: appointmentInfo?.appointmentId
              });
              setOpenAppointmentDetails(false);
              setAppointmentInfo(null);
            }}
              data-testid="provider-appointment-drawer"
          />
          <RescheduleAppointmentModal
            isVisible={isRescheduleViewVisible}
            closeModal={() => {
              setIsRescheduleViewVisible(false);
            }}
            oldId={oldAppointmentForReschedule}
          />
        </ProviderDashboardLayout>
      )}
      {showChargeSheetModal ? (
        <div className="w-full h-[90%] lg:max-w-[90%] max-w-[95%] flex items-center justify-center border-box">
          <div className="w-full h-[90%] lg:max-w-[90%] max-w-[95%] my-8 mx-4 overflow-y-scroll rounded-tl-[16px]">
            <SimpleChargeSheetModal
              open={showChargeSheetModal}
              setOpen={setShowChargeSheetModal}
              chargeSheetData={fullPatientData}
              isSendingToBilling={isSendingToBilling}
              isSavingDraft={isSavingDraft}
              isFromNewBilling={false}
              saveAction={({
                patientId,
                appointmentId,
                procedureCodes,
                diagnosisCodes,
                modeOfEncounter,
                locationOfEncounter,
                dateOfEncounter
              }) => {
                saveChargeSheet({
                  patientId,
                  appointmentId,
                  procedureCodes,
                  diagnosisCodes,
                  status: "draft",
                  modeOfEncounter,
                  locationOfEncounter,
                  dateOfEncounter
                });
                setShowChargeSheetModal(false);
              }}
              sendToBillingAction={({
                patientId,
                appointmentId,
                procedureCodes,
                diagnosisCodes,
                modeOfEncounter,
                locationOfEncounter,
                dateOfEncounter
              }) => {
                postChargeSheetToBilling({
                  patientId,
                  appointmentId,
                  procedureCodes,
                  diagnosisCodes,
                  status: "processing",
                  modeOfEncounter,
                  locationOfEncounter,
                  dateOfEncounter
                });
                setShowChargeSheetModal(false);
              }}
            />
          </div>
        </div>
      ) : null}
      {isIntroVideo ? (
        <VideoModal isOpen={isIntroVideo} handleClose={closeVideoModal} />
      ) : null}
    </>
  );
};

export default ProviderDashboardOverview;
