import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig
} from "axios";
import { API_URL } from "../constants/base_url";
import { getToken, removeToken } from "./helpers";

const http: AxiosInstance = axios.create({
  baseURL: API_URL
});

http.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const token = getToken();
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error: any) => {
    if (error.response && error.response.status === 401) {
      const fullPath = window.location.pathname;
      const userPath = fullPath.substring(
        1,
        fullPath.substring(1).indexOf("/") + 1
      );
      removeToken(userPath);
    }
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response: AxiosResponse<any, any>) {
    return response;
  },
  function (error: any) {

    const fullPath = window.location.pathname;
    const userPath = fullPath.substring(
      1,
      fullPath.substring(1).indexOf("/") + 1
    );
    if (error.response?.status === 401) {
      // Only logout if it's an authentication endpoint failure
      if (error.config?.url?.includes("/auth/")) {
        localStorage.removeItem("temple_user_token");
        window.location.href = `/${
          userPath === "provider" ? "provider" : "patient"
        }/login?authFail=true`;
      }
      // For other 401s, just log the error
      console.error("Unauthorized request:", error.config?.url);
    }
    return Promise.reject(error);
  }
);

export default http;
