import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Socket } from "socket.io-client";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCurrentUser } from "../api/auth";
import { useGetPatientProviderList } from '../api/hooks/patient/useGetPatientProviders';
import MessageChat from "../components/chat/MessageChat";
import MessagesList from "../components/chat/MessageList";
import DashboardLayout from "../components/dashboard/DashboardLayout";
import { Loading } from '../components/shared/Loading';
import SearchInput from "../components/shared/SearchInput";
import { ReactComponent as EmptyMsgIcon } from "../svgs/empty-smg.svg";
import { calculateAge } from "../utils/helpers";
import { ChatMessages } from "./types/chatThread";
import { CurrentUser } from "./types/currentUser";
import { Message, Messages as messages, RecentMessages } from "./types/messages";
import { SelectedChatDetails } from "./types/selectedChatDetails";

interface IProps {
  socket: Socket;
}

const Messages: React.FC<IProps> = ({ socket }) => {
  const navigate = useNavigate();
  const [messagesList, setMessagesList] = useState<Message[]>([]);
  // Fix the initial state type to match how it's used
  const [recentMessages, setRecentMessages] = useState<RecentMessages>({});
  const [selectedChatDetails, setSelectedChatDetails] = useState<null | SelectedChatDetails>(
    null
  );
  const pageTop = useRef<null | HTMLDivElement>(null);
  const [touchStart, setTouchStart] = useState<null | any>(null);
  const [touchEnd, setTouchEnd] = useState<null | any>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const minSwipeDistance = 50;
  const currentUser: CurrentUser = getCurrentUser();

  const senderId: string = currentUser?.userId;

  const receiverId = selectedChatDetails && selectedChatDetails?.userId || "";

  // const onBackSwipe = () => {
  //   setSelectedChatDetails(null);
  //   scrollToTop();
  // };

  const scrollToTop = () => {
    pageTop.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };

  const onTouchStart = (e: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (touchStart !== null && touchEnd !== null) {
      //eslint-disable-next-line
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance < -minSwipeDistance;
      if (isLeftSwipe) {
        setSelectedChatDetails(null);
        scrollToTop();
      }
    }
    return;
  };

  const { getPatientProviderList, isError, isLoading, error } = useGetPatientProviderList()

  useEffect(() => {
    if (isError && axios.isAxiosError(error)) {
      toast.error(error.response?.data?.error, { toastId: "customId" });
    }
  }, [isError, error]);

  useEffect(() => {
    scrollToTop();
  }, [selectedChatDetails]);

  const patientProviders = getPatientProviderList?.map((patient) => {
    return {
      ...patient,
      age: patient.dateOfBirth ? calculateAge(patient.dateOfBirth) : 0,
      name: `${patient.firstName} ${patient.lastName}`,
      email: patient.email,
      userId: patient.userId,
      chiefComplaint: null,
      avatarUrl: patient.avatarUrl
    }

  })

  const filteredProviders = useMemo(() => {
    return patientProviders?.filter((provider) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        provider.name.toLowerCase().includes(searchLower) ||
        provider.email.toLowerCase().includes(searchLower)
      );
    }) ?? [];
  }, [patientProviders, searchTerm]);

  const senderIds = useMemo(() => {
    return getPatientProviderList ? getPatientProviderList?.map((l) => l?.userId) : [];
  }, [getPatientProviderList]);

  const userRooms = senderIds.map((providerId) => `${senderId}_${providerId}`);

  useEffect(() => {
    if (userRooms.length) {
      socket.emit("PATIENT_USERS", { userRooms });
    }
  }, [socket, userRooms]);

  useEffect(() => {
    socket.on("SEND_MESSAGE_SUCCESS", (message: any) => {
      const newMessage = Object.values(message)[0] as Message;
      const unreadMessagesCount = Object.values<number>(message)[1];

      const id = `${newMessage.senderId}_${newMessage.receiverId}`;

      setMessagesList((prev) => {
        const messageExists = prev.some((msg) => msg.messageId === newMessage.messageId || msg.messageId === message.tempId);
        return messageExists
          ? prev.map((msg) => (msg.messageId === newMessage.messageId || msg.messageId === message.tempId ? { ...newMessage, hasSent: true } : msg))
          : [...prev, newMessage];
      });

      setRecentMessages((prev) => ({
        ...prev,
        [id]: { message: newMessage, unreadMessagesCount }
      }));
    });

    return () => {
      socket.off("SEND_MESSAGE_SUCCESS");
    };
  }, [socket, senderId, receiverId]);

  useEffect(() => {
    if (senderId && receiverId) {
      socket?.emit("GET_SINGLE_CHAT_MESSAGES", {
        senderId,
        receiverId
      });
    }
  }, [socket, receiverId, senderId]);

  useEffect(() => {
    socket?.emit("GET_SINGLE_CHAT_MESSAGES_ALL_SENDERS", {
      senderIds,
      receiverId: currentUser.userId,
      isReceiverPatient: false
    });
  }, [currentUser.userId, socket, senderIds]);

  useEffect(() => {
    socket?.on("GET_SINGLE_CHAT_MESSAGES_ALL_SENDERS_SUCCESS", (m: ChatMessages) => {
      setRecentMessages(prev => ({
        ...prev,
        ...m.messages
      }));
    });
  }, [receiverId, senderId, socket]);

  useEffect(() => {
    socket?.on("GET_SINGLE_CHAT_MESSAGES_SUCCESS", (messages: messages) => {
      if (messages?.messages) {
        const formattedMessages = messages.messages.map((msg) => ({
          ...msg,
          message: msg.message, //|| msg?.content, 
          created_at: msg.created_at || new Date().toISOString()
        }));
        setMessagesList(formattedMessages);

        // Make sure this matches the structure used in SEND_MESSAGE_SUCCESS
        setRecentMessages(prev => ({
          ...prev,
          [`${senderId}_${receiverId}`]: {
            message: formattedMessages[formattedMessages.length - 1],
            unreadMessagesCount: 0
          }
        }));
      }
    });

    return () => {
      socket.off("GET_SINGLE_CHAT_MESSAGES_SUCCESS");
    };
  }, [socket, receiverId, senderId]);


  // Fix the loading condition to properly return the loading component
  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div ref={pageTop} />
      <DashboardLayout
        reff={pageTop}
        headerTitle="Messages"
        defaultLeftMargin
        noGrid
      >
        <div className="w-full h-full">
          <div className="justify-between items-start max-h-[calc(100vh-100px)] w-full mr-0 mt-0 pt-0 overflow-x-hidden">
            <div className="flex flex-col justify-start bg-none lg:bg-[#F5FAF5] fixed w-full lg:w-[330px] h-full p-2 ml-0 pl-0 lg:pt-[27px] overflow-y-scroll lg:overflow-x-hidden">
              {selectedChatDetails && (
                <div className="hidden lg:block mx-auto mb-4">
                  <SearchInput
                    value={searchTerm}
                    placeholder="Search through your messages"
                    additionalClassname="w-full lg:w-[300px] h-[40px]"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                </div>
              )}
              {!selectedChatDetails && (
                <div className="mx-auto mb-4">
                  <SearchInput
                    value={searchTerm}
                    placeholder="Search through your messages"
                    additionalClassname="w-[300px] h-[40px]"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                </div>
              )}

              {/* SHOWN WHEN NO CHAT IS NOT SELECTED */}
              {/* {!selectedChatDetails && (
                <MessagesList
                  socket={socket}
                  expandMessage={selectedMessage => {
                    navigate(`/patient/messages/${selectedMessage.userId}`)
                    setSelectedChatDetails((prev) => {
                      if (prev?.userId === selectedMessage.userId) {
                        return null
                      }
                      return selectedMessage
                    });
                  }}
                  list={filteredProviders}
                  recentMessages={recentMessages}
                  selectedChat={selectedChatDetails}
                  setRecentMessages={setRecentMessages}
                />
              )}

              {/*  HIDDEN ON MOBILE */}
              {/* {selectedChatDetails && (
                <div className="hidden lg:block">
                  <MessagesList
                    socket={socket}
                    expandMessage={selectedMessage => {
                      navigate(`/patient/messages/${senderId}`)
                      setSelectedChatDetails(selectedMessage);
                    }}
                    list={filteredProviders}
                    recentMessages={recentMessages}
                    selectedChat={selectedChatDetails}
                    setRecentMessages={setRecentMessages}
                  />
                </div>
              )}  */}

              <MessagesList
                socket={socket}
                expandMessage={selectedMessage => {
                  setSelectedChatDetails((prev) => {
                    if (prev?.userId === selectedMessage.userId) {
                      return null
                    }
                    navigate(`/patient/messages/${selectedMessage.userId}`)
                    return selectedMessage
                  });
                }}
                list={filteredProviders}
                recentMessages={recentMessages}
                selectedChat={selectedChatDetails}
                setRecentMessages={setRecentMessages}
              />
            </div>
            {/* message box */}
            <div>
              <div
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                className="relative w-full min-h-[calc(100vh-100px)] max-w-full lg:max-w-[calc(100%-330px)] lg:min-w-[calc(100%-330px)] lg:ml-auto mr-0 flex flex-col justify-end bg-white mb-3"
              >
                {selectedChatDetails ? (
                  <MessageChat
                    socket={socket}
                    selectedUserDetails={selectedChatDetails}
                    messagesList={messagesList}
                    userType="patient"
                    setMessagesList={setMessagesList}
                  />
                ) : (
                    <div className="h-full w-full m-auto flex flex-col justify-center items-center">
                    <EmptyMsgIcon />
                    <div className="font-semibold text-[#34623f] mt-4 text-center">
                      No chats selected
                    </div>
                    <div className="text-[#103C1B] pt-2 w-[322px] text-center">
                      Select a chat on the left to see the details here
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Messages;
