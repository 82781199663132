import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DecodedJwt, DecodedPatientData, getDecodedJwt, setToken } from "../../../../utils/helpers";
import http from "../../../../utils/http";


type Payload = {
  email: string;
  password: string;
}

type Props = {
  setLoginError: Dispatch<SetStateAction<string>>
}

export const usePostPatientLogin = ({ setLoginError }: Props) => {
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const referrer = new URLSearchParams(search).get("referrer");

  const from = state?.from?.pathname || referrer || `/patient/dashboard`

  const { mutate, data, variables, reset, ...rest } = useMutation({
    mutationKey: ['login patient'],
    mutationFn: async ({ email, password }: Payload) => {
      const response = await http.post('patients/login', {
        email,
        password
      })
      return response.data;
    },

    onSuccess: ({ token }) => {
      setToken(token);
      const response = getDecodedJwt<DecodedJwt<DecodedPatientData>>(token);
      if (!response?.data?.data?.hasFilledPersonalDetails) {
        navigate(
          `/registration/personal${referrer === "appointment" ? "?referrer=appointment" : ""
          }`,
          { replace: true }
        );
      }
      navigate(from, { replace: true })
    },

    onError: (error) => {
      if (axios.isAxiosError(error)) {
        setLoginError(
          error?.response?.data?.error || error.response?.statusText,
        );
      } else {
        setLoginError(
          error?.message
          || "Error tryng to login",
        );
      }
    },
  });

  return {
    patientsLogin: mutate,
    variables,
    ...rest,
  };
}
