import { format } from "date-fns";
import { ChangeEvent, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useAddPatientForProvider } from '../../../api/hooks/patient/usePostSingleProviderPatient';
import TextField from "../../../components/form/TextField";
import BtnButton from "../../../components/shared/BtnButton";
import CheckWithLabelToggle from '../../../components/shared/CheckWithLabelToggle';
import ModalPortal from "../../../components/shared/ModalPortal";
import { CancelIcon } from "../../../svgs/CancelIcon";
import { Patient } from "../../../types/patientType";
import { removeEmptyKeys } from "../../../utils/removeEmptyFromObjectOrArray";

const AddSinglePatient = ({
  setShowAddSinglePatient
}: {
  setShowAddSinglePatient: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [newPatientDetails, setNewPatientDetails] = useState<Partial<Patient>>({
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: String(new Date()),
    phoneNumber: "",
    insuranceName: "",
    insuranceNumber: ""
  });
  const [sendPatientEmail, setSendPatientEmail] = useState<boolean>(false);

  const { addPatientForProvider, isPending } = useAddPatientForProvider()

  const handleNewPatientChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPatientDetails({
      ...newPatientDetails,
      [e.target.name]: e.target.value
    });
  };

  const handleSave = () => {
    const nonEmptyValues = removeEmptyKeys({
      ...newPatientDetails,
    });
    addPatientForProvider({
      data: [nonEmptyValues],
      emailPatients: sendPatientEmail
    });
    setShowAddSinglePatient(false);
  };

  return (
    <ModalPortal>
      <section
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center"
        }}
        className="min-w-screen h-full md:h-screen fixed md:justify-center md:items-center items-start justify-start top-0 bg-opacity-20 backdrop-blur-[5px]"
        onClick={() => setShowAddSinglePatient(false)}
        data-testid='single-patient-container'
      >
        <div
          className=" min-w-min max-w-[60%] bg-white px-20 py-10 flex flex-col gap-5 rounded-md h-3/4 md:max-h-full overflow-x-auto md:overflow-x-hidden "
          onClick={e => e.stopPropagation()}
        >
          <section className="flex justify-end" data-testid='close-button-container'>
            <CancelIcon
              width={32}
              height={32}
              className="cursor-pointer"
              onClick={() => setShowAddSinglePatient(false)}
            />
          </section>
          <p className="text-sm md:text-[18px] mb-5 h-1/2 text-pretty" data-testid='description'>
            Enter your patient's details below to start seeing them on TempleHS.
            To enjoy the full benefits of your account, you can send them an
            email to complete their account.
          </p>
          <section className="flex flex-col gap-5">
            <TextField
              placeholder="First Name"
              value={newPatientDetails.firstName || ""}
              name="firstName"
              onChange={handleNewPatientChange}
            />
            <TextField
              placeholder="Last Name"
              value={newPatientDetails.lastName || ""}
              name="lastName"
              onChange={handleNewPatientChange}
            />
            <TextField
              placeholder="Email"
              value={newPatientDetails.email || ""}
              name="email"
              onChange={handleNewPatientChange}
            />
            <ReactDatePicker
              selected={new Date(newPatientDetails?.dateOfBirth || "")}
              name="dateOfBirth"
              dateFormat="MM/dd/yyyy"
              onChange={value => {
                if (value) {
                  setNewPatientDetails({
                    ...newPatientDetails,
                    dateOfBirth: format(new Date(value), "MM/dd/yyyy")
                  });
                }
              }}
              placeholderText="Date of Birth"
              className="w-full border border-[#949589] text-[#103C1B] rounded py-3 px-4"
              shouldCloseOnSelect={true}
              showYearDropdown={true}
              scrollableYearDropdown={true}
              dropdownMode="select"
              data-testid='date-of-birth'
            />
            <TextField
              placeholder="Phone Number"
              value={newPatientDetails.phoneNumber ?? ""}
              name="phoneNumber"
              onChange={handleNewPatientChange}
            />
            <TextField
              placeholder="Insurance Name"
              value={newPatientDetails.insuranceName || ""}
              name="insuranceName"
              onChange={handleNewPatientChange}
            />
            <TextField
              placeholder="Insurance Number"
              value={newPatientDetails.insuranceNumber ?? ""}
              name="insuranceNumber"
              onChange={handleNewPatientChange}
            />
          </section>
          <section className="flex justify-between items-center w-full mt-5" data-testid='add-patient-file-btn-container'>
            <div className="flex items-center mt-5 text-xs md:text-pretty md:text-base">
              <CheckWithLabelToggle
                label="Also email this patient ?"
                isSelected={sendPatientEmail}
                toggleSelection={() => setSendPatientEmail(prev => !prev)}
              />
            </div>
            <div className="flex items-center">
              <BtnButton
                label="Save"
                size="small"
                variant="primary"
                loading={isPending}
                disabled={isPending}
                onClick={handleSave}
              />
            </div>
          </section>
        </div>
      </section>
    </ModalPortal>
  );
};

export default AddSinglePatient;
