import { differenceInYears, format } from "date-fns";
import { useCallback, useState } from "react";
import { CSVLink } from "react-csv";

import { useGetAllPatientsList } from "../../api/hooks/admin/get_admin_patients_list";
import { useGetSingleAdminPatient } from "../../api/hooks/admin/get_single_admin_patient";
import ReusableTable from "../../components/ReusableTable";
import BtnButton from "../../components/shared/BtnButton";
import { Loading } from "../../components/shared/Loading";
import { ArrowBackIcon } from "../../svgs/ArrowBackIcon";
import AdminDashboardLayout from "../components/AdminDashboardLayout";
import AdminDataInfoCard from "../components/AdminDataInfoCard";
import { useAdminPatientAppointmentTableCols } from "../tables/hooks/admin_appointment_cols";
import { useAdminPatientTableCols } from "../tables/hooks/admin_patient_cols";

export interface ProviderPatientsType {
  firstName: string;
  lastName: string;
  email: string;
  diagnosis: string;
  join_date: Date;
  created_at: Date;
  lastAppointmentDate: Date;
  id: number;
  userId: string;
  isActivated: boolean;
}

export interface PatientAppointmentsType {
  doctor: string;
  charges: string;
  date: Date;
  id: number;
}

const AdminPatients = () => {
  const [isPatientDetailsView, setIsPatientDetailsView] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");

  const { patientData, isLoading } = useGetAllPatientsList()
  const { singlePatientData, isLoading: isSingleLoading, refetch } = useGetSingleAdminPatient({ userId })
  const { APPOINTMENTS_TABLE_COLUMNS } = useAdminPatientAppointmentTableCols()

  const reFetch = useCallback(() => {
    return refetch()
  }, [refetch])

  const { PATIENTS_TABLE_COLUMNS } = useAdminPatientTableCols({ refetch: reFetch, setUserId, setIsPatientDetailsView })


  return (
    <AdminDashboardLayout headerTitle="Patients">

      {isPatientDetailsView ? (
        <div className="w-full h-full px-4 mt-8">
          <div className="w-full">
            <div
              onClick={() => setIsPatientDetailsView(false)}
              className="cursor-pointer pl-4 flex justify-start items-center"
            >
              <ArrowBackIcon /> <div className="ml-3">Go back to Patients</div>
            </div>
          </div>
          <div className="my-6 mx-4">
            <div className="flex items-center">
              <div className="w-[827px] h-[334px] bg-white rounded-[8px] shadow-[-2px_4px_25px_#EEEEE9] p-4 flex items-center">
                <div className="w-[321px] h-full border-r-2 border-[#EEEEE9]">
                  <div className="w-full flex justify-center items-center align-middle pt-6">
                    <div className="h-[96px] w-[96px]">
                      <img
                        className="w-full h-full rounded-full mr-8"
                        src="/assets/images/avatar.png"
                        alt="Profile img"
                      />
                    </div>
                  </div>
                  <div className="text-center text-[20px] font-[600] text-[#324705] my-4">
                    {`${singlePatientData?.patientDetails?.firstName} ${singlePatientData?.patientDetails?.lastName}`}
                  </div>
                  <div className="text-center text-[16px] font-[400] text-[#324705] my-4">
                    {singlePatientData?.patientDetails?.email}
                  </div>

                  <div className="w-full h-[60px] flex items-center mt-4 justify-center">
                    <div className=" pr-8 border-r-2 border-[#EEEEE9]">
                      <p className="text-center text-[20px] font-[600] text-[#324705]">
                        {singlePatientData?.pastVisits ?? 0}
                      </p>
                      <p className="text-[15px] font-[500] text-[#87928A]">
                        Past Visit
                      </p>
                    </div>
                    <div className="pl-6">
                      <p className="text-center text-[20px] font-[600] text-[#324705]">
                        {singlePatientData?.upcomingVisits ?? 0}
                      </p>
                      <p className="text-[15px] font-[500] text-[#87928A]">
                        Upcoming Visit
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-[505px] h-full flex flex-col my-4 px-6">
                  <div className="flex items-center justify-between mb-4">
                    <div className="my-4">
                      <p className="text-[15px] font-[500] text-[#87928A]">
                        Gender
                      </p>
                      <p className="text-[15px] font-[500] text-[#324705]">
                        {singlePatientData?.patientDetails?.gender}
                      </p>
                    </div>
                    <div className="my-4">
                      <p className="text-[15px] font-[500] text-[#87928A]">
                        Age
                      </p>
                      <p className="text-[15px] font-[500] text-[#324705]">
                        {differenceInYears(new Date(), new Date(singlePatientData?.patientDetails?.dateOfBirth))}
                      </p>
                    </div>
                    <div className="my-4">
                      <p className="text-[15px] font-[500] text-[#87928A]">
                        Phone Number
                      </p>
                      <p className="text-[15px] font-[500] text-[#324705]">
                        {singlePatientData?.patientDetails?.phoneNumber}
                      </p>
                    </div>
                  </div>
                  <div className="my-4">
                    <p className="text-[15px] font-[500] text-[#87928A]">
                      Address
                    </p>
                    <p className="text-[15px] font-[500] text-[#324705]">
                      {singlePatientData?.patientDetails?.address}
                    </p>
                  </div>
                  <div className="my-4">
                    <p className="text-[15px] font-[500] text-[#87928A]">
                      Join Date
                    </p>
                    <p className="text-[15px] font-[500] text-[#324705]">
                      {singlePatientData?.patientDetails?.created_at
                        ? format(
                          new Date(singlePatientData?.patientDetails?.created_at),
                          "MM/dd/yyyy"
                        )
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-4" />
              <div className="w-[502px] h-[334px] bg-white rounded-[8px] shadow-[-2px_4px_25px_#EEEEE9] p-4">
                <p className="text-[20px] font-[500] text-[#324705] mt-3">
                  Copay/Insurance
                </p>
                <div className="my-4">
                  <p className="text-[15px] font-[500] text-[#87928A]">
                    Provider
                  </p>
                  <p className="text-[15px] font-[500] text-[#324705]">
                    Singular Payments
                  </p>
                </div>
                <div className="my-4">
                  <p className="text-[15px] font-[500] text-[#87928A]">
                    Status
                  </p>
                  <p className="text-[15px] font-[500] text-[#324705]">
                    {singlePatientData?.patientDetails?.isActivated ? "Active" : "Inactive"}
                  </p>
                </div>
                <div className="my-4">
                  <p className="text-[15px] font-[500] text-[#87928A]">
                    Total Charges
                  </p>
                  <p className="text-[15px] font-[500] text-[#324705]">
                    ${singlePatientData?.totalCharges ?? 0}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-6">
            {
              isSingleLoading ? (<Loading />) : (
                <ReusableTable
                  tableColumns={APPOINTMENTS_TABLE_COLUMNS}
                  tableData={singlePatientData?.patientCompletedAppointments ?? []}
                  onClick={data => { }}
                  pageSizeCount={10}
                  showTitleHideSearch={true}
                  title="Past Appointments"
                />
              )
            }
          </div>
        </div>
      ) : (
        <div className="w-full h-full px-4 mt-8">
          <div className="flex items-center w-full">
            {" "}
            <div className="w-[374px]">
                {isLoading ? (<Loading />) : <AdminDataInfoCard
                title="Total no. of  Patients"
                  dataCount={patientData?.patientsCount ?? 0}
                blue
                />}

            </div>
          </div>
          <div>
            <div className="w-full flex items-center justify-between mt-8 mb-4 px-4">
              <div className="text-[24px] font-[600] text-[#324705]">
                All Patients
              </div>
              <div>
                  {isLoading ? (<Loading />) : (<CSVLink
                    data={patientData?.allPatients ?? ""}
                  filename="patients-list.csv"
                  uFEFF={true}
                >
                  <BtnButton variant="secondary" label="Export" size="small" />
                  </CSVLink>)}

              </div>
            </div>
            <div className="w-full mt-8">
              <ReusableTable
                tableColumns={PATIENTS_TABLE_COLUMNS}
                  tableData={patientData?.allPatients ?? []}
                  onClick={data => { }}
                pageSizeCount={10}
                searchFieldPlaceholder="Search Patients"
              />
            </div>
          </div>
        </div>
      )}
    </AdminDashboardLayout>
  );
};

export default AdminPatients;
