import { CancelIcon } from '../svgs/CancelIcon';
import ModalPortal from './shared/ModalPortal';

interface Props {
  isVisible: boolean;
  closeModal: () => void;
}

export function ExploreAiModal({
  isVisible,
  closeModal,

}: Props) {

  return isVisible ? (
    <ModalPortal>
      <div
        style={{
          backgroundColor: "rgba(38, 38, 38, 0.75)",
          zIndex: 100,
          display: "flex",
          justifyContent: "center",
          height: "100vh",
        }}
        className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
        data-testid="modal"
      >
        <div className="flex justify-end  w-[90%] lg:w-[462px] mx-auto py-2">
          <div
            onClick={() => {
              closeModal();
            }}
            className="p-2 bg-white rounded-full cursor-pointer"
            data-testid="close-modal"
          >
            <CancelIcon className="h-full w-full" />
          </div>
        </div>
        <div className="bg-white w-[90%] lg:w-[462px] px-6 pt-6 pb-4 lg:p-6 shadow-lg rounded-[16px] border-box">
          <p className="text-[#2E3011] text-[24px] font-[600] mt-4" data-testid="modal-title">
            Service Overview
          </p>
          <div className='text-[#2E3011] text-base font-normal' data-testid="modal-content">
            We use AI to automatically transcribe and summarize patient-provider conversations, generating detailed, accurate clinical documentation in real-time.
          </div>
          <p className="text-[#2E3011] text-[24px] font-[600] mt-4" data-testid="modal-method-title">
            Payment Method
          </p>
          <div className='text-[#2E3011] text-base font-normal' data-testid="modal-method-desc">
            This service is available to all providers regardless of your subscription plan as it is a pay-as-you-use service.
          </div>
          <div >
            <p className="text-[#2E3011] text-[24px] font-[600] mt-4" data-testid="modal-title-pricing">
              Pricing
            </p>
            <p className='text-[#2E3011] text-base font-normal' data-testid="modal-content-pricing">
              10c per minute
            </p>
          </div>
          <div >
            <p className="text-[#2E3011] text-[24px] font-[600] mt-4" data-testid="modal-title-example">
              Pricing Example
            </p>
            <p className='text-[#2E3011] text-base font-normal' data-testid="modal-pricing-desc">
              Let's assume that you want to use TempleHS AI Documentation to generate SOAP notes for a call that lasts 30 minutes. Since this service is priced at 10c per minute, your monthly charges will be as follows: Total cost = 30 minutes * $0.10 per minute Total cost = $3.00 You will be charged $3 for this service.
            </p>
          </div>
        </div>
      </div>
    </ModalPortal>
  ) : null;
}
