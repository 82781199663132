import Alert from "@mui/material/Alert";
import axios from "axios";
import { differenceInHours, format, formatRelative } from "date-fns";
import { useFormik } from "formik";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { toast } from "react-toastify";
import { Socket } from "socket.io-client";
import * as Yup from "yup";
import { getCurrentUser } from "../../api/auth";
import { useGetUserSource } from "../../api/hooks/common/useGetUserSource";
import { Message } from "../../patient/types/messages";
import { SelectedChatDetails } from "../../patient/types/selectedChatDetails";
import { InfoIcon } from "../../svgs/InfoIcon";
import { UserType } from "../../types/common";
import { CurrentUser } from "../../types/table";
import Spacer from "../Spacer";
import MessageInput from "./MessageInputField";
import ProviderProfileDrawer from "./ProviderProfileDrawer";

interface MessageChatProps {
  // firstName: string;
  // imageUrl: string;
  // messages?: any[] | null;
  selectedUserDetails: SelectedChatDetails | null;
  // addNewMessage?: (...arg: any) => any[];
  // newMessages?: any[];
  // messageOwnerIndex: number;
  // oidx: number;
  // onBackClick: (arg?: any) => void;
  messagesList: any;
  socket: Socket;
  userType: UserType;
  // viewDetails: (arg: any) => void;
  setMessagesList: React.Dispatch<React.SetStateAction<Message[]>>
}

const MessageChat = ({
  // messages,
  // imageUrl = "",
  selectedUserDetails,
  // addNewMessage,
  // newMessages,
  // messageOwnerIndex,
  // oidx,
  // firstName,
  // onBackClick,
  setMessagesList,
  messagesList,
  socket,
  userType
}: MessageChatProps) => {
  const [currentMessageText, setCurrentMessageText] = useState("");
  const [isProfileDrawerVisible, setIsProfileDrawerVisible] = useState(false);
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [base64ImageSrc, setBase64ImageSrc] = useState<string | null>(null);
  const [chatWindowError, setChatWindowError] = useState<any>({});


  const chatWindowPage = useRef<null | HTMLDivElement>(null);
  const chatSize = useRef<number>(0);

  const currentUser: CurrentUser = getCurrentUser();

  const scrollToBottom = () => {
    if (chatWindowPage.current) {
      chatWindowPage.current.scrollTop =
        chatWindowPage.current.scrollHeight + 200;
      // chatWindowPage.current?.scrollIntoView({ behavior: "smooth" });

    }
  };

  useEffect(() => {
    if (chatSize.current !== messagesList.length) {
      chatSize.current = messagesList.length;
      setTimeout(() => {
        scrollToBottom();
      }, 100); // Small delay to ensure DOM updates
    }
  }, [messagesList]);

  const senderId = useMemo(() => {
    return currentUser?.userId;
  }, [currentUser]);

  const receiverId = useMemo(() => {
    if (selectedUserDetails?.userId) {
      return selectedUserDetails?.userId
    }
    return "";
  }, [selectedUserDetails]);

  const { data, isLoading, isError, error } = useGetUserSource(receiverId)

  if (isError && axios.isAxiosError(error)) {

    toast.error(error?.response?.data?.error, { toastId: "customId" });
  }

  const formik = useFormik({
    initialValues: {
      message: ""
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Message should not be empty")
    }),
    onSubmit: async () => { }
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCurrentMessageText(e.target.value);
  };

  const createImageSource = (file: Blob) => {
    try {
      setIsUploadingImage(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = async () => {
        if (reader.result) {
          setBase64ImageSrc(reader.result.toString());
          // setBase64ImageSrc(null);

          setIsUploadingImage(false);
        }
      };
    } catch (error) {
      setIsUploadingImage(false);
    }
  };

  const openFileLibrary = () => {
    hiddenFileInputRef.current?.click();
  };

  const selectFileFromLibrary = (event: any) => {
    const target = event.currentTarget as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    createImageSource(file);
  };

  useEffect(() => {
    scrollToBottom();
    // checkIfPatientHasAccount();
  }, [messagesList.length]);

  const ChatMessages = useMemo(() => {
    return messagesList?.map((message: any) => {
      return (
        <React.Fragment key={message?.messageId}>
          <div className="w-full pr-2">
            {message?.senderId === receiverId ? (
              <div className="flex flex-col justify-start">
                <Spacer height={14} />
                <div className="flex w-full justify-start relative items-baseline">
                  <div className="pl-0 flex justify-start absolute bottom-0 left-0 right-10">
                    <img
                      onClick={() => {
                        setIsProfileDrawerVisible(true);
                      }}
                      className="w-[30px] h-[30px] rounded-full cursor-pointer"
                      src={
                        selectedUserDetails?.avatarUrl ||
                        "/assets/images/avatar.png"
                      }
                      alt="user photo"
                    />
                  </div>
                  <Spacer width={25} />

                  <div className=" min-h-fit w-[305px] ml-4 py-4 px-6 bg-[#e1d0c4] rounded rounded-tl-[25px] rounded-tr-[25px] rounded-br-[25px]">
                    <p className="font-normal text-black text-[12px]">
                      {message?.message}
                    </p>
                  </div>
                </div>
                <p className="text-[12px] font-medium pl-10 text-[#87928A]">
                  {differenceInHours(
                    new Date(),
                    new Date(message?.created_at)
                  ) < 24
                    ? format(new Date(message?.created_at), "h:mmb")
                    : formatRelative(new Date(message?.created_at), new Date())}
                </p>
              </div>
            ) : (
              <div className="flex w-full justify-end">
                <div className="w-full min-h-auto mr-0 flex flex-col">
                  <Spacer height={14} />
                  <div className="flex w-full relative items-end justify-end">
                    <div className="min-h-auto w-[305px] ml-4 py-4 px-6 border border-[#EBEBEB] bg-[#DEEDDE] rounded rounded-tl-[25px] rounded-tr-[25px] rounded-bl-[25px]">
                      <p className="font-[400] text-[#324705] text-[12px]">
                        {message?.message}
                      </p>
                    </div>
                    {chatWindowError[message.messageId] && !message.hasSent && (
                      <div className="flex justify-end items-center pl-3 h-full">
                        <InfoIcon color="red" />
                      </div>
                    )}
                  </div>

                  {chatWindowError[message.messageId] && !message.hasSent ? (
                    <>
                      <Spacer height={5} />
                      <p className="text-[12px] font-[500] flex justify-end w-full text-[#FC3333]">
                        Message not sent
                      </p>
                    </>
                  ) : (
                    <p className="text-[12px] font-[500] flex justify-end w-full text-[#87928A]">
                      {differenceInHours(
                        new Date(),
                        new Date(message?.created_at)
                      ) < 24
                        ? format(new Date(message?.created_at), "h:mmb")
                        : formatRelative(
                          new Date(message?.created_at),
                          new Date()
                        )}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    });
  }, [messagesList, chatWindowError, receiverId, selectedUserDetails]);

  const handleSendMessage = () => {
    if (currentMessageText.trim()) {
      const messageId = Date.now().toString();
      const newMessage: Message = {
        id: Number(messageId),
        messageId: messageId,
        message: currentMessageText,
        senderId,
        receiverId,
        created_at: new Date().toISOString(),
        hasSent: false,
        isImage: false,
        isAudio: false,
        isVideo: false,
        mediaFileKey: null,
        hasBeenRead: false,
        updated_at: new Date().toISOString()
      };

      // Add message to the list immediately for UI feedback
      setMessagesList(prev => [...prev, newMessage]);

      // Send message to server
      socket.emit('SEND_MESSAGE', {
        senderId,
        receiverId,
        message: currentMessageText,
        messageId: messageId, // Include messageId to track this specific message
        tempId: messageId // Add tempId to match with response
      });

      setCurrentMessageText('');

      // Scroll to bottom after sending message
      setTimeout(scrollToBottom, 100);
    }
  };

  // Add a socket listener for message status updates
  useEffect(() => {
    // Listen for message status updates
    socket.on("SEND_MESSAGE_SUCCESS", (message: any) => {
      // Update the message status in the list
      setMessagesList(prev =>
        prev.map(msg =>
          msg.messageId === message.tempId || msg.messageId === message.messageId
            ? { ...msg, hasSent: true, messageId: message.messageId || msg.messageId }
            : msg
        )
      );
    });

    return () => {
      socket.off("SEND_MESSAGE_SUCCESS");
    };
  }, [socket, setMessagesList]);

  // if (selectedUserDetails === null) {
  //   return <div>No Messages</div>;
  // }

  return messagesList?.length && messagesList.length > 0 ? (
    <div className="flex flex-col h-screen w-full relative border border-[#d1d2d4] bg-[#fff] gap-4">
      {!isLoading && data?.userSource === "provider" && (
        <div className="absolute top-0 left-0  z-10 py-4 w-1/2">
          <Alert severity="info"
            sx={{
              width: 'auto',
              fontSize: '12px',
              margin: '5px'
            }}
          >
            This patient does not have an account with TempleHS, but the message
            will be delivered once the patient opens an account
          </Alert>
        </div>
      )}

      <div
        ref={chatWindowPage}
        className="flex-1 overflow-y-auto p-4 pb-20"
      >
        <div className="mb-2 p-3 h-full rounded-lg">
          {ChatMessages}
        </div>
      </div>
      <div className=" h-[60px] w-full relative">

        <div className="absolute bottom-0 left-0 right-0 bg-white">
          <MessageInput
            socket={socket}
            formik={formik}
            currentUserId={senderId}
            receiverId={receiverId}
            value={currentMessageText}
            placeholder="Type your message here.."
            name="message"
            onSend={handleSendMessage}
            onAttach={openFileLibrary}
            onChange={handleChange}
            userType={userType}
          />
        </div>
      </div>

      <input
        type="file"
        className="hidden"
        ref={hiddenFileInputRef}
        onChange={selectFileFromLibrary}
        accept="image/x-png,image/jpeg,image/jpg"
      />

      <ProviderProfileDrawer
        open={isProfileDrawerVisible}
        onClose={() => setIsProfileDrawerVisible(false)}
        drawerTitle=""
        userDetails={selectedUserDetails}
      />
    </div>
  ) : (
      <div className="flex flex-col h-full w-full relative">
        <div className="absolute bottom-0 left-0 right-0 bg-white">
        <MessageInput
          socket={socket}
          formik={formik}
          currentUserId={senderId}
          receiverId={receiverId}
          value={currentMessageText}
          placeholder="Type your message here.."
          name="message"
            onSend={handleSendMessage}
          onAttach={openFileLibrary}
          onChange={handleChange}
          userType={userType}
        />
      </div>
      </div>
  );
};

export default MessageChat;
