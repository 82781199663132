import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import { getCurrentUser } from "../../api/auth";
import { UserType } from "../../types/common";

interface IProps {
  imageUrl?: string;
  providerName?: string;
  lastMessage?: string;
  lastMessageDate?: string;
  newMessageCount?: number;
  onClick?: (arg: any) => void;
  isSelected?: boolean;
  receiverDetails: any;
  socket: Socket;
  recentMessages: any;
  setRecentMessages: any;
  expandMessage?: any;
  selectedChat?: any;
}

const MessagesListItem: React.FC<IProps> = ({
  imageUrl,
  providerName,
  onClick = () => {},
  isSelected,
  receiverDetails,
  socket,
  recentMessages,
  setRecentMessages,
  expandMessage,
  selectedChat,
}) => {
  const currentUser = getCurrentUser() || { userType: "patient", userId: "" };
  const senderId = currentUser?.userId;
  const receiverId = receiverDetails?.userId;
  const [openUserId, setOpenUserId] = useState<string>("");
  const [lastMessageDetails, setLastMessageDetails] = useState<any>();
  const [count, setCount] = useState<number>(0);
  const isMessageOpen = selectedChat?.userId === receiverId;

  // Safely get userType with a default value
  const userType: UserType = currentUser?.userType || "patient";

  useEffect(() => {
    socket.on("SEND_MESSAGE_SUCCESS", message => {

      if (openUserId === receiverId) {
        if (userType === "patient") {
          setRecentMessages((prevMessages: { [x: string]: any }) => ({
            ...prevMessages,
            [`${receiverId}_${senderId}`]: {
              ...prevMessages[`${receiverId}_${senderId}`],
              unreadMessagesCount: 0
            }
          }));
        } else if (userType === "provider") {

          setRecentMessages((prevMessages: { [x: string]: any }) => ({
            ...prevMessages,
            [`${senderId}_${receiverId}`]: {
              ...prevMessages[`${senderId}_${receiverId}`],
              unreadMessagesCount: 0
            }
          }));
        }
        socket?.emit("MARK_ALL_MESSAGES_AS_READ", {
          receiverId: senderId,
          senderId: receiverId
        });
      }
    });
  }, [
    userType,
    openUserId,
    receiverId,
    senderId,
    setRecentMessages,
    socket
  ]);

  useEffect(() => {
    const messageDetails =
      userType === "patient"
        ? recentMessages[`${receiverId}_${senderId}`]?.message
        : recentMessages[`${senderId}_${receiverId}`]?.message;

    const unreadMessagesCount =
      userType === "patient"
        ? recentMessages[`${receiverId}_${senderId}`]?.unreadMessagesCount
        : recentMessages[`${senderId}_${receiverId}`]?.unreadMessagesCount;

    setLastMessageDetails(messageDetails);
    setCount(unreadMessagesCount || 0);
  }, [userType, receiverId, recentMessages, senderId]);

  useEffect(() => {
    if (isMessageOpen && count > 0) {
      setCount(0);

      if (userType === "patient") {
        setRecentMessages((prevMessages: { [x: string]: any }) => ({
          ...prevMessages,
          [`${receiverId}_${senderId}`]: {
            ...prevMessages[`${receiverId}_${senderId}`],
            unreadMessagesCount: 0
          }
        }));
      } else if (userType === "provider") {
        setRecentMessages((prevMessages: { [x: string]: any }) => ({
          ...prevMessages,
          [`${senderId}_${receiverId}`]: {
            ...prevMessages[`${senderId}_${receiverId}`],
            unreadMessagesCount: 0
          }
        }));
      }
    }
  }, [isMessageOpen, count, userType, receiverId, senderId, setRecentMessages]);

  return (
    <div
      onClick={e => {
        expandMessage(receiverDetails);
        if (!isMessageOpen) {
          setOpenUserId(receiverId);
        }
        if (count > 0) {
          socket?.emit("MARK_ALL_MESSAGES_AS_READ", {
            receiverId: senderId,
            senderId: receiverId
          });
        }
        setCount(0);
        if (userType === "patient") {
          setRecentMessages((prevMessages: { [x: string]: any }) => ({
            ...prevMessages,
            [`${receiverId}_${senderId}`]: {
              ...prevMessages[`${receiverId}_${senderId}`],
              unreadMessagesCount: 0
            }
          }));
        } else if (userType === "provider") {
          setRecentMessages((prevMessages: { [x: string]: any }) => ({
            ...prevMessages,
            [`${senderId}_${receiverId}`]: {
              ...prevMessages[`${senderId}_${receiverId}`],
              unreadMessagesCount: 0
            }
          }));
        }
        onClick(e);
      }}
      className={clsx({
        "flex items-end justify-between border-b border-[#EEEEE9] bg-[#F5FAF5]w-full h-[92px] cursor-pointer text-black pb-[25px] px-4 hover:bg-[#DEEDDE]":
          true,
        "bg-[#DEEDDE]": isSelected === true
      })}
    >
      <div className="w-full h-[50px] relative flex justify-start items-center">
        <img
          className="h-10 w-10 rounded-full"
          src={imageUrl || "/assets/images/avatar.png"}
          alt="avatar"
        />
        <div className="flex flex-col justify-end ml-4">
          <p className="font-medium text-xs text-[#141921] mb-2">
            {providerName}
          </p>
          <p
            className={clsx({
              "text-xs text-[#434343]": true,
              "font-[600]":
                count > 0 &&
                !isMessageOpen &&
                lastMessageDetails.receiverId === senderId
            })}
          >
            {lastMessageDetails?.message}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-end w-[110px] items-center">
        {count > 0 &&
          !isMessageOpen &&
          lastMessageDetails.receiverId === senderId && (
            <div className="w-[auto] h-[30px] bg-[#7E954B] p-3 text-white text-s text-center flex items-center justify-center rounded-full">
              {count}
            </div>
          )}
      </div>
    </div>
  );
};

export default MessagesListItem;
