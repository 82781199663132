import axios from "axios";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Socket } from "socket.io-client";
import { getCurrentUser } from "../../api/auth";
import { useGeProviderPatient } from "../../api/hooks/provider/useGetProviderPatients";
import { providerPatient } from "../../api/types";
import MessageChat from "../../components/chat/MessageChat";
import MessagesList from "../../components/chat/MessageList";
import { Loading } from "../../components/shared/Loading";
import SearchInput from "../../components/shared/SearchInput";
import { ChatMessages } from "../../patient/types/chatThread";
import { Messages as messages, RecentMessages } from "../../patient/types/messages";
import { SelectedChatDetails } from "../../patient/types/selectedChatDetails";
import { ReactComponent as EmptyMsgIcon } from "../../svgs/empty-smg.svg";
import ProviderDashboardLayout from "../components/dashboard/ProviderDashboardLayout";
import { MessageLIst } from "../types/messageList";

interface Props {
  socket: Socket;
}

const Messages = ({ socket }: Props) => {
  const navigate = useNavigate();
  const pageTop = useRef<null | HTMLDivElement>(null);
  const [selectedChatDetails, setSelectedChatDetails] = useState<SelectedChatDetails | null>(
    null
  );
  const [messagesList, setMessagesList] = useState<MessageLIst[]>([]);
  const [patientsList, setPatientsList] = useState<providerPatient[]>([]);
  const [recentMessages, setRecentMessages] = useState<RecentMessages>({});
  const [touchStart, setTouchStart] = useState<null | any>(null);
  const [touchEnd, setTouchEnd] = useState<null | any>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const minSwipeDistance = 50;

  const currentUser = getCurrentUser();

  const senderId: string = currentUser?.userId;

  const receiverId = selectedChatDetails && selectedChatDetails?.userId || "";

  // const onBackSwipe = () => {
  //   setSelectedChatDetails(null);
  //   scrollToTop();
  // };

  const scrollToTop = () => {
    pageTop.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest"
    });
  };

  const onTouchStart = (e: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (touchStart !== null && touchEnd !== null) {
      //eslint-disable-next-line
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance < -minSwipeDistance;
      if (isLeftSwipe) {
        setSelectedChatDetails(null);
        scrollToTop();
      }
    }
    return;
  };

  const { providerPatientsData, isError, error, isLoading } = useGeProviderPatient();

  useEffect(() => {
    if (providerPatientsData) {
      setPatientsList(providerPatientsData?.providersPatients);
    }
  }, [providerPatientsData]);

  const filteredProviders = useMemo(() => {
    return patientsList?.filter(({ name, email }) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        name.toLowerCase().includes(searchLower) ||
        email?.toLowerCase().includes(searchLower)
      );
    }) ?? [];
  }, [patientsList, searchTerm]);

  const senderIds = useMemo(() => {
    return patientsList ? patientsList.map((l) => l?.userId) : [];
  }, [patientsList]);

  const userRooms = senderIds.map(patientId => `${[patientId]}_${senderId}`);

  useEffect(() => {
    if (userRooms.length) {
      socket.emit("PROVIDER_USERS", { userRooms });
    }
  }, [socket, userRooms]);

  useEffect(() => {
    socket?.on("SEND_MESSAGE_SUCCESS", (message: any) => {
      const newMessage: any = Object.values(message)[0];
      const unreadMessagesCount = Object.values(message)[1];
      console.log({ message, unreadMessagesCount });

      // const newMessagesList = [...messagesList, newMessage];

      const { receiverId: newReceiver, senderId: newSender } = newMessage;

      const id =
        newReceiver === receiverId
          ? `${newSender}_${newReceiver}`
          : `${newReceiver}_${newSender}`;

      setMessagesList(prev => {
        // Check if message already exists to prevent duplicates
        const messageExists = prev.some(msg =>
          msg.messageId === newMessage.messageId ||
          msg.messageId === message.tempId
        );
        console.log({ messageExists, newMessage, unreadMessagesCount });

        if (messageExists) {
          // Update existing message
          return prev.map(msg =>
            (msg.messageId === newMessage.messageId || msg.messageId === message.tempId)
              ? { ...newMessage, hasSent: true }
              : msg
          );
        } else {
          // Add new message
          return [...prev, newMessage];
        }
      });

      setRecentMessages(prev => ({
        ...prev,
        [id]: {
          message: newMessage,
          unreadMessagesCount: 0
        }
      }));
    });

    return () => {
      socket?.off("SEND_MESSAGE_SUCCESS");
    };
  }, [messagesList, receiverId, recentMessages, senderId, socket]);

  useEffect(() => {
    if (senderId && receiverId) {
      socket?.emit("GET_SINGLE_CHAT_MESSAGES", {
        senderId,
        receiverId
      });
    }
  }, [socket, receiverId, senderId]);

  useEffect(() => {
    socket?.emit("GET_SINGLE_CHAT_MESSAGES_ALL_SENDERS", {
      senderIds,
      receiverId: currentUser.userId,
      isReceiverPatient: true
    });
  }, [currentUser.userId, socket, senderIds]);

  useEffect(() => {
    socket?.on("GET_SINGLE_CHAT_MESSAGES_ALL_SENDERS_SUCCESS", (m: ChatMessages) => {
      setRecentMessages(prev => ({
        ...prev,
        ...m.messages
      }));
    });

    return () => {
      socket?.off("GET_SINGLE_CHAT_MESSAGES_ALL_SENDERS_SUCCESS");
    };
  }, [receiverId, senderId, socket]);

  useEffect(() => {
    socket?.on("GET_SINGLE_CHAT_MESSAGES_SUCCESS", (messages: messages) => {
      if (messages?.messages) {
        setMessagesList(messages.messages);
      }
    });

    return () => {
      socket?.off("GET_SINGLE_CHAT_MESSAGES_SUCCESS");
    };
  }, [socket, receiverId, senderId]);

  if (isLoading) {
    return <div className=" h-1/2 flex justify-center items-center">
      <Loading />
    </div>
  }

  if (isError && axios.isAxiosError(error)) {
    toast.error(error?.response?.data?.error, { toastId: "customId" });
  }

  return (
    <>
      <div ref={pageTop} />
      <ProviderDashboardLayout
        headerTitle="Messages"
      >
        <div className="w-full h-full">
          <div className="flex justify-between min-h-fit items-start max-h-[calc(100vh-100px)] w-full mr-0 mt-0 pt-0 overflow-x-hidden">
            <div className="flex flex-col justify-start bg-none lg:bg-[#F5FAF5] fixed w-full lg:w-[330px] h-full p-2 ml-0 pl-0 lg:pt-[27px] overflow-y-scroll lg:overflow-x-hidden">
              {selectedChatDetails && (
                <div className="hidden lg:block mx-auto mb-4">
                  <SearchInput
                    value={searchTerm}
                    placeholder="Search through your messages"
                    additionalClassname="w-[300px] h-[40px]"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                </div>
              )}
              {!selectedChatDetails && (
                <div className="mx-auto mb-4">
                  <SearchInput
                    value={searchTerm}
                    placeholder="Search through your messages"
                    additionalClassname="w-[300px] h-[40px]"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                </div>
              )}

              {/* {!selectedChatDetails && (
                <MessagesList
                  socket={socket}
                  expandMessage={selectedMessage => {
                    setSelectedChatDetails(selectedMessage);
                  }}
                  list={patientsList}
                  recentMessages={recentMessages}
                  selectedChat={selectedChatDetails}
                  setRecentMessages={setRecentMessages}
                />
              )}

              {selectedChatDetails && (
                <div className="hidden lg:block">
                  <MessagesList
                    socket={socket}
                    expandMessage={selectedMessage => {
                      setSelectedChatDetails(selectedMessage);
                    }}
                    list={patientsList}
                    recentMessages={recentMessages}
                    selectedChat={selectedChatDetails}
                    setRecentMessages={setRecentMessages}
                  />
                </div>
              )} */}

              <MessagesList
                socket={socket}
                expandMessage={(selectedMessage: any) => {
                  setSelectedChatDetails((prev) => {
                    if (prev?.userId === selectedMessage.userId) {
                      return null
                    }
                    navigate(`/provider/messages/${selectedMessage.userId}`)
                    return selectedMessage
                  });
                }}
                list={filteredProviders}
                recentMessages={recentMessages}
                selectedChat={selectedChatDetails}
                setRecentMessages={setRecentMessages}
              />

            </div>

            <div
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
              className="relative w-full pt-4 h-full min-h-[calc(100vh-100px)] max-w-full lg:max-w-[calc(100%-330px)] lg:min-w-[calc(100%-330px)] lg:ml-auto mr-0 flex flex-col justify-end bg-white mb-3"
            >
              {selectedChatDetails ? (
                <MessageChat
                  socket={socket}
                  selectedUserDetails={selectedChatDetails}
                  messagesList={messagesList}
                  userType="provider"
                  setMessagesList={setMessagesList}
                />
              ) : (
                  <div className="h-full w-full m-auto flex flex-col justify-center items-center">
                  <EmptyMsgIcon />
                  <div className="font-semibold text-[#34623f] mt-4 text-center">
                    No chats selected
                  </div>
                  <div className="text-[#103C1B] pt-2 w-[322px] text-center">
                    Select a chat on the left to see the details here
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ProviderDashboardLayout>
    </>
  );
};

export default Messages;
