import { format } from 'date-fns';
import { toZonedTime } from "date-fns-tz";
import { useEffect, useRef } from 'react';
import { getCurrentUser } from '../../../api/auth';
import { ProviderCharge } from '../../../api/types';
import { formatDateSafely } from '../../../utils/format_date';
import { PatientDetailsType } from '../../pages/PatientDetail/interfaces/patientDetails';
import { FormValues } from '../types/chargeSheet';

type Props = {
  initialValues: FormValues;
  chargeSheetData: ProviderCharge | null;
  patientData: PatientDetailsType | null;
  setValues: (values: React.SetStateAction<FormValues>) => void;
  values: FormValues;
  isFromNewBilling: boolean;
  IsFromNewBillingTable?: boolean;
};

export const useSyncFormValues = ({
  initialValues,
  chargeSheetData,
  patientData,
  setValues,
  values,
  isFromNewBilling,
  IsFromNewBillingTable
}: Props) => {
  const currentUser = getCurrentUser();
  const prevValuesRef = useRef<FormValues | null>(null);

  const timeZone = "America/New_York";
  const zonedDate = toZonedTime(chargeSheetData?.dateOfBirth as string, timeZone);

  useEffect(() => {
    let updatedValues = { ...initialValues };

    if (chargeSheetData) {
      updatedValues = {
        ...updatedValues,
        patientName: chargeSheetData.patientName ?? initialValues.patientName,
        dateOfBirth: chargeSheetData.dateOfBirth
          ? format(zonedDate, "MM/dd/yyyy")
          : initialValues.dateOfBirth,
        gender: chargeSheetData.gender ?? initialValues.gender,
        procedureCodes: chargeSheetData.procedureCodes?.length
          ? chargeSheetData.procedureCodes
          : initialValues.procedureCodes ?? [],
        diagnosisCodes: chargeSheetData.diagnosisCodes?.length
          ? chargeSheetData.diagnosisCodes
          : initialValues.diagnosisCodes ?? [],
        patientInsurance: chargeSheetData.insurance ?? initialValues.patientInsurance,
        patientId: chargeSheetData.patientId ?? "",
        appointmentId: chargeSheetData.appointmentId ?? "",
        dateOfEncounter: formatDateSafely(chargeSheetData.dateOfEncounter) ?? initialValues.dateOfEncounter,
        modeOfEncounter: chargeSheetData.modeOfEncounter ?? "virtual",
        status: chargeSheetData.status ?? null,
      };
    }

    if (isFromNewBilling && patientData?.patientDetails.patient) {
      const patient = patientData.patientDetails.patient;
      const zonedDate = toZonedTime(patient.dateOfBirth as string, timeZone);
      updatedValues = {
        ...updatedValues,
        patientName: `${patient.firstName || ''} ${patient.lastName || ''}`.trim(),
        dateOfBirth: patient.dateOfBirth
          ? format(zonedDate, "yyyy-MM-dd")
          : '',
        gender: patient.gender ?? undefined,
        patientInsurance: patient.insuranceName || "",
        patientId: patient.userId || "",
      };
    }

    if (IsFromNewBillingTable && chargeSheetData) {
      updatedValues = {
        ...updatedValues,
        procedureCodes: chargeSheetData.procedureCodes?.length
          ? chargeSheetData.procedureCodes
          : initialValues.procedureCodes ?? [],
        diagnosisCodes: chargeSheetData.diagnosisCodes?.length
          ? chargeSheetData.diagnosisCodes
          : initialValues.diagnosisCodes ?? [],
        patientName: chargeSheetData.patientName ?? initialValues.patientName,
        dateOfBirth: chargeSheetData.dateOfBirth
          ? format(new Date(chargeSheetData.dateOfBirth), "MM/dd/yyyy")
          : initialValues.dateOfBirth,
        gender: chargeSheetData.gender ?? initialValues.gender,
        patientInsurance: chargeSheetData.insurance ?? initialValues.patientInsurance,
        patientId: chargeSheetData.patientId ?? "",
        appointmentId: chargeSheetData.appointmentId ?? "",
        dateOfEncounter: formatDateSafely(chargeSheetData.dateOfEncounter) ?? initialValues.dateOfEncounter,
        modeOfEncounter: chargeSheetData.modeOfEncounter ?? "virtual",
        status: chargeSheetData.status ?? null,
      };
    }

    updatedValues.providerName = `${currentUser?.firstName} ${currentUser?.lastName}`;
    updatedValues.locationOfEncounter = currentUser?.address;

    // Only update if values have changed
    if (JSON.stringify(updatedValues) !== JSON.stringify(prevValuesRef.current)) {
      prevValuesRef.current = updatedValues;
      setValues(updatedValues);
    }
  }, [chargeSheetData,
    patientData?.patientDetails.patient,
    isFromNewBilling,
    IsFromNewBillingTable,
    currentUser,
    initialValues,
    setValues,
    zonedDate
  ]);

  return;
};