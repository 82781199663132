import { useQuery } from '@tanstack/react-query';
import http from '../../../utils/http';

type UserSource = {
  userSource: string 
};
export function useGetUserSource(userId: string) {
  const { data, isLoading, refetch, ...rest } = useQuery({
    queryKey: ['get user source', userId],
    queryFn: async () => {
      const response = await http.get(`/common/user/source/${userId}`);
      return response.data;
    },
    staleTime: Infinity,
    enabled: !!userId,
    // refetch data every 1 minute for real-time updates when the patientId is available
    refetchInterval: userId ? 3600000 : false,// fetch appointment every 1hour
  });
  return {
    data: data as UserSource,
    isLoading,
    ...rest,
  }
}
