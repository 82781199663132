import { Divider } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReportProblemModal from "../../../components/ReportProblemModal";
import Spacer from "../../../components/Spacer";
import { BusinessOfficeIcon } from "../../../svgs/BusinessOfficeIcon";
import { ReactComponent as CloseIcon } from "../../../svgs/close-icon.svg";
import { InfoIcon2 } from "../../../svgs/InfoIcon2";
import { PatientSidebarIcon } from "../../../svgs/PatientSidebarIcon";
import { PaymentBillingIcon } from "../../../svgs/PaymentBillingIcon";
import { ProviderAppointmentSidebarIcon } from "../../../svgs/ProviderAppointmentSidebarIcon";
import { ProviderMessageSidebarIcon } from "../../../svgs/ProviderMessageSidebarIcon";
import { ProviderOverviewSidebarIcon } from "../../../svgs/ProviderOverviewSidebarIcon";
import { ProviderSettingsSidebarIcon } from "../../../svgs/ProviderSettingsSidebarIcon";
import { ProviderTemplateSidebarIcon } from "../../../svgs/ProviderTemplateSidebarIcon";
import { ReactComponent as LogoutIcon } from "../../../svgs/temple-logout.svg";
import { TempleHSLogo } from "../../../svgs/TempleHSLogo";
import ProviderMenuItem from "./ProviderMenuItem";

interface IProps {
  isMenuOpen: boolean;
  dismiss: () => void;
}

const ProviderRetractibleSidebar: React.FC<IProps> = ({
  isMenuOpen,
  dismiss
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isReportModalVisible, setIsReportModalVisible] = React.useState(false);

  const handleMenuClick = (path: string) => {
    navigate(`${path}`);
  };

  const menuItems = [
    {
      path: "/provider/dashboard",
      label: "Dashboard",
      icon: <ProviderOverviewSidebarIcon color="#000" />,
      activeIcon: <ProviderOverviewSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/patients",
      label: "Patients",
      icon: <PatientSidebarIcon color="#000" />,
      activeIcon: <PatientSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/appointments",
      label: "Appointments",
      icon: <ProviderAppointmentSidebarIcon color="#000" />,
      activeIcon: <ProviderAppointmentSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/messages",
      label: "Messages",
      icon: <ProviderMessageSidebarIcon color="#000" />,
      activeIcon: <ProviderMessageSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/payment-billing",
      label: "Payment & Billing",
      icon: <PaymentBillingIcon color="#000" />,
      activeIcon: <PaymentBillingIcon color="#ABE9B9" />
    },
    {
      path: "/provider/subscription",
      label: "Subscription",
      icon: <PaymentBillingIcon color="#000" />,
      activeIcon: <PaymentBillingIcon color="#ABE9B9" />
    },
    {
      path: "/provider/templates",
      label: "Templates",
      icon: <ProviderTemplateSidebarIcon color="#000" />,
      activeIcon: <ProviderTemplateSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/settings",
      label: "Settings",
      icon: <ProviderSettingsSidebarIcon color="#000" />,
      activeIcon: <ProviderSettingsSidebarIcon color="#3D874E" />
    },
    {
      path: "/provider/business-office",
      label: "Business Office",
      icon: <BusinessOfficeIcon color="#000" />,
      activeIcon: <BusinessOfficeIcon color="#ABE9B9" />
    }
  ];

  const bottomMenus = [
    {
      path: "/",
      label: "Log out",
      icon: <LogoutIcon stroke="#000" />,
      activeIcon: <LogoutIcon stroke="#fff" />
    }
  ];
  return (
    <aside
      className={clsx({
        "sidebar-provider z-9999 w-full h-screen fixed left-0 ease-in-out duration-500 lg:hidden bg-black bg-opacity-50":
          true,
        "-translate-x-0": isMenuOpen,
        "-translate-x-full": !isMenuOpen
      })}
    >
      <div className="w-[240px] h-full bg-white relative pt-[47px] text-sm font-semibold border border-t-0 border-[#d1d2d4]-500 shadow shadow-[4px 4px 15px 4px rgba(51, 51, 51, 0.05)] content-center lg:content-start text-left flex flex-col justify-between overflow-y-auto min-h-[100vh]">
        <div className="w-full flex items-center justify-between px-4 mb-4 lg:mb-0">
          <div>
            <TempleHSLogo />
          </div>
          <button
            type="button"
            className=" border-none outline-none cursor-pointer"
            onClick={dismiss}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="h-[50px]" />
        <div className="h-[100vh] flex flex-col justify-between">
          <div>
            {menuItems.map(item => (
              <ProviderMenuItem
                key={item.label}
                isActive={location.pathname.startsWith(item.path)}
                icon={
                  location.pathname === item.path ? item.activeIcon : item.icon
                }
                label={item.label}
                onClick={() => {
                  handleMenuClick(item.path);
                }}
              />
            ))}
          </div>
          <Divider />
          <div className="w-full mt-[1.375rem]">
            <div
              onClick={() => {
                setIsReportModalVisible(true);
              }}
              className="w-full cursor-pointer text-[#34623F] bg-white border-y border-[#8BDF9F] flex justify-center items-center h-[50px]"
            >
              <InfoIcon2 />
              <p className="ml-2">Report an issue</p>
            </div>
          </div>
          <div className="bottom-0">
            {bottomMenus.map(item => (
              <ProviderMenuItem
                key={item.label}
                isActive={location.pathname === item.path}
                icon={
                  location.pathname === item.path ? item.activeIcon : item.icon
                }
                label={item.label}
                onClick={() => {
                  if (item.label === "Log out") {
                    localStorage.removeItem("temple_user_token");
                    localStorage.clear();
                    window.location.href = "https://www.templehs.com";
                  } else {
                    handleMenuClick(item.path);
                  }
                }}
              />
            ))}
            <Spacer height={150} />
          </div>
        </div>
      </div>
      <ReportProblemModal
        isVisible={isReportModalVisible}
        closeModal={() => setIsReportModalVisible(false)}
        actionTrigger={() => {
          setIsReportModalVisible(false);
        }}
      />
    </aside>
  );
};

export default ProviderRetractibleSidebar;
