import { AxiosError } from "axios";
import http from "../../../utils/http";

export const generateDocumentation = async (data: any) => {
  try {
    const documentation = await http.post("/common/aws-scribe", data);
    return documentation;
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      return error?.response?.data;
    }
  }
};
