import { capitalize } from "@mui/material";
import clsx from "clsx";
import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { useDeleteAdminComplaint } from "../../../api/hooks/admin/delete_admin_complaint";
import { useUpdateAdminComplaints } from "../../../api/hooks/admin/update_admin_complaint";
import AdminComplaintActionCell from "../../components/AdminComplaintActionCell";
import { ComplaintObject } from "../../types/admin_complaints";


type Props = {
  setUserId: React.Dispatch<React.SetStateAction<string>>
  setIsComplaintDetailsView: React.Dispatch<React.SetStateAction<boolean>>
}

const AdminStatusEl = ({ status }: { status: string }) => (
  <div className="flex items-center">
    <p
      className={clsx({
        "text-[#5F91C0]": status === "active",
        "text-[#3D874E]": status === "resolved",
        "text-[#F1A512]": status === "inProgress"
      })}
    >
      {status && status === "inProgress" ? "In Progress" : capitalize(status)}
    </p>
  </div>
);
export function useAdminComplaintTableCols({ setUserId, setIsComplaintDetailsView }: Props) {
  const { deleteAdminComplaint } = useDeleteAdminComplaint()
  const { updateAdminComplaint } = useUpdateAdminComplaints()

  const TABLE_COLUMNS = useMemo<Column<ComplaintObject>[]>(
    () => [
      {
        Header: "Complaint ID",
        accessor: "complaintId",
        Cell: ({ cell: { value } }) => {
          return (
            <Fragment>
              {value ? "#" + value.slice(0, 7) : ""}
            </Fragment>
          );
        }
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        Cell: ({ cell: { value } }) => {
          return <Fragment>{value}</Fragment>;
        }
      },
      {
        Header: "Created On",
        accessor: "created_at",
        Cell: ({ cell: { value }, row }) => {
          return (
            <Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : "N/A"}
            </Fragment>
          );
        }
      },
      {
        Header: "Description",
        accessor: "description"
      },
      {
        Header: "Account Type",
        accessor: "accountType"
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => {
          return (
            <Fragment>
              <AdminStatusEl status={value} />
            </Fragment>
          );
        }
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ cell: { value }, row }) => {
          return (
            <AdminComplaintActionCell
              viewComplaint={() => {
                setUserId(row.original?.complaintId);
                setIsComplaintDetailsView(true);
              }}
              markAsInProgress={() => {
                updateAdminComplaint(
                  {
                    userId: row.original?.complaintId,
                    status: "inProgress"
                  }
                );
              }}
              markAsResolved={() => {
                updateAdminComplaint(
                  {
                    userId: row.original?.complaintId,
                    status: "resolved"
                  }
                );

              }}
              deleteComplaint={() => {
                deleteAdminComplaint({ userId: row.original?.complaintId });
              }}
              status={row.original?.status}
            />
          );
        }
      }
    ],
    [deleteAdminComplaint, updateAdminComplaint, setIsComplaintDetailsView, setUserId]
  );

  return {
    TABLE_COLUMNS,
  };
}
