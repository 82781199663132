function MicrophoneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={120}
      height={120}
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={6.72897}
        y={6.72897}
        width={106.542}
        height={106.542}
        rx={53.271}
        fill="#fff"
      />
      <rect
        x={6.72897}
        y={6.72897}
        width={106.542}
        height={106.542}
        rx={53.271}
        stroke="#EEEEE9"
        strokeWidth={13.4579}
      />
      <path
        d="M59.439 67.007a9.346 9.346 0 009.345-9.346V43.643a9.346 9.346 0 00-18.691 0V57.66a9.345 9.345 0 009.346 9.346zm-4.673-23.364a4.673 4.673 0 119.346 0V57.66a4.673 4.673 0 01-9.346 0V43.643zM78.13 57.66a2.336 2.336 0 10-4.673 0 14.019 14.019 0 11-28.037 0 2.337 2.337 0 00-4.673 0A18.691 18.691 0 0057.102 76.19v4.837H52.43a2.336 2.336 0 000 4.673h14.019a2.336 2.336 0 100-4.673h-4.673v-4.837A18.691 18.691 0 0078.13 57.661z"
        fill="#3D874E"
      />
    </svg>
  );
}

export default MicrophoneIcon;
