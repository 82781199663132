import React from "react";
import { Socket } from "socket.io-client";
import { RecentMessages } from "../../patient/types/messages";
import { SelectedChatDetails } from "../../patient/types/selectedChatDetails";
import MessagesListItem from "./MessageListItem";
import { PatietAndProviderList } from "./type/patient_provider_list";

interface IProps {
  list: PatietAndProviderList[];
  expandMessage: (arg: any) => void;
  socket: Socket;
  recentMessages: RecentMessages;
  selectedChat: SelectedChatDetails | null;
  setRecentMessages: React.Dispatch<React.SetStateAction<RecentMessages>>;
}

const MessagesList: React.FC<IProps> = ({
  list,
  expandMessage,
  socket,
  recentMessages,
  selectedChat,
  setRecentMessages
}) => (
  <div className="flex flex-col justify-start w-full lg:w-[330px] lg:h-full border-[1px] border-solid border-[#EBEBEB] rounded-lg lg:border-none px-0 py-2">
    {list?.length > 0 ? (
      list?.map(listItem => (
        <MessagesListItem
          socket={socket}
          key={listItem.userId}
          expandMessage={expandMessage}
          imageUrl={listItem.avatarUrl}
          providerName={listItem?.name}
          isSelected={selectedChat?.userId === listItem?.userId}
          receiverDetails={listItem}
          recentMessages={recentMessages}
          setRecentMessages={setRecentMessages}
          selectedChat={selectedChat}
        />
      ))
    ) : null}
  </div>
)


export default MessagesList;
