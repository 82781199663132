import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL } from "@ffmpeg/util";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { getS3SignedUrl } from "../../../api/hooks/common/fetchers.common";
import { generateUUID } from "../../../utils/helpers";
import { useProviderAuthState } from "../../../api/hooks/provider/auth/useProviderAuthState";
import axios from "axios";
import { generateDocumentation } from "../../../api/hooks/provider/generateDocumentation";
import Button from "../../../components/shared/Button";
import MicrophoneIcon from "../../../svgs/Microphone";
import { PauseCircleFilledRounded, StopCircle } from "@mui/icons-material";
import ModalPortal from "../../../components/shared/ModalPortal";
import { CancelIcon } from "../../../svgs/CancelIcon";
import { Socket } from "socket.io-client";

type AWSDocumentation = {
  ClinicalDocumentation: {
    Sections: Array<{
      SectionName: string;
      Summary: Array<{
        SummarizedSegment: string;
      }>;
    }>;
  };
};

const CallRecorder = ({
  setNoteGenerationStatus,
  socket,
  jitsiApi,
  remoteStream
}: {
  setNoteGenerationStatus: Dispatch<
    SetStateAction<"neutral" | "ai-generation" | "manual">
  >;
  socket: Socket;
  jitsiApi: any; // Jitsi API instance
  remoteStream: any;
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaStream = useRef<MediaStream>();
  const mediaRecorder = useRef<MediaRecorder>();
  const chunks = useRef<Array<Blob>>([]);
  const [recordedUrl, setRecordedUrl] = useState<string | null>(null);
  const [isModuleLoaded, setIsModuleLoaded] = useState(false);
  const localStreamRef = useRef<MediaStream | null>(null);
  const combinedStreamRef = useRef<MediaStream | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordedChunksRef = useRef<Blob[]>([]);
  const ffmpegRef = useRef(new FFmpeg());
  const [timer, setTimer] = useState(0); // Timer in seconds
  const timerRef = useRef<number | null>(null); // Ref to store the interval ID
  const user = useProviderAuthState();
  const [callFile, setCallFile] = useState<File | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isUploadingRecording, setIsUploadingRecording] =
    useState<boolean>(false);
  const [isGeneratingDocumentation, setIsGeneratingDocumentation] =
    useState<boolean>(false);
  const [newDocumentation, setNewDocumentation] = useState<{
    data: AWSDocumentation;
  }>();

  // Load FFmpeg
  useEffect(() => {
    const loadFFmpeg = async () => {
      const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
      const ffmpeg = ffmpegRef.current;
      // toBlobURL is used to bypass CORS issue, urls with the same
      // domain can be used directly.
      await ffmpeg.load({
        coreURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          "text/javascript"
        ),
        wasmURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          "application/wasm"
        )
      });
      setIsModuleLoaded(true);
    };

    loadFFmpeg();
  }, []);

  // Socket event listeners
  useEffect(() => {
    socket.on("uploaded-recording", () => {
      setIsGeneratingDocumentation(true);
    });
  }, [socket]);

  useEffect(() => {
    socket.on("new-documentation", data => {
      console.log("documentation received", data);
      setNewDocumentation(data);
      setIsGeneratingDocumentation(false);
    });
  }, [socket]);

  // Initialize local stream
  useEffect(() => {
    const initializeLocalStream = async () => {
      try {
        const localStream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });
        localStreamRef.current = localStream;
        console.log("Local Stream Captured:", localStream);

        // Add local tracks to combined stream
        if (!combinedStreamRef.current) {
          combinedStreamRef.current = new MediaStream();
        }
        localStream.getAudioTracks().forEach(track => {
          combinedStreamRef.current!.addTrack(track);
        });
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    };

    initializeLocalStream();
  }, []);

  // Add remote tracks to combined stream
  useEffect(() => {
    if (remoteStream) {
      if (!combinedStreamRef.current) {
        combinedStreamRef.current = new MediaStream();
      }
      remoteStream.getAudioTracks().forEach((track: any) => {
        combinedStreamRef.current!.addTrack(track);
      });
      console.log("Remote Tracks Added to Combined Stream:", remoteStream);
    }
  }, [remoteStream]);

  // Transcode webm to wav
  const transcode = async (audioData: ArrayBuffer) => {
    const ffmpeg = ffmpegRef.current;
    await ffmpeg.writeFile("input.webm", new Uint8Array(audioData));
    await ffmpeg.exec(["-i", "input.webm", "output.wav"]);
    const data = await ffmpeg.readFile("output.wav");
    const fileName =
      generateUUID() + user?.user?.firstName + user?.user?.lastName;
    console.log({ fileName });
    const wavFile = new File([data], `${fileName}.wav`, { type: "audio/wav" });
    setCallFile(wavFile);
    console.log({ wavFile });
    return wavFile;
  };

  // Generate documentation from recorded call
  const generateDocumentationFromCall = async () => {
    if (callFile) {
      setIsUploadingRecording(true);
      const response = await getS3SignedUrl({
        operation: "putObject",
        key: callFile.name,
        object: "med-scribe"
      });

      if (response.signedUrlParams) {
        const { signedUrl } = response.signedUrlParams;
        const value = await axios.put(signedUrl, callFile);
        if (value.status === 200) {
          const documentation = await generateDocumentation({
            fileName: callFile.name
          });
          console.log({ documentation });
        }
        console.log({ value });
      }
      setIsUploadingRecording(false);
    }
    setIsModalVisible(false);
  };

  // Start recording
  const startRecording = () => {
    if (!combinedStreamRef.current) {
      console.error("No tracks available for recording.");
      return;
    }

    const mediaRecorder = new MediaRecorder(combinedStreamRef.current, {
      mimeType: "audio/webm"
    });

    mediaRecorder.ondataavailable = event => {
      if (event.data.size > 0) {
        recordedChunksRef.current.push(event.data);
      }
    };

    mediaRecorder.onstop = async () => {
      const recordedBlob = new Blob(recordedChunksRef.current, {
        type: "audio/webm"
      });
      const recordedUrl = URL.createObjectURL(recordedBlob);
      setRecordedUrl(recordedUrl);
      recordedChunksRef.current = [];
    };

    mediaRecorder.start(1000);
    mediaRecorderRef.current = mediaRecorder;
    setIsRecording(true);
    startTimer();
  };

  // Stop recording
  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      stopTimer();
    }
  };

  // Transcode WebM to WAV using FFmpeg
  const transcodeToWav = async (blob: Blob) => {
    const ffmpeg = ffmpegRef.current;
    const arrayBuffer = await blob.arrayBuffer();
    await ffmpeg.writeFile("input.webm", new Uint8Array(arrayBuffer));
    await ffmpeg.exec(["-i", "input.webm", "output.wav"]);
    const data = await ffmpeg.readFile("output.wav");
    const wavFile = new File([data], "recording.wav", { type: "audio/wav" });
    return wavFile;
  };

  // Timer functions
  const startTimer = () => {
    setTimer(0); // Reset the timer to 0
    timerRef.current = window.setInterval(() => {
      setTimer(prev => prev + 1);
    }, 1000); // Update every second
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  // Format timer as HH:MM:SS
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(seconds / 3600);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      if (mediaRecorderRef.current?.state === "recording") {
        mediaRecorderRef.current.stop();
      }
      if (localStreamRef.current) {
        localStreamRef.current.getTracks().forEach(track => track.stop());
      }
      if (combinedStreamRef.current) {
        combinedStreamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  return (
    <>
      {isModalVisible && (
        <ModalPortal>
          <div
            style={{
              backgroundColor: "rgba(38, 38, 38, 0.75)",
              zIndex: 100,
              display: "flex",
              justifyContent: "center"
            }}
            className="w-screen h-screen fixed flex flex-col justify-center items-center top-0 bg-opacity-20 backdrop-blur-[5px]"
          >
            <div className="flex justify-end  w-[90%] lg:w-[462px] mx-auto py-2">
              <div
                onClick={() => {
                  setIsModalVisible(false);
                }}
                className="p-2 bg-white rounded-full cursor-pointer"
              >
                <CancelIcon className="h-full w-full" />
              </div>
            </div>
            {isUploadingRecording ? (
              <div className="flex flex-col justify-center items-center bg-white">
                <div className="w-8 h-8 border-4 border-t-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
                <span className="text-lg animate-pulse mt-2">
                  Uploading Recording...
                </span>
              </div>
            ) : (
              <div
                className={`bg-white h-[300px] w-[50%] px-6 pt-6 pb-4 lg:p-6 shadow-lg rounded-[16px] border-box flex flex-col justify-center items-center gap-5 ${isUploadingRecording ? "blur-sm" : ""}`}
              >
                <p>
                  This service costs $0.001667 per second of transcription. Your
                  saved card will be charged. Proceed?
                </p>
                <Button
                  variant="primary"
                  onClick={generateDocumentationFromCall}
                  label="Yes, proceed"
                  loading={isUploadingRecording}
                />
              </div>
            )}
          </div>
        </ModalPortal>
      )}
      {isModuleLoaded ? (
        <div className="my-5">
          <div
            className="flex flex-col items-center justify-center py-5 px-2 gap-5 mb-5"
            style={{ boxShadow: "0 0 2px rgba(0, 0, 0, 0.3)" }}
          >
            {!recordedUrl && (
              <div className="relative flex flex-col items-center justify-center py-5 px-2 gap-5 mb-5">
                <div
                  className={`w-36 h-36 rounded-full border-4 border-green-500 ${isRecording ? " absolute animate-pulse top-2" : " hidden"}`}
                ></div>
                <MicrophoneIcon
                  className={`text-4xl relative ${isRecording ? "animate-pulse text-red-500" : "text-white"}`}
                />
                {!isRecording && (
                  <Button
                    variant="primary"
                    label="Start recording"
                    onClick={startRecording}
                  />
                )}
              </div>
            )}
            {isRecording && (
              <div className="flex gap-5 items-center">
                <PauseCircleFilledRounded
                  fontSize="large"
                  style={{
                    color: "#D8F7E0",
                    padding: "1px",
                    cursor: "pointer"
                  }}
                />
                <p>{formatTime(timer)}</p>
                <StopCircle
                  fontSize="large"
                  onClick={stopRecording}
                  style={{ color: "#3D874E", cursor: "pointer" }}
                />
              </div>
            )}

            {recordedUrl && !newDocumentation?.data ? (
              <>
                {isGeneratingDocumentation && (
                  <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
                    <span className="font-bold text-lg animate-pulse">
                      Sit tight. We're generating your documentation...
                    </span>
                  </div>
                )}
                <audio controls>
                  <source src={recordedUrl} type="audio/webm" />
                </audio>
                <Button
                  variant="primary"
                  onClick={() => setIsModalVisible(true)}
                  label="Generate Documentation"
                />
              </>
            ) : (
              <>
                {newDocumentation?.data?.ClinicalDocumentation?.Sections?.map(
                  (section: any, index: number) => (
                    <div key={index}>
                      <p>{section.SectionName?.replaceAll("-", " ")}</p>
                      <div>
                        {section.Summary?.map((summary: any, index: number) => (
                          <p key={index}>{summary.SummarizedSegment}</p>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </>
            )}
          </div>
          <p
            className="cursor-pointer text-center underline text-lg"
            onClick={() => setNoteGenerationStatus("manual")}
          >
            Switch to manual generation?
          </p>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default CallRecorder;
