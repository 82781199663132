import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../../utils/http";
import { RegisterPayload } from "../../types";
import { setToken } from "../../../utils/helpers";

export const useRegisterProvider = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation({
    mutationKey: ["create Provider"],
    mutationFn: async ({ ...data }: RegisterPayload) => {
      delete data.acceptTermsOfService;
      const response = await http.post("/providers/register", data);
      return response;
    },

    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ["get all provider"] });
      console.log({ data });
      setToken(data?.token);
      if (data?.token) {
        navigate(`/provider/dashboard`, {
          replace: true
        });
      }
    },

    onError: error => {
      if (axios.isAxiosError(error)) {
        toast.error(error?.response?.data?.error, { toastId: "customId" });
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  });

  return {
    createProvider: mutate,
    ...rest
  };
};
