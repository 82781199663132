import clsx from "clsx";
import { useMemo } from "react";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { ArrowDownIcon } from "../svgs/ArrowDownIcon";
import TextField from "./form/TextField";
import Button from "./shared/Button";

interface ReusableTableProps {
  searchFieldPlaceholder?: string;
  tableColumns: any[]
  tableData: any[];
  onClick: (arg: any) => void;
  pageSizeCount?: number;
  showTitleHideSearch?: boolean;
  title?: string;
  padding?: boolean;
  headerButton?: boolean;
  headerButtonLabel?: string;
  headerButtonFunction?: (arg: any) => void;
}

function ReusableTable({
  searchFieldPlaceholder = "",
  tableColumns,
  tableData,
  onClick,
  pageSizeCount = 10,
  showTitleHideSearch = false,
  title = "",
  padding = true,
  headerButton,
  headerButtonFunction,
  headerButtonLabel
}: ReusableTableProps) {
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    setGlobalFilter,
    state: { pageIndex, globalFilter }
  } = useTable(
    {
      columns: tableColumns,
      data,
      initialState: { pageIndex: 0, pageSize: pageSizeCount }
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <div
      className={clsx({ "w-full mb-8 bg-white": true, "pt-6 px-4": padding })}
      data-testid='table-container'
    >
      <div className="shadow-table">
        {!showTitleHideSearch ? (
          <div className="pb-6 mx-2 flex justify-between" data-testid='table-utils-container'>
            <TextField
              placeholder={searchFieldPlaceholder}
              value={globalFilter || ""}
              onChange={e => setGlobalFilter(e.target.value)}
              name=""
              additionalClassname="w-full lg:w-[250px]"
            />
            {headerButton && (
              <Button
                variant="primary"
                size="large"
                label={headerButtonLabel}
                onClick={headerButtonFunction}
                icon={<ArrowDownIcon color="#fff" />}
                iconPosition="right"
              />
            )}
          </div>
        ) : title ? (
          <p
            className="w-full lg:w-
            1/2 text-[28px] font-[600] text-[#324705] ml-4 pb-6"
          >
            {title}
          </p>
        ) : null}
        <table {...getTableProps()} data-testid='table'>
          <thead>
            {headerGroups?.map(headerGroup => {
              const headerGroupProps = headerGroup.getHeaderGroupProps();
              const { key: headerGroupKey, ...restHeaderGroupProps } = headerGroupProps;
              return (
                <tr key={headerGroupKey} {...restHeaderGroupProps}>
                  {headerGroup.headers.map(column => {
                    const headerProps = column.getHeaderProps();
                    const { key, ...restHeaderProps } = headerProps;
                    return (
                      <th
                        key={key}
                        className="text-[#34623F] text-[16px] font-[600]"
                        {...restHeaderProps}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map(row => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              const { key: rowKey, ...restRowProps } = rowProps;
              return (
                <tr
                  key={rowKey}
                  className="hover:bg-[#E7FBEC] cursor-pointer h-[70px]"
                  {...restRowProps}
                  data-testid='table-row'
                >
                  {row.cells.map(cell => {
                    const cellProps = cell.getCellProps();
                    const { key: cellKey, ...restCellProps } = cellProps;
                    return (
                      <td
                        key={cellKey}
                        {...restCellProps}
                        onClick={() => {
                          onClick(row?.original);
                        }}
                        data-testid='table-cell'
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* TABLE PAGINATION */}
      <div className="w-full flex items-center justify-end mr-8 mt-2">
        <button
          className={clsx({
            "w-[35px] h-[35px] text-[#3D874E] flex items-center justify-center my-auto border border-[#ddd] rounded  cursor-pointer":
              true,
            "text-[#ddd] cursor-not-allowed": !canPreviousPage
          })}
          disabled={!canPreviousPage}
          onClick={() => previousPage()}
        >
          {"<"}
        </button>
        <span className="mx-2 w-[35px] h-[35px] flex items-center justify-center my-auto border border-[#ddd] bg-[#3D874E] text-[#fff] rounded">
          {pageIndex + 1}
        </span>
        of{" "}
        <span className="mx-2 w-[35px] h-[35px] flex items-center justify-center my-auto border border-[#ddd] text-[#3D874E] rounded">
          {pageOptions.length}
        </span>
        <div className="w-2" />
        <button
          disabled={!canNextPage}
          onClick={() => nextPage()}
          className={clsx({
            "mr-8 w-[35px] h-[35px] text-[#3D874E] flex items-center justify-center my-auto border border-[#ddd] rounded  cursor-pointer":
              true,
            "text-[#ddd] cursor-not-allowed": !canNextPage
          })}
        >
          {">"}
        </button>
      </div>
      <div className="h-8" />
    </div>
  )
}

export default ReusableTable;
