import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { Column } from "react-table";
import { useGeEraList } from "../../api/hooks/biller/useGetEraList";
import ReusableTable from "../../components/ReusableTable";
import Button from "../../components/shared/Button";
import { ArrowBackIcon } from "../../svgs/ArrowBackIcon";
import SPDashboardLayout from "../components/SPDashboardLayout";

interface ClaimsType {
  payer_name: string;
  received_time: Date;
  paid_amount: string;
  providerName: string;
  claimmd_provider_name: string;
}
// Add ERA list type 

function SPERAs() {
  const [isDetailsView, setIsDetailsView] = useState<boolean>(false);

  const { eraList } = useGeEraList();

  const fetchSinglePatient = async (value: string) => {
    //  FETCH API FROM CLAIMMD OR USE EXISTING DATA FROM ERALIST, IF SUCCESSFUL SHOW THE DETAILS AND SET THE RIGHT VIEW
    setIsDetailsView(true);
  };

  const ERAS_TABLE = useMemo<Column<ClaimsType>[]>(
    () => [
      {
        Header: "Payer Name",
        accessor: "payer_name"
      },
      {
        Header: "Received Time",
        accessor: "received_time",
        Cell: ({ cell: { value }, row }) => {
          return (
            <React.Fragment>
              {value ? format(new Date(value), "MM/dd/yyyy") : ""}
            </React.Fragment>
          );
        }
      },
      {
        Header: "Claim provider",
        accessor: "claimmd_provider_name"
      },
      {
        Header: "Provider Name",
        accessor: "providerName"
      },
      {
        Header: "Paid amount",
        accessor: "paid_amount"
      }
    ],
    []
  );
  return (
    <SPDashboardLayout headerTitle={isDetailsView ? "ERA Details" : "ERAs"}>
      {isDetailsView ? (
        <div className="w-full h-full">
          <div className="w-full flex items-center justify-between ">
            <div
              onClick={() => setIsDetailsView(false)}
              className="w-full cursor-pointer pl-4 flex justify-start items-center"
            >
              <ArrowBackIcon /> <div className="ml-3">Go back to ERAs</div>
            </div>
            <div className="w-full flex items-center justify-end">
              <Button variant="secondary" label="Print" size="medium" />
              <div className="w-4" />
              <Button variant="secondary" label="Save" size="medium" />
              <div className="w-4" />
              <Button variant="primary" label="Reviewd" size="medium" />
            </div>
          </div>
          <div className="h-16" />

          <div className="w-full flex items-center pl-4">
            <div className="w-[595px]">
              <div className="h-[53pxx] w-full bg-[#F8F8F7] text-[20px] text-[#324705] font-[500] p-2 mb-5">
                Payer Information
              </div>
              <div className="w-[80%] flex items-center justify-between">
                <p className="text-[16px] text-[#87928A] font-[500]">
                  Name of Payer
                </p>
                {/* <div className="w-20" /> */}
                <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
                  Tru Valu Drugs
                </p>
              </div>
              <div className="h-4" />
              <div className="w-[80%] flex items-center justify-between">
                <p className="text-[16px] text-[#87928A] font-[500]">
                  Identifier Address
                </p>
                {/* <div className="w-20" /> */}
                <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
                  123 Main St, Anytown, USA
                </p>
              </div>
              <div className="h-4" />
              <div className="w-[80%] flex items-center justify-between">
                <p className="text-[16px] text-[#87928A] font-[500]">
                  Claim ID
                </p>
                {/* <div className="w-20" /> */}
                <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
                  0001
                </p>
              </div>
              <div className="h-4" />
              <div className="w-[80%] flex items-center justify-between">
                <p className="text-[16px] text-[#87928A] font-[500]">
                  Contact Number
                </p>
                {/* <div className="w-20" /> */}
                <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
                  08165817208
                </p>
              </div>
            </div>
            <div className="w-8" />
            <div className="w-[595px]">
              <div className="h-[53pxx] w-full bg-[#F8F8F7] text-[20px] text-[#324705] font-[500] p-2 mb-5">
                Payment Information
              </div>
              <div className="w-[80%] flex items-center justify-between">
                <p className="text-[16px] text-[#87928A] font-[500]">
                  Total payment amount
                </p>
                {/* <div className="w-20" /> */}
                <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
                  $150
                </p>
              </div>
              <div className="h-4" />
              <div className="w-[80%] flex items-center justify-between">
                <p className="text-[16px] text-[#87928A] font-[500]">
                  Payment method
                </p>
                {/* <div className="w-20" /> */}
                <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
                  Check
                </p>
              </div>
              <div className="h-4" />
              <div className="w-[80%] flex items-center justify-between">
                <p className="text-[16px] text-[#87928A] font-[500]">
                  Check ID
                </p>
                {/* <div className="w-20" /> */}
                <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
                  CK-123456
                </p>
              </div>
              <div className="h-4" />
              <div className="w-[80%] flex items-center justify-between">
                <p className="text-[16px] text-[#87928A] font-[500]">
                  Payment Date
                </p>
                {/* <div className="w-20" /> */}
                <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
                  11/06/2022
                </p>
              </div>
            </div>
          </div>
          <div className="w-full pl-4 mt-10">
            <div className="w-[550px]">
              <div className="h-[53pxx] w-full bg-[#F8F8F7] text-[20px] text-[#324705] font-[500] p-2 mb-5">
                Transfer Information
              </div>
              <div className="w-[80%] flex items-center justify-between">
                <p className="text-[16px] text-[#87928A] font-[500]">
                  Sender account number
                </p>
                {/* <div className="w-20" /> */}
                <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
                  0987654321
                </p>
              </div>
              <div className="h-4" />
              <div className="w-[80%] flex items-center justify-between">
                <p className="text-[16px] text-[#87928A] font-[500]">
                  Receiver identifier
                </p>
                {/* <div className="w-20" /> */}
                <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
                  MB-123456
                </p>
              </div>
              <div className="h-4" />
              <div className="w-[80%] flex items-center justify-between">
                <p className="text-[16px] text-[#87928A] font-[500]">
                  Receiver account number
                </p>
                {/* <div className="w-20" /> */}
                <p className="w-1/2 text-[16px] text-[#324705] font-[500] text-left">
                  9876543210
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <ReusableTable
            tableColumns={ERAS_TABLE}
              tableData={eraList as ClaimsType[]}
            onClick={data => {
              // Commenting this for now till we decide on data to display on the single details view from the data we have from claimmd. Check prod or design for current view
              fetchSinglePatient("id");
            }}
            pageSizeCount={10}
            searchFieldPlaceholder="Search electronic remittance advices"
          />
        </div>
      )}
    </SPDashboardLayout>
  );
}

export default SPERAs;
