import FullCalendar, { EventContentArg } from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { JaaSMeeting } from "@jitsi/react-sdk";
import axios from "axios";
import { add, format } from "date-fns";
import { Fragment, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useProviderAuthState } from "../../api/hooks/provider/auth/useProviderAuthState";
import { useBookProviderAppointments } from "../../api/hooks/provider/useBookProviderAppointments";
import { useCancelAppointment } from "../../api/hooks/provider/useCancelAppointment";
import { useGetPatientFormResponse } from "../../api/hooks/provider/useGetPatientResponse";
import { useGetProviderPreviousAppointments } from "../../api/hooks/provider/useGetPreviousProviderApointments";
import { useGetProviderForm } from "../../api/hooks/provider/useGetProviderForm";
import { useGetPatientUpcomingAppointments } from "../../api/hooks/provider/useGetProviderUpcomingAppointsments";
import { useGetSinglePatientRecord } from "../../api/hooks/provider/useGetSinglePatientRecord";
import { useGetProviderJitsiToken } from "../../api/hooks/provider/useProviderJitsiToken";
import { useSaveChargeSheetDraft } from "../../api/hooks/provider/useSaveChargeSheet";
import { useSaveFormResponse } from "../../api/hooks/provider/useSaveFormResponse";
import { useSendChargeSheetToBilling } from "../../api/hooks/provider/useSendChargeSheetToBilling";
import FormBuilder from "../../components/form/FormBuilder";
import BtnButton from "../../components/shared/BtnButton";
import ListTabsWidget from "../../components/shared/ListTabsWidget";
import { Loading } from "../../components/shared/Loading";
import { removeZerosAndCheckAMPM, truncateText } from "../../utils/helpers";
import { SimpleChargeSheetModal } from "../components/SimpleModal";
import MobileAppointmentCard from "../components/appointments/MobileAppointmentCard";
import NewAppointmentModal, {
  AppointmentWithClientProps
} from "../components/appointments/NewAppointmentModal";
import ProviderAppointmentsDrawer from "../components/appointments/ProviderAppointmentDrawer";
import RescheduleAppointmentModal from "../components/appointments/RescheduleAppointment";
import ProviderDashboardLayout from "../components/dashboard/ProviderDashboardLayout";
import { NoteValue } from "./PatientDetail/interfaces";

const Appointments = () => {
  const { user } = useProviderAuthState();
  const [activeTabLabel, setActiveTabLabel] = useState("Upcoming");
  const [activeVideoTabLabel, setActiveVideoTabLabel] = useState("Intake Form");
  const [joinCall, setJoinCall] = useState(false);
  const [showNewAppointmentModal, setShowNewAppointmentModal] = useState(false);
  const [appointmentInfo, setAppointmentInfo] = useState<any | null>(null);
  const [openAppointmentDetails, setOpenAppointmentDetails] = useState(false);
  const [providersUpcomingAppointments, setProvidersUpcomingAppointments] =
    useState<any>(null);
  const [providersPreviousAppointments, setProvidersPreviousAppointment] =
    useState<any>(null);
  const [isRescheduleViewVisible, setIsRescheduleViewVisible] = useState(false);
  const [showChargeSheetModal, setShowChargeSheetModal] = useState(false);
  const [token, setToken] = useState<string>("");
  const [oldAppointmentForReschedule, setOldAppointmentForReschedule] =
    useState("");
  const [providerId, setProviderId] = useState<string>("");
  const [appointmentObject, setAppointmentObject] = useState<any>({});
  const [noteFields, setNoteFields] = useState<NoteValue>({});

  const jaasAppId = process.env.REACT_APP_JAAS_APP_ID || "";

  const handleTabClick = (tabName: string) => {
    setActiveTabLabel(tabName);
  };

  const handleVideoTabClick = (tabName: string) => {
    setActiveVideoTabLabel(tabName);
  };

  const {
    refetchPatientFormResponse,
    patientFormResponseData,
    isLoading: isPatientFormLoading
  } = useGetPatientFormResponse({
    appointmentId: appointmentInfo?.appointmentId && appointmentInfo?.appointmentId
  });

  // useEffect(() => {
  //   if (
  //     !isFetchingPatientFormResponse &&
  //     patientFormResponseData?.appointmentId
  //   ) {
  //     // setPatientIntakeFormResponse(patientFormResponseData);
  //   }
  // }, [isFetchingPatientFormResponse, patientFormResponseData?.appointmentId]);

  const { bookAppointmentsForProvider, isPending: isBooking } =
    useBookProviderAppointments();

  const handleProviderScheduleAppointment = (
    values: AppointmentWithClientProps
  ) => {
    bookAppointmentsForProvider({
      patientId: values.patientId,
      appointmentDate: values.appointmentDate,
      appointmentStartTime: values.appointmentStartTime,
      isInitialAppointment: values.isInitialAppointment,
      appointmentType: values.appointmentType
    });
    setShowNewAppointmentModal(false);
  };

  useEffect(() => {
    setProviderId(String(user?.userId));
  }, [user?.userId]);

  const { providerFormData } = useGetProviderForm({
    formType: appointmentInfo?.isInitialAppointment
      ? ["initialVisitNote"]
      : ["progressNote"],
    providerId
  });

  const {
    data,
    error: formError,
    isError: isFormError,
    isLoading: isFormLoading
  } = providerFormData[0];

  if (isFormError && axios.isAxiosError(formError)) {
    toast.error(data?.error?.error, { toastId: "customId" });
  } else if (data?.error !== null) {
    toast.error(data?.error, { toastId: "customId" });
  } else {
    toast.error(data?.error?.error || "Error fetching provider's form data", {
      toastId: "customId"
    });
  }

  if (!isFormLoading) {
    <div className="fixed inset-0 z-[10000000] backdrop-blur-sm flex justify-center w-full h-screen items-center">
      <Loading />
    </div>;
  }

  // TODO: review where saveFormResponse called from 373
  const { saveFormResponse } = useSaveFormResponse();
  const { saveChargeSheet, isPending: isSavingDraft } =
    useSaveChargeSheetDraft();
  const { postChargeSheetToBilling, isPending: isSendingToBilling } =
    useSendChargeSheetToBilling();

  const transformAppointments = (appointments: any[]): any => {
    return appointments.map(appointment => {
      const { appointmentDate, appointmentStartTime, appointmentEndTime } =
        appointment;
      const composeStartDate = add(new Date(appointmentDate), {
        hours: Number(appointmentStartTime.slice(0, 2)),
        minutes: Number(appointmentStartTime.slice(3, 5))
      });
      const formatStartDate = format(composeStartDate, "yyyy-MM-dd HH:mm:ss");
      const formatStartDateWithSlash = format(composeStartDate, "yyyy/MM/dd");
      const composeEndDate = add(new Date(appointmentDate), {
        hours: Number(appointmentEndTime.slice(0, 2)),
        minutes: Number(appointmentEndTime.slice(3, 5))
      });
      const formatEndDate = format(composeEndDate, "HH:mm:ss");
      const [date, time] = formatStartDate.split(" ");
      return {
        id: appointment.appointmentId,
        title: `Call with ${appointment.patientName}`,
        start: `${date}T00:00:00`,
        slotDuration: time,
        appointmentObject: {
          ...appointment,
          appointmentDate: date,
          appointmentNewDate: formatStartDateWithSlash,
          appointmentStartTime: time,
          appointmentEndTime: formatEndDate
        }
      };
    });
  };

  const { providerAppointments, isLoading, isError, error } =
    useGetPatientUpcomingAppointments();
  const {
    providerPrevAppointments,
    isLoadingPrevAptmnt,
    isErrorPrevAptmnt,
    errorPrevAptmnt
  } = useGetProviderPreviousAppointments();

  if (isErrorPrevAptmnt) {
    toast.error(
      axios.isAxiosError(errorPrevAptmnt)
        ? errorPrevAptmnt?.response?.data?.error
        : "Error getting provider's previous appointments, try again",
      { toastId: "customId" }
    );
  }

  if (isError) {
    toast.error(
      axios.isAxiosError(error)
        ? error?.response?.data?.error
        : "Error getting provider's upcoming appointments, try again",
      { toastId: "customId" }
    );
  }

  useEffect(() => {
    if (
      !isLoadingPrevAptmnt &&
      providerPrevAppointments?.previousAppointments
    ) {
      const transformedAppointments = transformAppointments(
        providerPrevAppointments?.previousAppointments
      );
      setProvidersPreviousAppointment(transformedAppointments);
    }
  }, [providerPrevAppointments?.previousAppointments, isLoadingPrevAptmnt]);

  useEffect(() => {
    if (!isLoading && providerAppointments?.providersUpcomingAppointments) {
      const transformedAppointments = transformAppointments(
        providerAppointments?.providersUpcomingAppointments
      );
      setProvidersUpcomingAppointments(transformedAppointments);
    }
  }, [providerAppointments?.providersUpcomingAppointments, isLoading]);

  const {
    getJitsiToken,
    refetchGetJitsiToken,
    isLoading: isGettingToken
  } = useGetProviderJitsiToken(joinCall);

  useEffect(() => {
    if (!getJitsiToken && joinCall) {
      refetchGetJitsiToken();
    }
  }, [getJitsiToken, joinCall, refetchGetJitsiToken]);

  useEffect(() => {
    if (!isGettingToken && getJitsiToken) {
      setToken(getJitsiToken);
    }
  }, [getJitsiToken, isGettingToken]);

  const { cancelAppointment } = useCancelAppointment();

  function renderEventContent(eventContent: EventContentArg) {
    const {
      title,
      extendedProps: { slotDuration, appointmentObject }
    } = eventContent?.event?._def;
    return (
      <div
        className=""
        onClick={() => {
          if (appointmentObject) {
            setAppointmentInfo(appointmentObject);
          }
          setOpenAppointmentDetails(true);
          // handleFetchPatientFormResponse(appointmentObject?.appointmentId);
          refetchPatientFormResponse();
          providerFormData[0].refetch();
        }}
      >
        <i className="text-xs">{truncateText(title, 20)}</i>
        <p className="text-xs">{removeZerosAndCheckAMPM(slotDuration)}</p>
      </div>
    );
  }

  useEffect(() => {
    if (appointmentInfo) {
      setAppointmentObject(appointmentInfo);
    }
  }, [appointmentInfo]);

  if (providerFormData[0].isLoading) {
    <div className="fixed inset-0 z-[10000000] backdrop-blur-sm flex justify-center w-full h-screen items-center">
      <Loading />
    </div>;
  }

  const { data: patientData } = useGetSinglePatientRecord(
    appointmentObject?.patientId
  );

  const fullPatientData = useMemo(() => {
    return {
      appointmentId: appointmentObject?.appointmentId,
      patientName: `${patientData?.patientDetails.patient.firstName}${" "}${patientData?.patientDetails.patient.lastName}`,
      providerId: patientData?.patientDetails.patient?.userId,
      dateOfBirth: patientData?.patientDetails.patient.dateOfBirth,
      gender: patientData?.patientDetails.patient.gender,
      patientId: patientData?.patientDetails.patient.userId,
      insurance: patientData?.patientDetails.patient.insuranceName,
      modeOfEncounter: appointmentInfo?.appointmentType as
        | "virtual"
        | "physical",
      dateOfEncounter: appointmentInfo?.appointmentDate as string
    };
  }, [patientData, appointmentObject, appointmentInfo]);

  return (
    <>
      {joinCall ? (
        <div className="h-full flex items-center">
          <JaaSMeeting
            appId={jaasAppId}
            roomName={`${appointmentInfo?.patientId}${providerId}`}
            useStaging={process.env.NODE_ENV === "development"} // dev should remove this prop when the app goes live
            jwt={token}
            configOverwrite={{
              disableThirdPartyRequests: true,
              disableLocalVideoFlip: true,
              backgroundAlpha: 0.5,
              disableModeratorIndicator: true
            }}
            interfaceConfigOverwrite={{
              VIDEO_LAYOUT_FIT: "nocrop",
              MOBILE_APP_PROMO: false,
              TILE_VIEW_MAX_COLUMNS: 4
            }}
            userInfo={{
              displayName: `${user?.firstName} ${user?.lastName}`,
              email: user ? user?.email : ""
            }}
            onApiReady={(externalApi: any) => {}}
            onReadyToClose={() => {
              setJoinCall(false);
              setShowChargeSheetModal(true);
            }}
            getIFrameRef={(iframeRef: any) => {
              iframeRef.style.height = "100%";
              iframeRef.style.width = "60%";
            }}
          />
          <div className="w-[40%] h-full px-4 py-5">
            <div className="overflow-y-hidden mb-6 px-4 w-full overflow-x-scroll lg:overflow-x-hidden py-4">
              <ListTabsWidget
                tabs={[{ label: "Intake Form" }, { label: "Notes" }]}
                handleTabClick={label => {
                  handleVideoTabClick(label);
                }}
                activeTabLabel={activeVideoTabLabel}
              />
            </div>

            {isPatientFormLoading ? (
              <div className="flex justify-center items-center h-1/2">
                <Loading />
              </div>
            ) : (
                <Fragment>
                  {activeVideoTabLabel === "Intake Form" && (
                    <div className="w-full h-full overflow-y-auto">
                      <div className="w-full flex justify-between items-center mb-6">
                        <p>
                          Intake form answers: {`${appointmentInfo?.patientName}`}
                        </p>
                      </div>
                      {patientFormResponseData?.formResponse?.length ? (
                        <div className="w-full h-full">
                          {patientFormResponseData?.formResponse?.map(
                            (item: any, idx: number) => (
                            <section key={idx} className="text-[18px]">
                              {Object.entries(item).map(([key, value]: any) => (
                                <div className="w-full pb-5" key={key}>
                                  <p className="font-semibold">{key}</p>
                                  {Array.isArray(value) ? (
                                    Object.keys(value[0])?.map(
                                      (item: any, index) => (
                                        <div key={index}>
                                          <input
                                            className="accent-[#3D874E]"
                                            type="checkbox"
                                            checked={value[0][item]}
                                            readOnly={true}
                                          />{" "}
                                          <span>{item}</span>
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <p>{value}</p>
                                  )}
                                </div>
                              ))}
                            </section>
                          )
                        )}
                        </div>
                      ) : (
                        <div className="w-full h-full">
                          {patientFormResponseData?.message}
                        </div>
                      )}
                    </div>
                  )}

                  {activeVideoTabLabel === "Notes" && (
                    <Fragment>
                      {data?.intakeForm?.noteFields?.length > 0 ? (
                        <div className="w-full h-full overflow-y-auto">
                          <div className="w-full flex justify-between items-center mb-12">
                            <p>
                              Progress Note: {`${appointmentInfo?.patientName}`}
                            </p>
                          </div>
                          <div className="w-full h-full">
                            <FormBuilder
                              formParams={
                                data?.progressNote?.noteFields ||
                                data?.initialVisitNote?.noteFields ||
                                []
                              }
                              formType={
                                appointmentInfo?.isInitialAppointment
                                  ? "initialVisitNote"
                                  : "progressNote"
                              }
                              submitAction={() => {
                                saveFormResponse({
                                noteType: appointmentInfo?.isInitialAppointment
                                  ? "initialVisitNote"
                                  : "progressNote",
                                noteFields: [noteFields],
                                appointmentId:
                                  appointmentInfo?.appointmentId ?? "",
                                patientId: appointmentInfo?.patientId ?? ""
                              });
                              }}
                              isPreview={false}
                              formAnswers={noteFields}
                              setFormAnswers={setNoteFields}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>No notes!</div>
                      )}
                    </Fragment>
                  )}
                </Fragment>
            )}
          </div>
        </div>
      ) : (
        <ProviderDashboardLayout headerTitle="Appointments">
            <Fragment>
              <div
                className="w-full lg:mt-8 mb-4 flex justify-end"
                data-testid="appointment-container"
              >
                <div
                  className="w-full lg:w-[300px]"
                  data-testid="appointment-button-container"
                >
                <BtnButton
                  type="button"
                  variant="primary"
                  label="Add Appointment"
                  onClick={() => {
                    setShowNewAppointmentModal(true);
                  }}
                  size="large"
                />
              </div>
            </div>

            <div className="w-full my-4">
              <div className="overflow-y-hidden mb-6 px-4 w-full overflow-x-scroll lg:overflow-x-hidden py-4">
                <ListTabsWidget
                  tabs={[{ label: "Past" }, { label: "Upcoming" }]}
                  handleTabClick={label => {
                    handleTabClick(label);
                  }}
                  activeTabLabel={activeTabLabel}
                />
              </div>
            </div>

            {activeTabLabel === "Past" && (
              <div className="mt-8 hidden lg:block">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                  editable={true}
                  selectable={true}
                  eventContent={renderEventContent}
                  events={providersPreviousAppointments}
                  dayMaxEventRows={1}
                    dayMaxEvents={1}
                  headerToolbar={{
                    left: "title,prev,next",
                    center: "",
                    right: ""
                  }}
                />
              </div>
            )}

            {activeTabLabel === "Upcoming" && (
              <div className="mt-8 hidden lg:block">
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  editable={true}
                  selectable={true}
                  eventContent={renderEventContent}
                  events={providersUpcomingAppointments}
                  dayMaxEventRows={1}
                    dayMaxEvents={1}
                  headerToolbar={{
                    left: "title,prev,next",
                    center: "",
                    right: ""
                  }}
                />
              </div>
            )}

            {activeTabLabel === "Past" && (
              <div className="w-full block lg:hidden lg:mt-8">
                {providersPreviousAppointments &&
                    providersPreviousAppointments?.map(
                      (item: any, index: number) => {
                      return (
                        <MobileAppointmentCard
                          key={index}
                          appointmentDetails={item?.appointmentObject}
                          onClick={() => {
                            if (item?.appointmentObject) {
                              setAppointmentInfo(item?.appointmentObject);
                            }
                            setOpenAppointmentDetails(true);
                          }}
                        />
                      );
                    }
                  )}
              </div>
            )}

            {activeTabLabel === "Upcoming" && (
              <div className="w-full block lg:hidden lg:mt-8">
                {providersUpcomingAppointments &&
                    providersUpcomingAppointments?.map(
                      (item: any, index: number) => {
                      return (
                        <MobileAppointmentCard
                          key={index}
                          appointmentDetails={item?.appointmentObject}
                          onClick={() => {
                            if (item?.appointmentObject) {
                              setAppointmentInfo(item?.appointmentObject);
                            }
                            setOpenAppointmentDetails(true);
                          }}
                        />
                      );
                    }
                  )}
              </div>
            )}
            <ProviderAppointmentsDrawer
              setOldAppointmentForReschedule={setOldAppointmentForReschedule}
              setIsRescheduleViewVisible={setIsRescheduleViewVisible}
              open={openAppointmentDetails}
                onClose={() => {
                setOpenAppointmentDetails(false);
              }}
              drawerTitle="Appointment Details"
              appointmentDetails={appointmentInfo && appointmentInfo}
              patientIntakeFormResponse={
                patientFormResponseData && patientFormResponseData.formResponse
              }
              clickJoinCall={() => {
                setJoinCall(true);
              }}
              handleCancelAppointment={() => {
                cancelAppointment({
                  appointmentId: appointmentInfo?.appointmentId
                });
                setOpenAppointmentDetails(false);
                setAppointmentObject(null);
              }}
            />
            </Fragment>
        </ProviderDashboardLayout>
      )}
      <NewAppointmentModal
        loading={isBooking}
        isVisible={showNewAppointmentModal}
        closeModal={() => setShowNewAppointmentModal(false)}
        addAppointmentAction={handleProviderScheduleAppointment}
      />
      <RescheduleAppointmentModal
        isVisible={isRescheduleViewVisible}
        closeModal={() => setIsRescheduleViewVisible(false)}
        oldId={oldAppointmentForReschedule}
      />
      <SimpleChargeSheetModal
        chargeSheetData={fullPatientData}
        isFromNewBilling={false}
        isSendingToBilling={isSendingToBilling}
        isSavingDraft={isSavingDraft}
        open={showChargeSheetModal}
        setOpen={setShowChargeSheetModal}
        saveAction={({
          patientId,
          appointmentId,
          procedureCodes,
          diagnosisCodes,
          modeOfEncounter,
          locationOfEncounter,
          dateOfEncounter
        }) => {
          saveChargeSheet({
            patientId,
            appointmentId,
            procedureCodes,
            diagnosisCodes,
            status: "draft",
            modeOfEncounter,
            locationOfEncounter,
            dateOfEncounter
          });
          setShowChargeSheetModal(false);
        }}
        sendToBillingAction={({
          patientId,
          appointmentId,
          procedureCodes,
          diagnosisCodes,
          modeOfEncounter,
          locationOfEncounter,
          dateOfEncounter
        }) => {
          postChargeSheetToBilling({
            patientId,
            appointmentId,
            procedureCodes,
            diagnosisCodes,
            status: "processing",
            modeOfEncounter,
            locationOfEncounter,
            dateOfEncounter
          });
        }}
      />
    </>
  );
};

export default Appointments;
