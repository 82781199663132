import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import adminHttp from "../../../utils/adminHttp";


type Payload = {
  userId: string;
}

export const useDeleteAdminComplaint = () => {
  const queryClient = useQueryClient();
  const { mutate, data, variables, ...rest } = useMutation({
    mutationKey: ['delete admin complaints'],
    mutationFn: async ({ userId }: Payload) => {
      const response = await adminHttp.delete(`complaint/${userId}`);
      return response.data;
    },
    onMutate: () => {
      const toastId = toast.loading("Please wait...");
      return { toastId };
    },
    onSuccess: (_data, _variables, context) => {
      toast.success(
        `deleted successfully`,
        { toastId: "customId" }
      );

      const { toastId } = context;
      toast.update(toastId, {
        render: "deleted successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      })

      queryClient.invalidateQueries({
        queryKey: ["get all admin complaints list"]
      });
    },

    onError: (error, _variables, context) => {
      const toastId = context?.toastId as number
      if (toastId) {
        const errorMessage = axios.isAxiosError(error)
          ? error.response?.data?.error || "An error occurred"
          : error instanceof Error
            ? error.message
            : "Error processing your request";

        toast.update(toastId, {
          render: errorMessage,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });
      }
    },
  });

  return {
    deleteAdminComplaint: mutate,
    data,
    variables,
    ...rest,
  };
}