import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
// import PasswordField from "../form/PasswordField";
import { adminSetPassword } from "../../api/auth";
import PasswordField from "../../components/form/PasswordField";
import BtnButton from "../../components/shared/BtnButton";
import Spacer from "../../components/Spacer";
import { CertifiedBackgroundIcon } from "../../svgs/CertifiedBackgroundIcon";

const AdminSetPasswordForm = () => {
  localStorage.clear();
  // const [passwordSetError, setPasswordSetErrorError] = useState("");
  const [showSuccessFullSetupView, setShowSuccessFullSetupView] =
    useState(false);
  const { token } = useParams();

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      password: ""
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,40}$/,
          "Password must have at least one uppercase, one lowercase, one number, and be at least 8 characters long"
        )
    }),
    onSubmit: () => {}
  });

  return (
    <div className="w-full box-border lg:w-[614px] h-[397px] bg-white">
      {showSuccessFullSetupView ? (
        <>
          {" "}
          <div className="flex flex-col mx-auto items-center w-full h-full relative">
            <div className="h-full mt-16">
              <div className="w-full mx-auto flex justify-center">
                <CertifiedBackgroundIcon color="white" />
              </div>
              <p className="my-6 text-[28px~Check] font-[600] text-[#2E3011] text-center">
                {" "}
                Password set successfully
              </p>
              <div className="h-8" />
              <div className="w-full mt-6">
                <BtnButton
                  onClick={() => navigate("/admin/login")}
                  additionalClassname={
                    "text-[18px] font-[400] w-full relative w-[478px] h-[50px] rounded-none"
                  }
                  width={478}
                  height={60}
                  variant="primary"
                  label="Proceed to sign in"
                  // label={isLoading ? "Loading..." : "Proceed to sign in"}
                  // disabled={isLoading}
                  size="extra-large"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-center">
            <Spacer height={30} />
            <p className="text-[34px] font-[800] text-[#103C1B]">
              Set up password
            </p>
          </div>
          <Spacer height={40} />
          <div className="flex flex-col mx-auto items-center w-full relative">
              {/* {passwordSetError?.length > 0 && (
              <p className="text-[18px] font-[700] text-red-500 font-[gentiumBasic] mb-4">
                {passwordSetError}
              </p>
            )} */}
            <form className="relative flex flex-col items-center w-full lg:w-[478px]">
              <PasswordField
                name="password"
                placeholder="At least 8 characters"
                value={formik.values.password}
                onChange={formik.handleChange}
                label="Password"
                showPasswordToggler={true}
                error={formik.touched.password ? formik.errors.password : ""}
              />

              <div className="h-30px" />

              <div className="w-full mt-10">
                <BtnButton
                  type="button"
                  onClick={async () => {
                    const userReq: any = await adminSetPassword({
                      token,
                      password: formik.values.password
                    });
                    if (userReq) {
                      setShowSuccessFullSetupView(true);
                    }
                  }}
                  additionalClassname={
                    "text-[18px] font-[400] w-full relative w-[478px] h-[50px] rounded-none"
                  }
                  width={478}
                  height={60}
                  variant="primary"
                    label={"Submit"}
                    // label={isLoading ? "Submiting..." : "Submit"}
                    // disabled={isLoading}
                  size="extra-large"
                />
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminSetPasswordForm;
