
function AIGenerate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.796 11.933a77.73 77.73 0 014.8 4.41c13.58 13.583 19.831 29.654 13.073 36.415-3.968 3.965-11.12 3.516-19.362-.51a1.98 1.98 0 111.74-3.559c6.892 3.367 12.376 3.71 14.82 1.269 4.584-4.587-.87-18.612-13.072-30.812a72.523 72.523 0 00-4.552-4.184 1.98 1.98 0 012.553-3.03zm8.972 20.199c.835.709.938 1.959.229 2.791a79.107 79.107 0 01-4.4 4.763C26.012 53.266 9.941 59.517 3.182 52.758c-3.964-3.964-3.518-11.11.505-19.348a1.98 1.98 0 013.559 1.74c-3.364 6.885-3.705 12.365-1.263 14.807 4.583 4.584 18.608-.87 30.811-13.072a75.065 75.065 0 004.181-4.524 1.983 1.983 0 012.792-.23zM22.528 3.775a1.983 1.983 0 01-1.739 3.562C13.903 3.973 8.426 3.632 5.983 6.074c-4.586 4.583.87 18.609 13.073 30.812a71.239 71.239 0 004.54 4.171 1.982 1.982 0 01-2.553 3.03 76.519 76.519 0 01-4.791-4.4C2.672 26.102-3.58 10.031 3.182 3.273c3.962-3.965 11.108-3.52 19.346.5zm5.503 17.364c.405.105.715.421.814.827l.82 3.422a1.682 1.682 0 001.182 1.226l3.02.848a1.038 1.038 0 01-.037 2.012l-2.862.686a1.684 1.684 0 00-1.25 1.279l-.852 3.902a1.098 1.098 0 01-2.147 0l-.851-3.896a1.678 1.678 0 00-1.257-1.278l-2.965-.7a1.04 1.04 0 01-.776-1.256c.086-.371.368-.665.733-.768l2.98-.835a1.676 1.676 0 001.173-1.195l.904-3.469c.155-.6.77-.962 1.37-.805zM52.668 3.273c3.968 3.968 3.522 11.139-.507 19.389a1.98 1.98 0 11-3.56-1.74c3.37-6.9 3.714-12.4 1.267-14.848-4.587-4.586-18.612.87-30.812 13.072a71.298 71.298 0 00-4.2 4.568 1.982 1.982 0 01-2.791.242 1.981 1.981 0 01-.238-2.792 76.468 76.468 0 014.425-4.821C29.835 2.763 45.906-3.49 52.668 3.273z"
        fill="#324705"
      />
    </svg>
  )
}

export default AIGenerate;
