import clsx from "clsx";
import React, { useState } from "react";
import { ArrowDownIcon } from "../../svgs/ArrowDownIcon";
import { ArrowUpIcon } from "../../svgs/ArrowUpIcon";

interface MobilePatientCardProps {
  patientDetails?: any;
  onClick?: () => void;
}

const MobilePatientCard: React.FC<MobilePatientCardProps> = ({
  patientDetails,
  onClick
}) => {
  const [showFullView, setShowFullView] = useState(false);
  return (
    <div
      onClick={onClick}
      className="w-full block lg:hidden p-2 mb-2 border-b border-[#EEEEE9]"
    >
      <div className="w-full flex justify-start text-[#3D874E] font-[600]">
        {patientDetails?.name}
      </div>
      <div className="w-full flex items-center justify-end">
        {showFullView ? (
          <>
            <p
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(false);
              }}
              className="text-xs font-normal text-[#2E3011]"
            >
              Hide <br /> Details
            </p>
            <div
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(!showFullView);
              }}
            >
              <ArrowUpIcon />
            </div>
          </>
        ) : (
          <>
            <p
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(true);
              }}
                className="text-xs font-normal text-[#2E3011]"
            >
              Show <br /> Details
            </p>
            <div
              onClick={(e: any) => {
                e.stopPropagation();
                setShowFullView(!showFullView);
              }}
            >
              <ArrowDownIcon />
            </div>
          </>
        )}
      </div>

      <div className="w-full">
        <div className={clsx({ "my-4": showFullView, "my-0": !showFullView })}>
          <p>Age</p>
          <p>{patientDetails?.age}</p>
        </div>
        {showFullView && (
          <div className="my-4">
            <p>Email</p>
            <p>{patientDetails?.email}</p>
          </div>
        )}
        {showFullView && (
          <div className="my-4">
            <p>Diagnosis</p>
            <p>{patientDetails?.chiefComplaint}</p>
          </div>
        )}
        {showFullView && (
          <div className="my-4">
            <p>Last Session</p>
            <p>N/A</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobilePatientCard;
