
function WritingNotes(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={77}
      height={54}
      viewBox="0 0 77 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M74.607 15.088H63.076c-1.09 0-1.977.887-1.977 1.976v3.754c-8.043-.273-11.672-2.238-14.8-4.308l7.155-7.157a1.978 1.978 0 000-2.796l-6-6c-.742-.743-2.054-.743-2.797 0L33.951 11.264a34.76 34.76 0 00-4.125-.23c-7.81 0-13.82 6.245-21.434 14.151-1.594 1.652-3.239 3.364-5.012 5.137l-.004.004-.004.004c-.043.043-.078.093-.117.14-.043.055-.094.102-.133.16 0 0 0 .004-.004.004l-.012.016a6.148 6.148 0 00.778 7.71 6.072 6.072 0 001.746 1.212l-2.23 2.23c-.223.223-.391.496-.485.797l-2.82 8.82a1.975 1.975 0 002.484 2.485l8.82-2.82c.301-.098.575-.262.797-.485l7.367-7.367c.352.105.72.18 1.098.23h.004c.059.008.113.02.172.028.078.008.152.011.23.011h.004c10.836 0 14.496 1.426 17.727 2.688 2.39.934 4.652 1.813 8.996 1.813 3.121 0 5.223-.692 7.258-1.36 1.785-.586 3.492-1.148 6.043-1.34v2.727c0 1.09.887 1.976 1.977 1.976h11.53c1.09 0 1.977-.886 1.977-1.976v-30.97c0-1.089-.886-1.976-1.976-1.976l.004.005zM46.056 4.756l3.203 3.203-7.45 7.449-.007.008-15.156 15.152h-5.578c-.074 0-.152.004-.227.012-.25.027-.496.074-.738.125L46.06 4.748l-.004.008zM6.689 35.576c-.7-.7-.824-1.782-.336-2.625 1.73-1.735 3.336-3.403 4.89-5.016 7.231-7.512 12.458-12.94 18.586-12.94.137 0 .262.003.395.007L9.31 35.916a2.175 2.175 0 01-2.621-.336v-.004zm3.058 11.898L5.04 48.978l1.504-4.707 8.246-8.246a5.936 5.936 0 00-.093 1.023c0 1.496.554 2.883 1.484 3.992l-6.437 6.438.003-.004zm44.074-4.582c-1.898.625-3.535 1.164-6.023 1.164-3.602 0-5.352-.68-7.563-1.543-3.363-1.313-7.546-2.938-19.003-2.953-1.473-.196-2.575-1.266-2.575-2.516 0-1.238 1.086-2.305 2.54-2.515h19.726c1.09 0 1.977-.887 1.977-1.977s-.887-1.977-1.977-1.977h-8.68l11.212-11.21c3.62 2.44 7.832 5.097 17.648 5.413v16.57c-3.207.211-5.352.915-7.278 1.547l-.004-.003zm18.81 3.168h-7.579V19.044h7.578V46.06z"
        fill="#324705"
      />
    </svg>
  )
}

export default WritingNotes
